import {GET_CURRENT_SCENARIO, SET_CURRENT_SCENARIO} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {setNotificationInfoAction} from "domain/notification/actions";
import {withPathParams} from "utils/helpers/apiHelpers";

function* scenarioData(action) {
	try {
		const {data} = yield axios.get(
			withPathParams({
				id: action.id,
			})(ApiRoutePathsEnum.GET_SCENARIO)
		);
		yield put({type: SET_CURRENT_SCENARIO, scenario: data});
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.error.GET_SCENARIO
			)
		);
	}
}

function* getScenarioData() {
	yield takeEvery(GET_CURRENT_SCENARIO, scenarioData);
}

export default getScenarioData;
