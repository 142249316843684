const useStyles = (theme) => ({
	content: {
		minHeight: "100%",
		paddingTop: theme.spacing(6),
		paddingLeft: theme.spacing(0),
		paddingRight: theme.spacing(0),
		paddingBottom: theme.spacing(0),
		boxSizing: "border-box",
	},
});

export default useStyles;
