const useStyles = (theme) => ({
	card: {
		marginTop: theme.spacing(3),
		padding: "8px",
		flexShrink: 0,
		minHeight: "100px",
		background: theme.palette.background.secondary,
		color: theme.typography.body1.color,
		fontSize: theme.typography.body1.fontSize,
		"&> .MuiCardContent-root": {
			padding: "2px",
			paddingBottom: "0px",
			paddingTop: "24px",
			margin: "0px",
		},
		borderRadius: "8px",
	},
	cardTitle: {
		marginBottom: theme.spacing(1),
		fontSize: theme.typography.h5.fontSize,
	},
});

export default useStyles;
