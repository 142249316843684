export const GET_PRODUCERS_PARAM = "GET_PRODUCERS_PARAM";

export const SET_PRODUCERS_PARAM = "SET_PRODUCERS_PARAM";

export const GET_BUILDINGS_PARAM = "GET_BUILDINGS_PARAM";

export const SET_BUILDINGS_PARAM = "SET_BUILDINGS_PARAM";

export const SET_ZONES_PARAM = "SET_ZONES_PARAM";

export const SET_HEAT_ENERGY_PARAM = "SET_HEAT_ENERGY_PARAM";

export const SET_ENERGY_LABEL_PARAM = "SET_ENERGY_LABEL_PARAM";

export const RESET_SCENARIO_PARAM = "RESET_SCENARIO_PARAM";

export const GET_SCENARIO_PARAM = "GET_SCENARIO_PARAM";

export const GET_MODIFICATIONS_PARAM = "GET_MODIFICATIONS_PARAM";

export const SET_MODIFICATIONS_PARAM = "SET_MODIFICATIONS_PARAM";
