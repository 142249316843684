import React, {useCallback} from "react";
import {Redirect, Route} from "react-router-dom";

const PublicRoute = (props) => {
	const {
		component: Component,
		authenticated,
		authorized,
		redirectTo,
		unauthorizedPath,
		...rest
	} = props;

	const renderCallBack = useCallback(
		(routeProps) =>
			!authenticated || !authorized ? (
				<Component {...routeProps} />
			) : (
				<Redirect to={redirectTo} />
			),
		[authenticated, authorized, redirectTo]
	);

	return <Route {...rest} render={renderCallBack} />;
};

export default PublicRoute;
