import React from "react";

export default ({className}) => (
	<svg
		version="1.1"
		id="Capa_1"
		xmlns="http://www.w3.org/2000/svg"
		x="0px"
		y="0px"
		className={className}
		viewBox="0 0 512.001 512.001">
		<g>
			<g>
				<path
					d="M368.411,463.073c-3.015-4.628-9.21-5.937-13.838-2.922c-31.986,20.835-69.167,31.848-107.52,31.848
			c-108.773,0-197.267-88.493-197.267-197.266c0-39.499,11.629-77.607,33.632-110.209c3.09-4.578,1.883-10.793-2.695-13.883
			c-4.577-3.09-10.794-1.883-13.884,2.695C42.6,209.254,29.786,251.234,29.786,294.734c0,58.034,22.6,112.594,63.636,153.631
			s95.597,63.636,153.631,63.636c42.239,0,83.193-12.134,118.437-35.09C370.119,473.897,371.427,467.701,368.411,463.073z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M402.006,432.749c-3.944-3.867-10.275-3.804-14.143,0.139l-0.332,0.337c-3.89,3.92-3.865,10.252,0.055,14.143
			c1.951,1.935,4.497,2.901,7.044,2.901c2.571,0,5.144-0.986,7.099-2.957l0.414-0.42
			C406.011,442.948,405.95,436.617,402.006,432.749z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M442.512,163.889c1.953,1.953,4.512,2.929,7.071,2.929c2.559,0,5.118-0.977,7.071-2.929l16.658-16.658
			c11.869-11.871,11.869-31.186,0-43.057l-35.7-35.7c-5.751-5.75-13.397-8.917-21.528-8.917c-8.133,0-15.778,3.167-21.528,8.917
			l-16.658,16.657c-1.875,1.875-2.929,4.419-2.929,7.071c0,2.652,1.054,5.196,2.929,7.071l4.729,4.729l-11.982,11.982
			c-25.409-17.648-54.143-29.55-84.588-35.037V64.003h22.001c5.522,0,10-4.477,10-10V10c0-5.523-4.478-10-10-10H186.053
			c-5.522,0-10,4.477-10,10v44.002c0,5.523,4.478,10,10,10h22.001v16.965c-28.622,5.19-55.935,16.09-80.46,32.262
			c-4.611,3.041-5.884,9.243-2.844,13.855c3.042,4.611,9.245,5.884,13.855,2.843c32.202-21.235,69.704-32.459,108.451-32.459
			c108.773,0,197.267,88.493,197.267,197.267c0,36.887-10.242,72.857-29.619,104.017c-2.916,4.69-1.479,10.856,3.212,13.774
			c1.644,1.022,3.468,1.509,5.271,1.509c3.342,0,6.607-1.674,8.502-4.721c21.351-34.334,32.635-73.955,32.635-114.579
			c0-44.813-13.487-87.547-38.521-123.59l11.982-11.982L442.512,163.889z M266.055,78.297c-6.284-0.541-12.619-0.83-19.001-0.83
			c-6.369-0.001-12.707,0.281-19.001,0.828V64.003h38.001V78.297z M218.054,44.002h-22.001V20.001h102.004v24.001h-22.001H218.054z
			 M413.533,155.125c-4.061-4.825-8.336-9.51-12.849-14.023c-4.512-4.512-9.197-8.787-14.023-12.849l10.107-10.107l26.871,26.871
			L413.533,155.125z M403.841,96.933c-0.002-0.002-0.004-0.003-0.006-0.005l-4.723-4.723l9.587-9.586
			c1.973-1.973,4.595-3.059,7.385-3.059c2.789,0,5.412,1.086,7.385,3.06l35.7,35.699c4.072,4.072,4.072,10.699,0,14.772
			l-9.586,9.585l-4.714-4.714c-0.005-0.005-0.009-0.01-0.014-0.015L403.841,96.933z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M247.055,152.992c-5.522,0-10,4.477-10,10v22.001c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10v-22.001
			C257.055,157.469,252.577,152.992,247.055,152.992z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M347.278,194.508c-1.86-1.86-4.439-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.86,1.86-2.931,4.44-2.931,7.07
			c0,2.64,1.07,5.21,2.931,7.07c1.859,1.86,4.439,2.93,7.069,2.93c2.631,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
			S349.139,196.368,347.278,194.508z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M378.795,284.733h-22.001c-5.522,0-10,4.477-10,10s4.478,10,10,10h22.001c5.522,0,10-4.477,10-10
			C388.795,289.21,384.317,284.733,378.795,284.733z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M347.278,380.815c-1.86-1.86-4.439-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.86,1.86-2.931,4.44-2.931,7.07
			c0,2.64,1.07,5.22,2.931,7.07c1.859,1.87,4.439,2.93,7.069,2.93c2.631,0,5.21-1.06,7.07-2.93c1.86-1.86,2.93-4.43,2.93-7.07
			C350.208,385.255,349.139,382.685,347.278,380.815z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M247.055,394.473c-5.522,0-10,4.477-10,10v22.001c0,5.523,4.478,10,10,10s10-4.477,10-10v-22.001
			C257.055,398.951,252.577,394.473,247.055,394.473z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M160.97,380.815c-1.861-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.86,1.86-2.931,4.44-2.931,7.07
			c0,2.64,1.07,5.21,2.931,7.07c1.859,1.87,4.439,2.93,7.069,2.93c2.63,0,5.21-1.06,7.07-2.93c1.86-1.86,2.93-4.43,2.93-7.07
			C163.901,385.255,162.831,382.675,160.97,380.815z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M137.314,284.733h-22.001c-5.522,0-10,4.477-10,10c0,5.523,4.478,10,10,10h22.001c5.522,0,10-4.477,10-10
			C147.315,289.21,142.837,284.733,137.314,284.733z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M160.97,194.508c-1.86-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.86,1.86-2.931,4.44-2.931,7.07
			s1.07,5.21,2.931,7.07c1.859,1.86,4.439,2.93,7.069,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
			S162.831,196.368,160.97,194.508z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M325.133,216.654c-3.256-3.255-8.312-3.867-12.25-1.483l-82.548,49.974c-0.893,0.507-2.143,1.242-2.989,1.908
			c-8.641,6.171-14.292,16.275-14.292,27.679c0,18.75,15.253,34.002,34.001,34.002c11.36,0,21.431-5.606,27.608-14.192
			c0.739-0.915,1.428-2.116,1.989-3.105l49.964-82.533C329,224.967,328.387,219.91,325.133,216.654z M259.422,301.277l-0.826,1.364
			c-2.526,3.675-6.755,6.093-11.542,6.093c-7.719,0-14-6.28-14-14c0-4.788,2.419-9.019,6.096-11.545l1.356-0.821
			c1.955-1.04,4.182-1.634,6.547-1.634c7.72,0,14.001,6.28,14.001,14.001C261.054,297.097,260.46,299.323,259.422,301.277z
			 M275.049,275.466c-2.355-3.411-5.315-6.371-8.727-8.726l22.115-13.388L275.049,275.466z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M247.055,121.499c-95.523,0-173.237,77.712-173.237,173.235S151.531,467.97,247.055,467.97
			s173.236-77.713,173.236-173.236S342.578,121.499,247.055,121.499z M247.055,447.969c-84.494,0-153.236-68.741-153.236-153.235
			s68.741-153.235,153.236-153.235c84.494,0,153.235,68.741,153.235,153.235S331.549,447.969,247.055,447.969z"
				/>
			</g>
		</g>
		<g>
			<g>
				<path
					d="M108.178,140.495c-1.861-1.86-4.44-2.93-7.07-2.93c-2.63,0-5.21,1.07-7.069,2.93c-1.86,1.86-2.931,4.44-2.931,7.07
			c0,2.64,1.07,5.21,2.931,7.07c1.859,1.86,4.439,2.93,7.069,2.93c2.63,0,5.21-1.07,7.07-2.93c1.86-1.86,2.93-4.44,2.93-7.07
			C111.108,144.936,110.039,142.356,108.178,140.495z"
				/>
			</g>
		</g>
	</svg>
);
