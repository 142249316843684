function search(node, id) {
	if (node.id === id) return true;
	else if (node.children) {
		let result;
		for (const child of node.children) {
			if (!result) result = search(child, id);
		}
		return result ? result : null;
	}
	return null;
}

export default (tree, id) => {
	for (const node of tree) {
		const isinBranch = search(node, id);
		if (isinBranch) return node;
	}
	return null;
};
