const useStyles = (theme) => ({
	card: {
		minWidth: 120,
		height: 96,
		margin: `${theme.spacing(1)}px`,
		padding: 0,
		position: "relative",
		boxSizing: "border-box",
		borderRadius: "4px",
		background: theme.palette.background.secondary,
		boxShadow: "0px 0px 0px 0px rgb(18, 18, 18)",
		transition: "box-shadow 0.15s linear",
		"&:hover": {
			boxShadow: "0px 0px 0px 3px grey",
		},
	},
	content: {
		padding: "0 !important",
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	title: {
		color: theme.palette.text.grey,
		textTransform: "uppercase",
		fontSize: 11,
		fontWeight: "bold",
		letterSpacing: 0,
		lineHeight: "1.45",
		textAlign: "left",
		top: "0px",
		padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px ${theme.spacing(
			1
		)}px ${theme.spacing(1)}px`,
	},
	numerics: {
		fontWeight: "bold",
		margin: "0 0 8px 0",
		padding: `0 ${theme.spacing(1)}px`,
		paddingBottom: 0,
		width: "100%",
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "flex-end",
	},
	outputLabel: {
		fontSize: 15,
	},
	value: {
		textAlign: "left",
		fontSize: 32,
		lineHeight: 1,
		margin: 0,
		padding: 0,
		letterSpacing: 0,
		transition: "font-size 0.3s linear",
	},
	subValue: {
		textAlign: "right",
		fontSize: 14,
		lineHeight: 2,
		marginLeft: 12,
		padding: 0,
		letterSpacing: 0,
		display: "flex",
		alignItems: "center",
	},
	subValueIcon: {
		fontSize: "20px",
		padding: "2px",
	},
	isSubTotalPositifSubValue: {
		color: "green",
	},
	isNegatifSubValue: {
		color: "red",
	},
	evolution: {
		fontWeight: "bold",
		textAlign: "left",
		fontSize: 12,
		margin: 0,
		padding: 0,
		height: "14px",
		letterSpacing: 0,
		lineHeight: "14px",
	},
	kpiResultsContainer: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-end",
	},
});

export default useStyles;
