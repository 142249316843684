export const mergeZonesForHeatEnergyTypeFilters = (list) => {
	const mapHeatEnergyType = {};
	list.forEach((element) => {
		if (mapHeatEnergyType[element.heatenergytype]) {
			mapHeatEnergyType[element.heatenergytype] += element.countbuilding;
		} else {
			mapHeatEnergyType[element.heatenergytype] = element.countbuilding;
		}
	});
	return Object.keys(mapHeatEnergyType).map((key) => ({
		heatenergytype: key,
		countbuilding: mapHeatEnergyType[key],
	}));
};

export const mergeZonesForEnergyLabelFilters = (list) => {
	const mapEnergyLabel = {};
	list.forEach((element) => {
		if (mapEnergyLabel[element.energylabel]) {
			mapEnergyLabel[element.energylabel] += element.countbuilding;
		} else {
			mapEnergyLabel[element.energylabel] = element.countbuilding;
		}
	});
	return Object.keys(mapEnergyLabel).map((key) => ({
		energylabel: key,
		countbuilding: mapEnergyLabel[key],
	}));
};

export const hydrateCurrentHeatEnergyTypeFromheatEnergyTypeFiltersList = (
	heatEnergyTypeFiltersList,
	currentHeatEnergyTypes
) => {
	const mapHeatEnergyType = {};
	heatEnergyTypeFiltersList.forEach((element) => {
		if (mapHeatEnergyType[element.heatenergytype]) {
			mapHeatEnergyType[element.heatenergytype] += element.countbuilding;
		} else {
			mapHeatEnergyType[element.heatenergytype] = element.countbuilding;
		}
	});

	const newCurrentHeatEnergyTypes = [];

	currentHeatEnergyTypes.forEach((element) => {
		const heatEnergyTypeValue = element.split("+")[0];
		if (mapHeatEnergyType[heatEnergyTypeValue]) {
			newCurrentHeatEnergyTypes.push(
				heatEnergyTypeValue +
					"+" +
					mapHeatEnergyType[heatEnergyTypeValue]
			);
		}
	});

	return newCurrentHeatEnergyTypes;
};

export const hydrateCurrentEnergyLabelFromheatEnergyTypeFiltersList = (
	energyLabelFiltersList,
	currentEnergyLabels
) => {
	const mapEnergyLabel = {};
	energyLabelFiltersList.forEach((element) => {
		if (mapEnergyLabel[element.energylabel]) {
			mapEnergyLabel[element.energylabel] += element.countbuilding;
		} else {
			mapEnergyLabel[element.energylabel] = element.countbuilding;
		}
	});

	const newCurrentEnergyLabels = [];

	currentEnergyLabels.forEach((element) => {
		const energyLabelValue = element.split("+")[0];
		if (mapEnergyLabel[energyLabelValue]) {
			newCurrentEnergyLabels.push(
				energyLabelValue + "+" + mapEnergyLabel[energyLabelValue]
			);
		}
	});

	return newCurrentEnergyLabels;
};
