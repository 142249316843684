import App from "./App";
import {connect} from "react-redux";
import {getEmbedInfo} from "domain/powerbi/actions";
import {getScenarioTreeAction} from "domain/scenario/actions";

const mapDispatchToProps = {
	getScenarioTreeAction,
	getEmbedInfo,
};

export default connect(null, mapDispatchToProps)(App);
