import SourceIdEnum from "utils/enums/SourceIdEnum";

export default (map) => {
	map.addSource(SourceIdEnum.RESEAU_GAZ.id, {
		type: "geojson",
		data: SourceIdEnum.RESEAU_GAZ.path,
	});

	map.addSource(SourceIdEnum.ESTIMATION_CONSO_CHALEUR.id, {
		type: "geojson",
		data: SourceIdEnum.ESTIMATION_CONSO_CHALEUR.path,
	});

	map.addSource(SourceIdEnum.RESEAU_CHALEUR.id, {
		type: "geojson",
		data: SourceIdEnum.RESEAU_CHALEUR.path,
	});

	map.addSource(SourceIdEnum.PARCELLES.id, {
		type: "geojson",
		data: SourceIdEnum.PARCELLES.path,
	});

	map.addSource(SourceIdEnum.CPS_RESIDENTIEL_TERTIAIRE.id, {
		type: "geojson",
		data: SourceIdEnum.CPS_RESIDENTIEL_TERTIAIRE.path,
		cluster: true,
		clusterMaxZoom: 13,
		clusterRadius: 50,
	});

	map.addSource(SourceIdEnum.GEOTHERMIE_EXPLOITATION.id, {
		type: "geojson",
		data: SourceIdEnum.GEOTHERMIE_EXPLOITATION.path,
	});

	map.addSource(SourceIdEnum.GEOTHERMAL_POTENTIAL.id, {
		type: "geojson",
		data: SourceIdEnum.GEOTHERMAL_POTENTIAL.path,
	});

	map.addSource(SourceIdEnum.ZAC.id, {
		type: "geojson",
		data: SourceIdEnum.ZAC.path,
	});

	map.addSource(SourceIdEnum.CPS.id, {
		type: "geojson",
		data: SourceIdEnum.CPS.path,
	});

	map.addSource(SourceIdEnum.IRIS.id, {
		type: "geojson",
		data: SourceIdEnum.IRIS.path,
	});

	map.addSource(SourceIdEnum.BORNES_RECHARGE_ELECTRIQUE.id, {
		type: "geojson",
		data: SourceIdEnum.BORNES_RECHARGE_ELECTRIQUE.path,
	});

	map.addSource(SourceIdEnum.TRONCON_AERIEN.id, {
		type: "geojson",
		data: SourceIdEnum.TRONCON_AERIEN.path,
	});

	map.addSource(SourceIdEnum.TRONCON_CABLE.id, {
		type: "geojson",
		data: SourceIdEnum.TRONCON_CABLE.path,
	});

	map.addSource(SourceIdEnum.POSTE_SOURCE.id, {
		type: "geojson",
		data: SourceIdEnum.POSTE_SOURCE.path,
	});

	map.addSource(SourceIdEnum.POSTE_ELEC.id, {
		type: "geojson",
		data: SourceIdEnum.POSTE_ELEC.path,
		cluster: true,
		clusterMaxZoom: 13,
		clusterRadius: 50,
	});

	map.addSource(SourceIdEnum.WIND_POTENTIAL.id, {
		type: "geojson",
		data: SourceIdEnum.WIND_POTENTIAL.path,
	});

	map.addSource(SourceIdEnum.ROOFTOP_POTENTIAL.id, {
		type: "geojson",
		data: SourceIdEnum.ROOFTOP_POTENTIAL.path,
	});
};
