import {CLOSE_NOTIFICATION_MODAL, SET_NOTIFICATION_INFO} from "./constants";

import StatusEnum from "utils/enums/StatusEnum";
import {combineReducers} from "redux";

const notificationReducer = combineReducers({
	info: (
		state = {
			status: StatusEnum.IDLE,
			message: "",
			title: "",
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				return {
					...state,
					status: action.status,
					message: action.message,
					title: action.title,
				};
			default:
				return state;
		}
	},

	isOpen: (state = false, action) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				return true;
			case CLOSE_NOTIFICATION_MODAL:
				return false;
			default:
				return state;
		}
	},
});

export default notificationReducer;
