export default {
	GET_ZONE: "api/zone/list",
	GET_HEAT_ENERGY_TYPE: "api/heatenergytype/list",
	GET_ENERGY_LABEL: "api/energylabel/list",
	GET_BUILDINGS: "api/building/list",
	GET_OUTPUT_KPIS: "api/global-output-kpis",
	GET_GLOBAL_KPIS: "api/global-kpis",
	GET_SCENARIOS: "api/scenarios",
	GET_SCENARIO: "api/scenario/:id",
	CREATE_SCENARIO: "api/scenario",
	UPDATE_SCENARIO: "api/update-scenario",
	UPDATE_ALTERNATIVE_SCENARIO: "api/update-alternative-scenario",
	DELETE_SCENARIOS: "api/scenarios",
	GET_TABLE: "api/scenario/table",
	GET_PRODUCERS: "api/producers",
	CREATE_PRODUCER: "api/producer",
	UPDATE_PRODUCER: "api/producer",
	DELETE_PRODUCERS: "api/producers",
	LAUNCH_SIMULATION: "api/simulation/run/:id",
	GET_BUILDINGS_POLYGON: "api/building/polygon",
	GET_EMBED_INFO: "api/get-embed-info",
	SIMULATION_MAPPING: "api/simulation-mapping",
};
