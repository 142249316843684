import {
	deleteScenariosAction,
	openCreationScenarioModalAction,
	resetScenarioFormValuesAction,
	setCurrentScenarioAction,
} from "domain/scenario/actions";

import ScenarioManagerView from "./ScenarioManagerView";
import {connect} from "react-redux";
import {getScenarioParametersAction} from "domain/parameters/actions";
import {getScenarioTreeAction} from "domain/scenario/actions";
import {resetKpisAction} from "domain/kpi/actions";
import {resetScenarioParametersAction} from "domain/parameters/actions";
import {resetSelectionCurrentFiltersAction} from "domain/map/actions";

const mapStateToProps = (state) => ({
	scenariosTree: state.scenarioReducer.scenarioTree,
});

const mapDispatchToProps = {
	setCurrentScenarioAction,
	deleteScenariosAction,
	openCreationScenarioModalAction,
	getScenarioParametersAction,
	resetScenarioParametersAction,
	resetSelectionCurrentFiltersAction,
	resetKpisAction,
	resetScenarioFormValuesAction,
	getScenarioTreeAction,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScenarioManagerView);
