import NotificationModal from "./NotificationModal";
import {closeNotificationModalAction} from "domain/notification/actions";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
	info: state.notificationReducer.info,
	isOpen: state.notificationReducer.isOpen,
});

const mapDispatchToProps = {
	closeNotificationModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal);
