import "./style.scss";

import {Box, withStyles} from "@material-ui/core";
import {
	POWERBI_DASHBOARD_PAGES,
	POWERBI_REPORTS,
} from "utils/enums/PowerBiReportsEnum";
import React, {useCallback, useEffect, useRef, useState} from "react";

import ButtonBase from "common/presentational/components/Button/ButtonBase";
import {PowerBIEmbed} from "powerbi-client-react";
import {models} from "powerbi-client";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const DashboardView = ({classes, accessToken, embedUrl}) => {
	const {i18n} = useTranslation();

	const isInitialMount = useRef(true);
	const isReportRendered = useRef(false);
	const firstReportRender = useRef(true);

	const [reportAccessToken, setReportAccessToken] = useState(null);
	const [reportId, setReportId] = useState(null);
	const [reportEmbedUrl, setReportEmbedUrl] = useState(null);

	const [reportPages, setReportPages] = useState([]);

	useEffect(() => {
		if (isInitialMount.current) {
			if (accessToken && embedUrl) {
				setReportAccessToken(accessToken);
				setReportId(
					embedUrl[POWERBI_REPORTS.POWERBI_DASHBOARD].reportId
				);
				setReportEmbedUrl(
					embedUrl[POWERBI_REPORTS.POWERBI_DASHBOARD].embedUrl
				);
				isInitialMount.current = false;
			}
		} else {
			if (accessToken && window.report) {
				window.report.setAccessToken(accessToken);
			}
		}
	}, [accessToken, embedUrl]);

	const reportPageChange = useCallback((name) => {
		window.report.setPage(name);
	}, []);

	const updatePages = useCallback(
		(refreshPage) => {
			window.report.getPages().then((reportPages) => {
				isReportRendered.current = true;
				setReportPages(
					reportPages.filter((page) =>
						POWERBI_DASHBOARD_PAGES[i18n.language].includes(
							page.displayName
						)
					)
				);
			});
		},
		[i18n.language]
	);

	useEffect(() => {
		if (isReportRendered.current) {
			firstReportRender.current = true;
			updatePages();
		}
	}, [i18n.language, updatePages]);

	useEffect(() => {
		if (
			isReportRendered.current &&
			firstReportRender.current &&
			reportPages[0]
		) {
			window.report.setPage(reportPages[0].name);
			firstReportRender.current = false;
		}
	}, [reportPages]);

	return (
		<Box component="main">
			{reportAccessToken && reportId && reportEmbedUrl && (
				<PowerBIEmbed
					embedConfig={{
						type: "report",
						id: reportId,
						embedUrl: reportEmbedUrl,
						accessToken: reportAccessToken,
						tokenType: models.TokenType.Embed,
						settings: {
							navContentPaneEnabled: false,
						},
					}}
					cssClassName={"pse-powerbi"}
					getEmbeddedComponent={(embeddedReport) => {
						window.report = embeddedReport;
					}}
					eventHandlers={new Map([["loaded", () => updatePages()]])}
				/>
			)}
			{reportPages.length > 0 && (
				<Box className={classes.reportPageContainer}>
					{reportPages.map((page) => (
						<ButtonBase
							key={page.name}
							className={classes.reportPage}
							color="secondary"
							onClick={() => reportPageChange(page.name)}>
							{page.displayName}
						</ButtonBase>
					))}
				</Box>
			)}
		</Box>
	);
};

export default withStyles(useStyles)(DashboardView);
