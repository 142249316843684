import "./index.scss";
import "typeface-ubuntu";

import React, {Suspense} from "react";

import App from "./views/App";
import {I18nextProvider} from "react-i18next";
import {Provider} from "react-redux";
import ReactDOM from "react-dom";
import {ThemeProvider} from "@material-ui/core";
// eslint-disable-next-line no-unused-vars
import axiosConfig from "./axiosConfig";
import i18n from "./i18next.config";
import theme from "styles/theme";
import thestore from "./configureStore";

ReactDOM.render(
	<Suspense fallback={<></>}>
		<Provider store={thestore}>
			<ThemeProvider theme={theme}>
				<I18nextProvider i18n={i18n}>
					<App />
				</I18nextProvider>
			</ThemeProvider>
		</Provider>
	</Suspense>,
	document.getElementById("root")
);
