import {Grid, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";

import ButtonBase from "common/presentational/components/Button/ButtonBase";
import PropTypes from "prop-types";
import {useStyles} from "./muiStyles";
import {useTranslation} from "react-i18next";
import validate from "validate.js";
import {validationSignInForm} from "utils/constants/signInViewParams";
import {withStyles} from "@material-ui/core/styles";

const SignInView = ({classes, handleSignIn}) => {
	const {t} = useTranslation();

	const [formState, setFormState] = useState({
		isValid: false,
		values: {},
		touched: {},
		errors: {},
	});

	useEffect(() => {
		const errors = validate(formState.values, validationSignInForm);

		setFormState((formState) => ({
			...formState,
			isValid: errors ? false : true,
			errors: errors || {},
		}));
	}, [formState.values, setFormState]);

	return (
		<div className={classes.root}>
			<Grid className={classes.grid} container>
				<Grid className={classes.quoteContainer} item lg={5}>
					<div className={classes.quote} />
				</Grid>
				<Grid className={classes.content} item lg={7} xs={12}>
					<div className={classes.content}>
						<div className={classes.contentHeader}></div>
						<div className={classes.contentBody}>
							<form className={classes.form}>
								<Typography
									className={classes.title}
									variant="h2">
									{t("views.signin.title")}
								</Typography>
								<Grid container>
									<ButtonBase
										className={classes.signInButton}
										onClick={handleSignIn}
										size="large">
										{t("views.signin.button")}
									</ButtonBase>
								</Grid>
							</form>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

SignInView.propTypes = {
	handleSignIn: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
};

SignInView.defaultProps = {};

export default withStyles(useStyles)(SignInView);
