const useStyles = (theme) => ({
	card: {
		overflowX: "auto",
		padding: "1rem",
		flexShrink: 0,
		minHeight: "100px",
		background: theme.palette.background.secondary,
		color: theme.typography.body1.color,
		fontSize: theme.typography.body1.fontSize,
		borderRadius: "10px",
		marginRight: "50px",
		"&> .MuiCardContent-root": {
			padding: "2px",
			paddingBottom: "0px",
			paddingTop: "24px",
			margin: "0px",
		},
	},
	cardTitle: {
		marginBottom: theme.spacing(1),
		fontSize: theme.typography.h5.fontSize,
	},
	legend: {
		fontSize: 16,
	},
	labelType: {
		display: "flex",
		alignItems: "center",
		gap: "8px",
	},
});

export default useStyles;
