import {grey, paleOrange, red, yellow} from "styles/typeFiltersColors.scss";

import LayerIdEnum from "utils/enums/LayerIdEnum";
import generateInfoPopup from "utils/helpers/generateInfoPopup";
import i18n from "../../../i18next.config";
import mapboxgl from "mapbox-gl";

export default (map) => {
	// When a click event occurs on a feature in the states layer, open a popup at the
	// location of the click, with description HTML from its properties.

	// To resize the map on every resize
	map.on("idle", function () {
		map.resize();
	});

	// * Parcelles fond :
	map.on("mouseenter", LayerIdEnum.PARCELLES_FOND, function () {
		map.getCanvas().style.cursor = "pointer";
	});

	map.on("mouseleave", LayerIdEnum.PARCELLES_FOND, function () {
		map.getCanvas().style.cursor = "";
	});

	map.on("click", LayerIdEnum.PARCELLES_FOND, (e) => {
		new mapboxgl.Popup({maxWidth: "26rem", anchor: "right"})
			.setLngLat(e.lngLat)
			.setHTML(
				generateInfoPopup(
					{
						[i18n.t("components.scenarioparamtable.popup.id")]: e
							.features[0].properties.idpar,
						[i18n.t(
							"components.scenarioparamtable.popup.surface"
						)]: e.features[0].properties.surface,
						[i18n.t(
							"components.scenarioparamtable.popup.constructionyear"
						)]: e.features[0].properties.constructionyear,
						[i18n.t(
							"components.scenarioparamtable.popup.heatenergytype"
						)]: i18n.t(
							`components.scenarioparamtable.popup.heatenergytypeenum.${e.features[0].properties.heatenergytype}`
						),
						[i18n.t(
							"components.scenarioparamtable.popup.energyclass"
						)]: e.features[0].properties.energycat,
						[i18n.t(
							"components.scenarioparamtable.popup.livingquarters"
						)]: e.features[0].properties.napart,
						[i18n.t(
							"components.scenarioparamtable.popup.floors"
						)]: e.features[0].properties.nlevel,
						[i18n.t("components.scenarioparamtable.popup.hlm")]: e
							.features[0].properties.nloghlm,
						[i18n.t("components.scenarioparamtable.popup.owner")]: e
							.features[0].properties.noccprop,
						[i18n.t(
							"components.scenarioparamtable.popup.tenant"
						)]: e.features[0].properties.nocclocat,
					},
					i18n.t("components.scenarioparamtable.popup.title"),
					grey
				)
			)
			.addTo(map);
	});

	// * Réseau de gaz :
	map.on("mouseenter", LayerIdEnum.RESEAU_GAZ, function () {
		map.getCanvas().style.cursor = "pointer";
	});

	map.on("mouseleave", LayerIdEnum.RESEAU_GAZ, function () {
		map.getCanvas().style.cursor = "";
	});

	map.on("click", LayerIdEnum.RESEAU_GAZ, (e) => {
		new mapboxgl.Popup()
			.setLngLat(e.lngLat)
			.setHTML(
				generateInfoPopup(
					{
						[i18n.t(
							"components.scenarioparamtable.popup.municipalitypostcode"
						)]: e.features[0].properties["code_postal_commune"],
					},
					LayerIdEnum.RESEAU_GAZ,
					yellow
				)
			)
			.addTo(map);
	});

	// * Geothermie exploitation :
	map.on("mouseenter", LayerIdEnum.GEOTHERMIE_EXPLOITATION, function () {
		map.getCanvas().style.cursor = "pointer";
	});

	map.on("mouseleave", LayerIdEnum.GEOTHERMIE_EXPLOITATION, function () {
		map.getCanvas().style.cursor = "";
	});

	map.on("click", LayerIdEnum.GEOTHERMIE_EXPLOITATION, (e) => {
		new mapboxgl.Popup()
			.setLngLat(e.lngLat)
			.setHTML(
				generateInfoPopup(
					{
						[i18n.t("components.scenarioparamtable.popup.shape")]: e
							.features[0].properties["FORME"],
						[i18n.t(
							"components.scenarioparamtable.popup.sitenum"
						)]: e.features[0].properties["NUMSITE"],
						[i18n.t(
							"components.scenarioparamtable.popup.perimeter"
						)]: e.features[0].properties["PERIMETRE"],
					},
					LayerIdEnum.GEOTHERMIE_EXPLOITATION,
					paleOrange
				)
			)
			.addTo(map);
	});

	// * réseau chaleur :
	map.on("mouseenter", LayerIdEnum.RESEAU_CHALEUR, function () {
		map.getCanvas().style.cursor = "pointer";
	});

	map.on("mouseleave", LayerIdEnum.RESEAU_CHALEUR, function () {
		map.getCanvas().style.cursor = "";
	});

	map.on("click", LayerIdEnum.RESEAU_CHALEUR, (e) => {
		new mapboxgl.Popup()
			.setLngLat(e.lngLat)
			.setHTML(generateInfoPopup({}, LayerIdEnum.RESEAU_CHALEUR, red))
			.addTo(map);
	});
};
