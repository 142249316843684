const IndicatorsModel = ({
	annualEnergyConsumption,
	energyProduction,
	gesAndCO2Emission,
}) => ({
	annualEnergyConsumption,
	energyProduction,
	gesAndCO2Emission,
});

module.exports = IndicatorsModel;
