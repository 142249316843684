import {Box, Typography, withStyles} from "@material-ui/core";

import React from "react";
import ScenarioParamsTableTabs from "./ScenarioParamsTableTabs";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const ScenarioParameters = ({classes}) => {
	const {t} = useTranslation();

	return (
		<Box className={classes.card}>
			<Typography
				display="inline"
				component="div"
				className={classes.cardTitle}
				color="textSecondary"
				gutterBottom>
				{t("components.scenarioparameters.title")}
			</Typography>
			<ScenarioParamsTableTabs />
		</Box>
	);
};

export default withStyles(useStyles)(ScenarioParameters);
