import {Button, withStyles} from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import cn from "classnames";
import {noop} from "lodash";
import {useButtonBaseStyles} from "./muiStyles";

const ButtonBase = ({
	className,
	color,
	size,
	variant,
	onClick,
	classes,
	children,
	disabled,
	...props
}) => (
	<Button
		className={cn({
			[classes.button]: true,
			[className]: true,
			[classes.disabled]: disabled,
		})}
		onClick={onClick}
		size={size}
		variant={variant}
		color={color}
		disabled={disabled}
		{...props}>
		{children}
	</Button>
);

ButtonBase.defaultProps = {
	className: null,
	size: "medium",
	variant: "contained",
	color: "primary",
	onClick: noop,
};

ButtonBase.propTypes = {
	size: PropTypes.string,
	variant: PropTypes.string,
	color: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
	classes: PropTypes.object.isRequired,
};

export default withStyles(useButtonBaseStyles)(ButtonBase);
