const SelectionsModel = ({
	zoneIds,
	heatEnergyIds,
	energyLabelIds,
	buildingIds,
	polygons,
}) => ({
	zoneIds: zoneIds || [],
	heatEnergyIds: heatEnergyIds || [],
	energyLabelIds: energyLabelIds || [],
	buildingIds: buildingIds || [],
	polygons: polygons || [],
});

module.exports = SelectionsModel;
