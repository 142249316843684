import "./styles.scss";
import "pure-react-carousel/dist/react-carousel.es.css";

import {
	Box,
	CircularProgress,
	Slide,
	Typography,
	withStyles,
} from "@material-ui/core";
import React, {useMemo} from "react";

import KPICard from "common/presentational/components/KPICard";
import StatusEnum from "utils/enums/StatusEnum";
import kpiDigitalTwinParams from "utils/constants/kpiDigitalTwinParams";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const KPICardList = ({
	classes,
	annualEnergyConsumptionKpi,
	energyProductionKpi,
	gesAndCO2EmissionKpi,
	commonKpi,
	isKpiAfterSimulation,
	totalEnergyConsumptionKpi,
	totalEnergyProductionKpi,
	totalGesAndCO2EmissionKpi,
	avgCostPerFinalCustomerKpi,
	investCostKpi,
	operatingCostKpi,
	localEnergyPartKpi,
	frenchEneryPartKpi,
	kpiStatus,
}) => {
	const {t} = useTranslation();

	const kpiDigitalTwinParamsCards = useMemo(() => {
		const kpisDictValues = {};
		if (isKpiAfterSimulation) {
			kpisDictValues[
				totalEnergyConsumptionKpi.key
			] = totalEnergyConsumptionKpi;
			kpisDictValues[
				totalEnergyProductionKpi.key
			] = totalEnergyProductionKpi;
			kpisDictValues[
				totalGesAndCO2EmissionKpi.key
			] = totalGesAndCO2EmissionKpi;
			kpisDictValues[
				avgCostPerFinalCustomerKpi.key
			] = avgCostPerFinalCustomerKpi;
			kpisDictValues[investCostKpi.key] = investCostKpi;
			kpisDictValues[operatingCostKpi.key] = operatingCostKpi;
			kpisDictValues[localEnergyPartKpi.key] = localEnergyPartKpi;
			kpisDictValues[frenchEneryPartKpi.key] = frenchEneryPartKpi;
		} else {
			kpisDictValues[
				annualEnergyConsumptionKpi.key
			] = annualEnergyConsumptionKpi;
			kpisDictValues[energyProductionKpi.key] = energyProductionKpi;
			kpisDictValues[gesAndCO2EmissionKpi.key] = gesAndCO2EmissionKpi;
		}

		return (
			kpiDigitalTwinParams[
				isKpiAfterSimulation ? "afterSimulation" : "beforeSimulation"
			] || []
		).map((kpiParam, i) => (
			<Slide
				direction="right"
				in={Boolean(kpiParam)}
				timeout={(i + 1) * 100}
				key={kpiParam.groupName}>
				<Box key={`${kpiParam.groupName}-${i}`}>
					<Typography
						className={classes.cardContent}
						color="textSecondary"
						gutterBottom>
						{t(
							`components.kpicardlist.kpigrouplabels.${kpiParam.groupName}`
						)}
					</Typography>
					<Box display="flex">
						{kpiParam.kpis.map((kpi, j) => (
							<KPICard
								key={kpi.key}
								type={kpi.type}
								title={t(
									`components.kpicardlist.kpilabels.${kpi.title}`
								)}
								fixedDigits={kpi.fixedDigits}
								isSubTotalPositif={
									kpisDictValues?.[kpi.key]?.isSubTotalPositif
								}
								value={kpisDictValues?.[kpi.key]?.value}
								subValue={kpisDictValues?.[kpi.key]?.subValue}
								isLoading={
									kpisDictValues?.[kpi.key]?.status ===
									StatusEnum.LOADING
								}
							/>
						))}
					</Box>
				</Box>
			</Slide>
		));
	}, [
		isKpiAfterSimulation,
		annualEnergyConsumptionKpi,
		energyProductionKpi,
		gesAndCO2EmissionKpi,
		totalEnergyConsumptionKpi,
		totalEnergyProductionKpi,
		totalGesAndCO2EmissionKpi,
		avgCostPerFinalCustomerKpi,
		investCostKpi,
		operatingCostKpi,
		localEnergyPartKpi,
		frenchEneryPartKpi,
		classes.cardContent,
		t,
	]);

	return (
		<Box display="flex" className={classes.card}>
			{kpiStatus === StatusEnum.LOADING ? (
				<CircularProgress
					disableShrink
					size={40}
					className={classes.kpiCircularProgress}
				/>
			) : (
				kpiDigitalTwinParamsCards
			)}
		</Box>
	);
};

export default withStyles(useStyles)(KPICardList);
