import {FormControl, Modal, TextField, withStyles} from "@material-ui/core";
import React, {useCallback, useEffect, useMemo, useState} from "react";

import {Add} from "@material-ui/icons";
import Backdrop from "@material-ui/core/Backdrop";
import ButtonBase from "common/presentational/components/Button/ButtonBase";
import Fade from "@material-ui/core/Fade";
import ScenarioAutocomplete from "common/presentational/components/ScenarioAutocomplete";
import i18n from "../../../i18next.config";
import parseScenarioTreeToList from "utils/helpers/parseScenarioTreeToList";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const scenarioDefaultValue = i18n
	.loadNamespaces("components.scenariocreationmodal.noparents.label")
	.then(() => ({
		id: null,
		value: "",
		name: i18n.t("components.scenariocreationmodal.noparents.label"),
	}));

const ScenarioCreationModal = ({
	classes,
	currentScenario,
	scenariosTree,
	createScenarioAction,
	isCreationModalOpen,
	closeCreationScenarioModalAction,
}) => {
	const {t} = useTranslation();

	const [scenarioParentValue, setScenarioParentValue] = useState(
		scenarioDefaultValue
	);

	const [scenarioNameValue, setScenarioNameValue] = useState(undefined);

	const [scenarioDescriptionValue, setScenarioDescriptionValue] = useState(
		""
	);

	useEffect(() => {
		if (currentScenario.scenario) {
			setScenarioParentValue({
				id: currentScenario.scenario.id,
				value: currentScenario.scenario.id,
				name: currentScenario.scenario.name,
			});
		} else {
			setScenarioParentValue(scenarioDefaultValue);
		}
	}, [currentScenario, currentScenario.scenario]);

	const handleScenarioParentValueChange = useCallback(
		(_, newScenarioParent) => {
			setScenarioParentValue(newScenarioParent);
		},
		[setScenarioParentValue]
	);

	const handleScenarioNameValueChange = useCallback(
		(event) => {
			setScenarioNameValue(event.target.value);
		},
		[setScenarioNameValue]
	);

	const handleScenarioDescriptionValueChange = useCallback(
		(event) => {
			setScenarioDescriptionValue(event.target.value);
		},
		[setScenarioDescriptionValue]
	);

	const generatedScenarioListMenuItem = useMemo(
		() =>
			parseScenarioTreeToList(scenariosTree.list)
				.map((scenario) => ({
					id: scenario.id,
					value: scenario.id,
					name: scenario.name,
				}))
				.concat({
					id: null,
					value: "",
					name: t("components.scenariocreationmodal.noparents.label"),
				}),
		[scenariosTree.list, t]
	);

	const closeCreationScenarioModalActionAndFlushState = useCallback(() => {
		closeCreationScenarioModalAction();
		setScenarioParentValue(scenarioDefaultValue);
		setScenarioNameValue(null);
		setScenarioDescriptionValue(null);
	}, [
		closeCreationScenarioModalAction,
		setScenarioParentValue,
		setScenarioNameValue,
		setScenarioDescriptionValue,
	]);

	const handleCreationScenario = useCallback(() => {
		createScenarioAction({
			parentId: scenarioParentValue.id,
			name: scenarioNameValue,
			description: scenarioDescriptionValue,
		});
		closeCreationScenarioModalActionAndFlushState();
	}, [
		scenarioParentValue,
		scenarioNameValue,
		scenarioDescriptionValue,
		closeCreationScenarioModalActionAndFlushState,
		createScenarioAction,
	]);

	return (
		<Modal
			className={classes.containerModalScenarioCreation}
			open={isCreationModalOpen?.value}
			onClose={closeCreationScenarioModalActionAndFlushState}
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 300,
			}}>
			<Fade in={isCreationModalOpen?.value}>
				<div className={classes.modalScenarioCreation}>
					<div className={classes.modalScenarioTitleContainer}>
						{isCreationModalOpen &&
							t("components.scenariocreationmodal.title.label")}
					</div>
					<div>
						<FormControl className={classes.formControl}>
							<ScenarioAutocomplete
								value={scenarioParentValue}
								onChange={handleScenarioParentValueChange}
								options={generatedScenarioListMenuItem}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t(
											"components.scenariocreationmodal.parentselection.label"
										)}
										variant="outlined"
									/>
								)}
								style={{width: "100%"}}
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								id="scenario-name"
								label={t(
									"components.scenariocreationmodal.scenarioname.label"
								)}
								value={scenarioNameValue}
								onChange={handleScenarioNameValueChange}
								variant="outlined"
							/>
						</FormControl>
						<FormControl className={classes.formControl}>
							<TextField
								id="scenario-description"
								label={t(
									"components.scenariocreationmodal.scenariodescription.label"
								)}
								value={scenarioDescriptionValue}
								onChange={handleScenarioDescriptionValueChange}
								variant="outlined"
								multiline
							/>
						</FormControl>
					</div>
					<div className={classes.addScenarioModalContainer}>
						<ButtonBase
							color="secondary"
							onClick={handleCreationScenario}
							endIcon={<Add />}
							disabled={
								scenarioNameValue == null ||
								scenarioNameValue === ""
							}>
							{t(
								"components.scenariocreationmodal.addbutton.label"
							)}
						</ButtonBase>
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default withStyles(useStyles)(React.memo(ScenarioCreationModal));
