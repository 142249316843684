import {
	getKpisAfterSelectionAction,
	setKpisAfterSelectionAction,
} from "domain/kpi/actions";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, select, takeLatest} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import {GET_POLYGON_SELECTION_BUILDINGS} from "../constants";
import KPIKeysEnum from "utils/enums/KPIKeysEnum";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {filterPolygonSelectionBuildingFiltersAction} from "domain/map/actions";
import {getDrawInstance} from "domain/map/actions";
import {selectFiltersBuildingList} from "utils/helpers/sagaSelectors";
import {setNotificationInfoAction} from "domain/notification/actions";

function* polygonSelectionBuildingsData(action) {
	try {
		// Get user drawn polygons
		const drawnPolygons = getDrawInstance().getAll();

		if (!drawnPolygons.features.length) {
			yield put(
				setKpisAfterSelectionAction({
					annualEnergyConsumptionKpi: {
						value: null,
						charts: null,
					},
					energyProductionKpi: {
						value: "",
					},
					gesAndCO2EmissionKpi: {
						value: null,
						charts: null,
					},
					commonKpi: {
						charts: null,
					},
				})
			);
			yield put(filterPolygonSelectionBuildingFiltersAction([], []));
			return;
		}

		// Get buildings filter list
		const buildingsFilterList = yield select(selectFiltersBuildingList);

		// Convert all polygons to a GEOJSON MultiPolygon geometry coordinates
		const polygons = drawnPolygons.features.map(
			(elt) => elt.geometry.coordinates
		);

		// Get a list of IdParcel of buildings contained in the MultiPolygon
		const {data: fullParcelIdList} = yield axios.post(
			ApiRoutePathsEnum.GET_BUILDINGS_POLYGON,
			{
				MultiPolygon: polygons,
			}
		);

		// Filter buildings with the selection card pre-selection
		const parcelIdList = fullParcelIdList.filter((value) =>
			buildingsFilterList.includes(value)
		);

		// Dispatch the parcel list to KPIs
		yield put(getKpisAfterSelectionAction());

		if (parcelIdList.length === 0) {
			yield put(
				setKpisAfterSelectionAction({
					annualEnergyConsumptionKpi: {
						value: null,
						charts: null,
					},
					energyProductionKpi: {
						value: "",
					},
					gesAndCO2EmissionKpi: {
						value: null,
						charts: null,
					},
					commonKpi: {
						charts: null,
					},
				})
			);
			return;
		}
		const {data} = yield axios.post(ApiRoutePathsEnum.GET_GLOBAL_KPIS, {
			parcelIdList,
		});
		yield put(
			setKpisAfterSelectionAction({
				annualEnergyConsumptionKpi: {
					value: data[KPIKeysEnum.annualEnergyConsumption]?.total,
					charts: data[KPIKeysEnum.annualEnergyConsumption]?.charts,
				},
				energyProductionKpi: {
					value: "",
				},
				gesAndCO2EmissionKpi: {
					value: data[KPIKeysEnum.gesAndCO2Emission]?.total,
					charts: data[KPIKeysEnum.gesAndCO2Emission]?.charts,
				},
				commonKpi: {
					charts: data["extra"]?.labelsCount?.charts,
				},
			})
		);

		// Dispatch the parcel list to get the number of buildings
		yield put(
			filterPolygonSelectionBuildingFiltersAction(parcelIdList, polygons)
		);
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.POLYGONS,
				notificationInfos.POLYGONS.error.GET_POLYGON_SELECTION_BUILDINGS
			)
		);
	}
}

function* getPolygonSelectionBuildingsData() {
	yield takeLatest(
		GET_POLYGON_SELECTION_BUILDINGS,
		polygonSelectionBuildingsData
	);
}

export default getPolygonSelectionBuildingsData;
