const IndicatorsModel = require("./IndicatorsModel");
const OutputIndicatorsModel = require("./OutputIndicatorsModel");
const SelectionsModel = require("./SelectionsModel");
const ProducerModel = require("./ProducerModel");

const currentYear = new Date().getFullYear();

const ScenarioModel = ({
	id,
	name,
	description,
	type,
	indicators,
	selections,
	modifications,
	simulation_start,
	simulation_end,
	producers,
	parentId,
	sagaId,
	status,
	userId,
	userName,
	outputIndicators,
}) => ({
	id,
	name: name || "",
	description: description || "",
	type: type || "base",
	outputIndicators: OutputIndicatorsModel(outputIndicators || {}),
	indicators: IndicatorsModel(indicators || {}),
	selections: SelectionsModel(selections || {}),
	modifications: modifications || {},
	producers: (producers || []).map((producer) => ProducerModel(producer)),

	// ? Source ?
	sagaId: sagaId || "",

	simulation_start: simulation_start || currentYear,
	simulation_end: simulation_end || currentYear + 20,

	parentId: parentId || "",
	status: status || "",
	userId: userId || "",
	userName: userName || "",
});

module.exports = ScenarioModel;
