import {
	black,
	blue,
	brown,
	darkBlue,
	darkBrown,
	darkGrey,
	darkPink,
	darkPurple,
	darkRed,
	darkerBlue,
	darkerGreen,
	darkerGrey,
	darkerPink,
	darkerTurquoise,
	gold,
	greenSea,
	grey,
	lightLime,
	lightPink,
	lightPurple,
	lime,
	orange,
	paleGrey,
	paleOrange,
	paleOrangeDark,
	palePink,
	pink,
	pinkRed,
	purple,
	skyBlue,
	turquoise,
	veryLightPurple,
	whiteGrey,
	yellow,
} from "styles/mapLayoutColors.scss";

import LayerIdEnum from "utils/enums/LayerIdEnum";
import SourceIdEnum from "utils/enums/SourceIdEnum";

const zoomThreshold = 4;

const network_heat_not_gaz = [
	"all",
	["==", ["get", "ishestnetwokrconnected"], true],
	["==", ["get", "isgasnetworkconnected"], false],
];
const network_heat_and_gaz = [
	"all",
	["==", ["get", "ishestnetwokrconnected"], true],
	["==", ["get", "isgasnetworkconnected"], true],
];
const network_not_heat_not_gaz = [
	"all",
	["==", ["get", "ishestnetwokrconnected"], false],
	["==", ["get", "isgasnetworkconnected"], false],
];
const network_not_heat_gaz = [
	"all",
	["==", ["get", "ishestnetwokrconnected"], false],
	["==", ["get", "isgasnetworkconnected"], true],
];

export default (map) => {
	const layers = map.getStyle()?.layers;
	let firstSymbolId;
	for (let i = 0; i < layers?.length; i++) {
		if (layers[i].type === "symbol") {
			firstSymbolId = layers[i].id;
			break;
		}
	}

	map.addLayer({
		id: LayerIdEnum.ESTIMATION_CONSO_CHALEUR,
		type: "line",
		source: SourceIdEnum.ESTIMATION_CONSO_CHALEUR.id,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": {
				property: "Densite_CC",
				stops: [
					[0, turquoise],
					[10, darkerBlue],
				],
			},
			"line-width": 3,
		},
	});

	map.addLayer({
		id: LayerIdEnum.CPS_RESIDENTIEL_TERTIAIRE_CLUSTER,
		type: "circle",
		source: SourceIdEnum.CPS_RESIDENTIEL_TERTIAIRE.id,
		filter: ["has", "point_count"],
		paint: {
			"circle-color": [
				"interpolate",
				["linear"],
				["get", "point_count"],
				0,
				turquoise,
				800,
				darkerBlue,
			],
			"circle-radius": [
				"interpolate",
				["linear"],
				["zoom"],
				8,
				[
					"interpolate",
					["linear"],
					["get", "point_count"],
					0,
					8,
					800,
					14,
				],
				11,
				[
					"interpolate",
					["linear"],
					["get", "point_count"],
					0,
					10,
					800,
					16,
				],
				16,
				[
					"interpolate",
					["linear"],
					["get", "point_count"],
					0,
					14,
					800,
					20,
				],
			],
		},
	});

	map.addLayer({
		id: LayerIdEnum.CPS_RESIDENTIEL_TERTIAIRE_CLUSTER_COUNT,
		type: "symbol",
		source: SourceIdEnum.CPS_RESIDENTIEL_TERTIAIRE.id,
		filter: ["has", "point_count"],
		layout: {
			"text-field": "{point_count_abbreviated}",
			"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
			"text-size": {
				stops: [
					[8, 10],
					[11, 12],
					[16, 14],
				],
			},
		},
	});

	map.addLayer(
		{
			id: LayerIdEnum.CPS_RESIDENTIEL_TERTIAIRE_UNCLUSTERED_POINTS,
			type: "circle",
			source: SourceIdEnum.CPS_RESIDENTIEL_TERTIAIRE.id,
			filter: ["!", ["has", "point_count"]],
			paint: {
				"circle-radius": {
					stops: [
						[14, 6],
						[20, 8],
					],
				},
				"circle-opacity": 0.8,
				"circle-color": {
					property: "CALO_MWh",
					stops: [
						[0, turquoise],
						[1000, darkerBlue],
					],
				},
			},
		},
		firstSymbolId
	);

	map.addLayer({
		id: LayerIdEnum.RESEAU_CHALEUR,
		type: "line",
		source: SourceIdEnum.RESEAU_CHALEUR.id,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": darkRed,
			//'line-color': ['get', 'color'],
			"line-width": 2,
		},
	});

	map.addLayer(
		{
			id: LayerIdEnum.GEOTHERMIE_EXPLOITATION,
			type: "fill",
			source: SourceIdEnum.GEOTHERMIE_EXPLOITATION.id,
			layout: {},
			paint: {
				"fill-color": paleOrange,
				"fill-opacity": 0.4,
			},
			filter: ["==", "PERIMETRE", "Actuel"],
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.GEOTHERMIE_EXPLOITATION_BORDURE,
			type: "line",
			source: SourceIdEnum.GEOTHERMIE_EXPLOITATION.id,
			layout: {
				"line-join": "round",
				"line-cap": "round",
			},
			paint: {
				"line-color": paleOrangeDark,
				"line-width": 2,
			},
			filter: ["==", "PERIMETRE", "Actuel"],
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.DOGGER,
			type: "fill",
			source: SourceIdEnum.GEOTHERMAL_POTENTIAL.id,
			layout: {},
			paint: {
				"fill-color": [
					"match",
					["get", "dogger"],
					"0",
					palePink,
					[
						"interpolate",
						["linear"],
						["to-number", ["get", "dogger"]],
						1,
						brown,
						5,
						darkBrown,
					],
				],
				"fill-opacity": 0.7,
			},
			filter: ["==", "geoinfo", "1"],
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.LUSITANIEN,
			type: "fill",
			source: SourceIdEnum.GEOTHERMAL_POTENTIAL.id,
			layout: {},
			paint: {
				"fill-color": [
					"match",
					["get", "Lusitanien"],
					"0",
					palePink,
					[
						"interpolate",
						["linear"],
						["to-number", ["get", "Lusitanien"]],
						1,
						brown,
						5,
						darkBrown,
					],
				],
				"fill-opacity": 0.7,
			},
			filter: ["==", "geoinfo", "1"],
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.NEOCOMIEN,
			type: "fill",
			source: SourceIdEnum.GEOTHERMAL_POTENTIAL.id,
			layout: {},
			paint: {
				"fill-color": [
					"match",
					["get", "Néocomien"],
					"0",
					palePink,
					[
						"interpolate",
						["linear"],
						["to-number", ["get", "Néocomien"]],
						1,
						brown,
						5,
						darkBrown,
					],
				],
				"fill-opacity": 0.7,
			},
			filter: ["==", "geoinfo", "1"],
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.ALBIEN,
			type: "fill",
			source: SourceIdEnum.GEOTHERMAL_POTENTIAL.id,
			layout: {},
			paint: {
				"fill-color": [
					"match",
					["get", "Albien"],
					"0",
					palePink,
					[
						"interpolate",
						["linear"],
						["to-number", ["get", "Albien"]],
						1,
						brown,
						5,
						darkBrown,
					],
				],
				"fill-opacity": 0.7,
			},
			filter: ["==", "geoinfo", "1"],
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.ZAC,
			type: "fill",
			source: SourceIdEnum.ZAC.id,
			layout: {},
			paint: {
				//'#318CE7'

				"fill-color": [
					"match",
					["get", "statut"],
					"en cours de réalisation",
					orange,
					"projet",
					darkGrey,
					"supprimée",
					pinkRed,
					"créée",
					skyBlue,
					/* other */ grey,
				],
				"fill-opacity": 0.4,
			},
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.PARCELLES_FOND,
			type: "fill",
			source: SourceIdEnum.PARCELLES.id,
			minzoom: 10,
			layout: {},
			paint: {
				"fill-color": [
					"case",
					network_heat_not_gaz,
					darkRed,
					network_heat_and_gaz,
					lightPink,
					network_not_heat_not_gaz,
					blue,
					network_not_heat_gaz,
					yellow,
					blue,
				],
				"fill-opacity": 0.4,
			},
		},
		firstSymbolId
	);

	map.addLayer({
		id: LayerIdEnum.RESEAU_GAZ,
		type: "line",
		source: SourceIdEnum.RESEAU_GAZ.id,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": gold,
			"line-width": 2,
		},
	});

	map.addLayer(
		{
			id: LayerIdEnum.CPS_DENSITE,
			type: "fill",
			source: SourceIdEnum.CPS.id,
			layout: {},
			paint: {
				"fill-color": [
					"let",
					"density",
					[
						"*",
						["/", ["get", "popul"], ["get", "st_area_sha"]],
						1000000,
					],
					[
						"interpolate",
						["linear"],
						["zoom"],
						8,
						[
							"interpolate",
							["linear"],
							["var", "density"],
							100,
							["to-color", paleGrey],
							5000,
							["to-color", darkerGreen],
						],
						10,
						[
							"interpolate",
							["linear"],
							["var", "density"],
							100,
							["to-color", whiteGrey],
							5000,
							["to-color", darkBlue],
						],
					],
				],
				"fill-opacity": 0.7,
			},
		},
		firstSymbolId
	);

	map.addLayer({
		id: LayerIdEnum.CPS_BORDER,
		type: "line",
		source: SourceIdEnum.CPS.id,
		minzoom: zoomThreshold,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": black,
			"line-width": 1,
		},
	});

	map.addLayer({
		id: LayerIdEnum.IRIS_BORDER,
		type: "line",
		source: SourceIdEnum.IRIS.id,
		minzoom: zoomThreshold,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": black,
			"line-width": 2,
		},
	});

	map.addLayer({
		id: LayerIdEnum.BORNES_RECHARGE_ELECTRIQUE,
		type: "circle",
		source: SourceIdEnum.BORNES_RECHARGE_ELECTRIQUE.id,
		layout: {},
		paint: {
			"circle-radius": [
				"interpolate",
				["linear"],
				["zoom"],
				8,
				[
					"interpolate",
					["linear"],
					["get", "nb_prises"],
					0,
					["+", 4, ["/", ["get", "nb_prises"], 2]],
					22,
					["+", 8, ["/", ["get", "nb_prises"], 2]],
				],
				11,
				[
					"interpolate",
					["linear"],
					["get", "nb_prises"],
					0,
					["+", 6, ["/", ["get", "nb_prises"], 2]],
					22,
					["+", 10, ["/", ["get", "nb_prises"], 2]],
				],
				16,
				[
					"interpolate",
					["linear"],
					["get", "nb_prises"],
					0,
					["+", 14, ["/", ["get", "nb_prises"], 2]],
					22,
					["+", 20, ["/", ["get", "nb_prises"], 2]],
				],
			],
			"circle-opacity": 0.8,
			"circle-color": {
				property: "nb_prises",
				stops: [
					[1, lightLime],
					[22, lime],
				],
				default: darkerGrey,
			},
		},
	});

	map.addLayer({
		id: LayerIdEnum.BORNES_RECHARGE_ELECTRIQUE_LABELS,
		type: "symbol",
		source: SourceIdEnum.BORNES_RECHARGE_ELECTRIQUE.id,
		filter: ["has", "nb_prises"],
		layout: {
			"text-field": ["get", "nb_prises"],
			"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
			"text-size": {
				stops: [
					[8, 6],
					[11, 12],
					[16, 14],
				],
			},
		},
	});

	map.addLayer({
		id: LayerIdEnum.TRONCON_AERIEN,
		type: "line",
		source: SourceIdEnum.TRONCON_AERIEN.id,
		minzoom: 12.5,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": darkPurple,
			"line-width": [
				"interpolate",
				["exponential", 2],
				["zoom"],
				10,
				["*", 2, ["^", 2, -3]],
				24,
				["*", 2, ["^", 2, 5]],
			],
		},
	});

	map.addLayer({
		id: LayerIdEnum.TRONCON_CABLE,
		type: "line",
		source: SourceIdEnum.TRONCON_CABLE.id,
		minzoom: 12.5,
		layout: {
			"line-join": "round",
			"line-cap": "round",
		},
		paint: {
			"line-color": darkPurple,
			"line-width": [
				"interpolate",
				["exponential", 2],
				["zoom"],
				10,
				["*", 2, ["^", 2, -3]],
				24,
				["*", 2, ["^", 2, 5]],
			],
		},
	});

	map.addLayer({
		id: LayerIdEnum.POSTE_SOURCE,
		type: "circle",
		source: SourceIdEnum.POSTE_SOURCE.id,
		paint: {
			"circle-radius": [
				"interpolate",
				["linear"],
				["zoom"],
				8,
				[
					"interpolate",
					["linear"],
					["to-number", ["get", "SOMME_PUIS"]],
					0,
					1,
					700,
					4,
				],
				11,
				[
					"interpolate",
					["linear"],
					["to-number", ["get", "SOMME_PUIS"]],
					0,
					2,
					700,
					4,
				],
				16,
				[
					"interpolate",
					["linear"],
					["to-number", ["get", "SOMME_PUIS"]],
					0,
					8,
					700,
					14,
				],
			],
			"circle-opacity": 0.8,
			"circle-color": [
				"step",
				["to-number", ["get", "SOMME_PUIS"]],
				pink,
				400,
				darkPink,
				600,
				darkerPink,
			],
			"circle-stroke-color": orange,
			"circle-stroke-width": 1,
		},
	});

	map.addLayer({
		id: LayerIdEnum.POSTE_ELECTRIQUE_CLUSTER,
		type: "circle",
		source: SourceIdEnum.POSTE_ELEC.id,
		filter: ["has", "point_count"],
		paint: {
			"circle-color": [
				"interpolate",
				["linear"],
				["get", "point_count"],
				0,
				pink,
				800,
				darkerPink,
			],
			"circle-radius": [
				"interpolate",
				["linear"],
				["zoom"],
				8,
				[
					"interpolate",
					["linear"],
					["get", "point_count"],
					0,
					8,
					800,
					14,
				],
				11,
				[
					"interpolate",
					["linear"],
					["get", "point_count"],
					0,
					10,
					800,
					16,
				],
				16,
				[
					"interpolate",
					["linear"],
					["get", "point_count"],
					0,
					14,
					800,
					20,
				],
			],
		},
	});

	map.addLayer({
		id: LayerIdEnum.POSTE_ELECTRIQUE_CLUSTER_COUNT,
		type: "symbol",
		source: SourceIdEnum.POSTE_ELEC.id,
		filter: ["has", "point_count"],
		layout: {
			"text-field": "{point_count_abbreviated}",
			"text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
			"text-size": {
				stops: [
					[8, 10],
					[11, 12],
					[16, 14],
				],
			},
		},
	});

	map.addLayer(
		{
			id: LayerIdEnum.POSTE_ELECTRIQUE_UNCLUSTERED_POINTS,
			type: "circle",
			source: SourceIdEnum.POSTE_ELEC.id,
			filter: ["!", ["has", "point_count"]],
			paint: {
				"circle-radius": {
					stops: [
						[14, 6],
						[20, 8],
					],
				},
				"circle-opacity": 0.8,
				"circle-color": [
					"interpolate",
					["linear"],
					["to-number", ["get", "PUISSAN_T"]],
					0,
					pink,
					2500,
					darkerPink,
				],
			},
		},
		firstSymbolId
	);

	map.addLayer(
		{
			id: LayerIdEnum.WIND_POTENTIAL,
			type: "fill",
			source: SourceIdEnum.WIND_POTENTIAL.id,
			layout: {},
			paint: {
				"fill-color": [
					"match",
					["get", "Zone"],
					"Favorable à fortes contraintes",
					veryLightPurple,
					"Favorable à contraintes modérées",
					lightPurple,
					"Favorable",
					purple,
					veryLightPurple,
				],
				"fill-opacity": 0.6,
			},
		},
		firstSymbolId
	);

	// TODO : remove unecessary cities and clean filter
	map.addLayer(
		{
			id: LayerIdEnum.ROOFTOP_POTENTIAL,
			type: "fill",
			source: SourceIdEnum.ROOFTOP_POTENTIAL.id,
			layout: {},
			paint: {
				"fill-color": [
					"interpolate",
					["linear"],
					["get", "productible"],
					900,
					darkerTurquoise,
					100000,
					greenSea,
				],
				"fill-opacity": 0.9,
			},
		},
		firstSymbolId
	);
};
