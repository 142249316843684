import {
	DELETE_SCENARIOS,
	RESET_CURRENT_SCENARIO,
	RESET_SCENARIO_FORM_VALUES,
} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import {RESET_KPIS} from "domain/kpi/constants";
import {RESET_SCENARIO_PARAM} from "domain/parameters/constants";
import {RESET_SELECTION_CURRENT_FILTERS} from "domain/map/constants";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {getScenarioTreeAction} from "../actions";
import {setNotificationInfoAction} from "domain/notification/actions";

function* scenarioData(action) {
	try {
		yield axios.delete(ApiRoutePathsEnum.DELETE_SCENARIOS, {
			data: {scenarioIdList: action.scenarios},
		});
		yield put(
			getScenarioTreeAction({
				from: "fromCreate",
			})
		);

		yield put({type: RESET_SCENARIO_PARAM});

		yield put({type: RESET_SELECTION_CURRENT_FILTERS});

		yield put({type: RESET_KPIS});

		yield put({type: RESET_SCENARIO_FORM_VALUES});

		yield put({type: RESET_CURRENT_SCENARIO});

		yield put(
			setNotificationInfoAction(
				StatusEnum.SUCCESS,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.success.DELETE_SCENARIOS
			)
		);
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.error.DELETE_SCENARIOS
			)
		);
	}
}

function* deleteScenariosData() {
	yield takeEvery(DELETE_SCENARIOS, scenarioData);
}

export default deleteScenariosData;
