const useStyles = (theme) => ({
	formControl: {
		marginTop: `${theme.spacing(2)}px`,
		width: "100%",
	},
	titleContainer: {
		display: "flex",
		justifyContent: "space-between",
		marginBottom: theme.spacing(1),
	},
	buildingNumberTag: {
		border: "2px solid",
		borderRadius: "8px",
		fontSize: "12px",
		textTransform: "uppercase",
		padding: "4px",
		fontWeight: "bold",
		marginRight: "2px",
	},
	zoneTagsContainer: {
		display: "inline-flex",
		flexWrap: "wrap",
		gap: "5px",
	},
	zoneTag: {
		display: "flex",
		backgroundColor: "black",
		padding: "5px",
		border: "1px solid rgba(255,255,255,0.12)",
		borderRadius: "16px",
	},
	zoneTagName: {
		padding: "0 6px",
	},
});

export default useStyles;
