import {
	GET_KPIS_AFTER_SELECTION,
	RESET_KPIS,
	SET_KPIS_AFTER_SELECTION,
	SET_KPIS_AFTER_SIMULATION,
} from "./constants";

import {NotificationTitles} from "utils/constants/notificationInfos";
import {SET_NOTIFICATION_INFO} from "domain/notification/constants";
import StatusEnum from "utils/enums/StatusEnum";

export const initialStateKpiAfterSimulation = (key = null) => ({
	status: StatusEnum.IDLE,
	value: null,
	subValue: null,
	isSubTotalPositif: null,
	key,
});

export const initialStateKpiAfterSelection = (key = null) => ({
	status: StatusEnum.IDLE,
	value: null,
	key,
	charts: null,
});

export const generateKpiAfterSimulationReducer = (state, action, key) => {
	switch (action.type) {
		case SET_NOTIFICATION_INFO:
			if (
				action.status === StatusEnum.ERROR &&
				action.title === NotificationTitles.KPI
			) {
				return {
					...state,
					status: StatusEnum.ERROR,
					value: null,
				};
			}
			return state;
		case RESET_KPIS:
			return {
				...state,
				status: StatusEnum.IDLE,
				value: null,
			};
		case SET_KPIS_AFTER_SIMULATION:
			return {
				...state,
				...action[key],
				status: StatusEnum.SUCCESS,
			};
		default:
			return state;
	}
};

export const generateKpiAfterSelectionReducer = (state, action, key) => {
	switch (action.type) {
		case SET_NOTIFICATION_INFO:
			if (
				action.status === StatusEnum.ERROR &&
				action.title === NotificationTitles.KPI
			) {
				return {
					...state,
					status: StatusEnum.ERROR,
					value: null,
					charts: null,
				};
			}
			return state;
		case RESET_KPIS:
			return {
				...state,
				status: StatusEnum.IDLE,
				value: null,
				charts: null,
			};
		case GET_KPIS_AFTER_SELECTION:
			return {
				...state,
				status: StatusEnum.LOADING,
			};
		case SET_KPIS_AFTER_SELECTION:
			return {
				...state,
				...action[key],
				status: StatusEnum.SUCCESS,
			};
		default:
			return state;
	}
};
