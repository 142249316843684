import {CLOSE_NOTIFICATION_MODAL, SET_NOTIFICATION_INFO} from "./constants";

export const setNotificationInfoAction = (status, title, message) => ({
	type: SET_NOTIFICATION_INFO,
	status,
	message,
	title,
});

export const closeNotificationModalAction = () => ({
	type: CLOSE_NOTIFICATION_MODAL,
});
