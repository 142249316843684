import {all, fork} from "redux-saga/effects";
import {
	initiateMapDrawEventsSaga,
	watchRedirectChannel,
} from "./initiateMapDrawEventsSaga";

import getPolygonSelectionBuildingsData from "./getPolygonSelectionBuildings";
import getSelectionBuildingFiltersData from "./getSelectionBuildingFiltersData";
import getSelectionEnergyLabelFiltersData from "./getSelectionEnergyLabelFiltersData";
import getSelectionHeatEnergyTypeFiltersData from "./getSelectionHeatEnergyTypeFiltersData";
import getSelectionZoneFiltersData from "./getSelectionZoneFiltersData";

export default function* mapSaga() {
	yield all([
		fork(getSelectionZoneFiltersData),
		fork(getSelectionHeatEnergyTypeFiltersData),
		fork(getSelectionEnergyLabelFiltersData),
		fork(getSelectionBuildingFiltersData),
		fork(getPolygonSelectionBuildingsData),
		fork(watchRedirectChannel),
		fork(initiateMapDrawEventsSaga),
	]);
}
