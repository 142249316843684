import {SET_EMBED_INFO} from "./constants";
import {combineReducers} from "redux";

const powerbiReducer = combineReducers({
	info: (
		state = {
			accessToken: "",
			embedUrl: "",
			expiry: "",
		},
		action
	) => {
		switch (action.type) {
			case SET_EMBED_INFO:
				return {
					...state,
					accessToken: action.accessToken,
					embedUrl: action.embedUrl,
					expiry: action.expiry,
				};
			default:
				return state;
		}
	},
});

export default powerbiReducer;
