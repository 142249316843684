import {
	GET_SELECTION_BUILDING_FILTERS,
	SET_SELECTION_BUILDING_FILTERS,
} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, takeLatest} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import KPIKeysEnum from "utils/enums/KPIKeysEnum";
import LayerIdEnum from "utils/enums/LayerIdEnum";
import {SET_KPIS_AFTER_SELECTION} from "domain/kpi/constants";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {filterByLayerDataFeatureMapAction} from "../layerActions";
import {getKpisAfterSelectionAction} from "domain/kpi/actions";
import {getMapInstance} from "../actions";
import {setNotificationInfoAction} from "domain/notification/actions";
import {withQueries} from "utils/helpers/apiHelpers";

function* selectionBuildingFiltersData(action) {
	if (!action.zoneIds && !action.heatenergies && !action.energylabel) {
		return;
	}
	let response = {};
	try {
		response = yield axios.get(
			withQueries({
				zones: action.zoneIds,
				heatenergies: action.heatenergies,
				energylabel: action.energylabel,
			})(ApiRoutePathsEnum.GET_BUILDINGS)
		);

		const mapInstance = getMapInstance();

		if (mapInstance) {
			filterByLayerDataFeatureMapAction(
				mapInstance,
				LayerIdEnum.PARCELLES_FOND,
				"idpar",
				(response.data || []).map((d) =>
					d["Idparcel"].replaceAll("_", " ")
				)
			);
		}
		yield put({
			type: SET_SELECTION_BUILDING_FILTERS,
			list: response.data || [],
		});
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.PARCELLE_SELECTION,
				notificationInfos.PARCELLE_SELECTION.error.GET_ID_PARCELLES
			)
		);
	}

	try {
		const parcelIdList = (response.data || []).map((d) => d["Idparcel"]);
		if (parcelIdList.length > 0) {
			yield put(getKpisAfterSelectionAction());
			const {data: dataKpi} = yield axios.post(
				ApiRoutePathsEnum.GET_GLOBAL_KPIS,
				{
					parcelIdList,
				}
			);
			yield put({
				type: SET_KPIS_AFTER_SELECTION,
				annualEnergyConsumptionKpi: {
					value: dataKpi[KPIKeysEnum.annualEnergyConsumption]?.total,
					charts:
						dataKpi[KPIKeysEnum.annualEnergyConsumption]?.charts,
				},
				energyProductionKpi: {
					value: null,
				},
				gesAndCO2EmissionKpi: {
					value: dataKpi[KPIKeysEnum.gesAndCO2Emission]?.total,
					charts: dataKpi[KPIKeysEnum.gesAndCO2Emission]?.charts,
				},
				commonKpi: {
					value: null,
					charts: dataKpi["extra"]?.labelsCount?.charts,
				},
			});
		}
	} catch (error) {
		console.log(error);
		yield put({
			type: SET_KPIS_AFTER_SELECTION,
			annualEnergyConsumptionKpi: {
				value: null,
			},
			energyProductionKpi: {
				value: null,
			},
			gesAndCO2EmissionKpi: {
				value: null,
			},
			commonKpi: {
				value: null,
			},
		});
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.KPI,
				notificationInfos.KPI.error.GET_KPIS
			)
		);
	}
}

function* getSelectionBuildingFiltersData() {
	yield takeLatest(
		GET_SELECTION_BUILDING_FILTERS,
		selectionBuildingFiltersData
	);
}

export default getSelectionBuildingFiltersData;
