import {
	GET_SELECTION_ENERGY_LABEL_FILTERS,
	SET_SELECTION_ENERGY_LABEL_FILTERS,
} from "../constants";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import axios from "axios";
import {withQueries} from "utils/helpers/apiHelpers";

function* selectionEnergyLabelFiltersData(action) {
	if (!action.zoneIds && !action.heatenergies) {
		yield put({type: SET_SELECTION_ENERGY_LABEL_FILTERS, list: []});
		return;
	}

	const {data} = yield axios.get(
		withQueries({
			zones: action.zoneIds,
			heatenergies: action.heatenergies,
		})(ApiRoutePathsEnum.GET_ENERGY_LABEL)
	);

	yield put({type: SET_SELECTION_ENERGY_LABEL_FILTERS, list: data});
}

function* getSelectionEnergyLabelFiltersData() {
	yield takeEvery(
		GET_SELECTION_ENERGY_LABEL_FILTERS,
		selectionEnergyLabelFiltersData
	);
}

export default getSelectionEnergyLabelFiltersData;
