export const POWERBI_REPORTS = {
	POWERBI_DASHBOARD: "PSE-PowerBI",
	POWERBI_DASHBOARD_MAINPAGE: "PSE-MainPage-PowerBI",
};

export const POWERBI_DASHBOARD_PAGES = {
	en: ["Scenario KPI", "Scenario comparison"],
	fr: ["Scénario ICP", "Comparaison de scénarios"],
};

export const POWERBI_DASHBOARD_MAINPAGE_PAGES = {
	en: ["Energy class", "Energy consumption", "GHG emissions", "Building"],
	fr: [
		"Classe énergétique",
		"Consommation énergétique",
		"Emissions de GES",
		"Bâtiments",
	],
};
