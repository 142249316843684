const useStyles = (theme) => ({
	containerModal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modal: {
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	card: {
		marginTop: theme.spacing(3),
		padding: "0px",
		flexShrink: 0,
		overflowX: "auto",
		scrollbarWidth: "thin",
		scrollbarTrackColor: "black",
		"&> .MuiCardContent-root": {
			padding: "2px",
			paddingBottom: "0px",
			paddingTop: "24px",
			margin: "0px",
		},
	},
	cardContent: {
		marginBottom: theme.spacing(1),
		margin: theme.spacing(1),
	},
	title: {
		fontSize: 14,
	},
	kpiCircularProgress: {
		margin: "3.42rem 0.2rem",
	},
});

export default useStyles;
