const useStyles = (theme) => ({
	containerModalScenarioCreation: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modalScenarioTitleContainer: {
		width: "100%",
		textAlign: "center",
		color: theme.palette.white,
		fontSize: "24px",
		padding: "8px",
	},
	modalScenarioCreation: {
		position: "relative",
		bottom: "10%",
		width: "500px",
		backgroundColor: theme.palette.background.secondary,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: "15px",
	},
	mainCard: {
		width: "100%",
		height: "100%",
		borderRadius: "8px",
		background: theme.palette.background.secondary,
		padding: `0px ${theme.spacing(2)}px`,
	},
	scenarioManagerPanel: {
		flexGrow: 1,
		padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
			3
		)}px`,
		display: "flex",
		flexDirection: "column",
	},
	page: {
		background: theme.palette.background.default,
		display: "flex",
		flexDirection: "column",
		height: "100%",
	},
	grid: {
		margin: `${theme.spacing(1)}px ${theme.spacing(-1)}px ${
			1.5 * theme.spacing(-1)
		}px ${theme.spacing(-1)}px`,
		minHeight: "70vh",
	},
	formControl: {
		marginTop: `${theme.spacing(2)}px`,
		width: "100%",
	},
	addScenarioModalContainer: {
		marginTop: "20px",
		display: "flex",
		justifyContent: "flex-end",
	},
	inputLabel: {
		zIndex: "10",
		paddingLeft: "10px",
		paddingTop: "2px",
		paddingBottom: "4px",
		color: "#8b8b8b",
	},
	deleteScenarioModalContainer: {
		display: "flex",
		justifyContent: "space-evenly",
		margin: ".5rem",
	},
	deleteScenarioButtonContainer: {
		margin: ".5rem",
	},
	navBarContainer: {
		padding: "1em 2em 1em",
	},
	scenarioButtonsContainer: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "flex-end",
	},
	breadcrumbScenarioContainer: {
		display: "flex",
		alignItems: "center",
		height: "3.5rem",
	},
	managerCircularProgress: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
});

export default useStyles;
