import {
	resetScenarioFormValuesAction,
	setCurrentScenarioAction,
} from "domain/scenario/actions";

import ScenarioBreadcrumb from "./ScenarioBreadcrumb";
import {connect} from "react-redux";
import {getScenarioParametersAction} from "domain/parameters/actions";
import {resetKpisAction} from "domain/kpi/actions";
import {resetScenarioParametersAction} from "domain/parameters/actions";
import {resetSelectionCurrentFiltersAction} from "domain/map/actions";

const mapStateToProps = (state) => ({
	scenarioTree: state.scenarioReducer.scenarioTree,
	currentScenario: state.scenarioReducer.currentScenario,
});

const mapDispatchToProps = {
	setCurrentScenarioAction,
	resetScenarioParametersAction,
	resetSelectionCurrentFiltersAction,
	getScenarioParametersAction,
	resetKpisAction,
	resetScenarioFormValuesAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScenarioBreadcrumb);
