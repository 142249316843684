import {Box, CircularProgress, Tab, Tabs, withStyles} from "@material-ui/core";
import {
	NetworkConnectionRow,
	NewNetworkCreationRow,
	ProductionSourceRow,
	RenovationRow,
	ZacRow,
} from "domain/scenario/models/scenarioParamsModels";
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import scenarioTabParams, {
	StaticLists,
} from "utils/constants/scenarioTabParams";

import PropTypes from "prop-types";
import ScenarioParamTable from "common/presentational/components/ScenarioParamTable";
import ScenarioTabPanel from "common/presentational/components/ScenarioTabPanel";
import StatusEnum from "utils/enums/StatusEnum";
import debounce from "lodash.debounce";
import {useStyles} from "./muiStyles";
import {useTranslation} from "react-i18next";

const ScenarioParamsTableTabs = ({
	classes,
	values,
	setFieldValue,
	zonesList,
	producers,
	buildings,
	modifications,
	setScenarioFormValuesAction,
}) => {
	const {t} = useTranslation();

	const [fieldsDataFromApi, setFieldsDataFromApi] = useState({});

	const [tabValue, setTabValue] = useState(0);

	const handleChangeTab = (_, newValue) => {
		setTabValue(newValue);
	};

	const scenarioTabPanel = useMemo(() => {
		return (
			<>
				{scenarioTabParams.map((scenarioTabParam) => (
					<ScenarioTabPanel
						key={scenarioTabParam.key}
						index={scenarioTabParam.index}
						value={tabValue}>
						<ScenarioParamTable
							formSection={scenarioTabParam.key}
							rowsNumber={values[scenarioTabParam.key]?.length}
							fields={scenarioTabParam.fields}
							fieldsDataFromApi={fieldsDataFromApi}
						/>
					</ScenarioTabPanel>
				))}
			</>
		);
	}, [tabValue, values]);

	// Input of parametres into the Form
	useEffect(() => {
		if (zonesList?.length) {
			setFieldsDataFromApi({
				...fieldsDataFromApi,
				city: zonesList.map((zone) => zone["name"]),
			});
		}

		if (modifications?.table?.newNetworkCreation?.length) {
			setFieldValue(
				"newNetworkCreation",
				modifications.table.newNetworkCreation.map((element) =>
					NewNetworkCreationRow({
						name: element["IdHeatNetwork"],
						fixedPrice: element["CustomersFixedPrice"],
						variableMargin: element["VariableMargin"],
					})
				)
			);

			if (modifications?.table?.productionSource) {
				setFieldValue(
					"productionSource",
					Object.values(modifications.table.productionSource).map(
						(e) =>
							ProductionSourceRow({
								controlable: e["IsControllable"],
								priority: e["Priority"],
								maxPower: e["PowerMax"],
								gesPercent: e["GHGRate"],
								fixedCost: e["FixedCost"],
								supplyCost: e["VariableCost"],
								investmentCost: e["InvestmentCost"],
								type: e["Type"],
								connectionNet: e["IdHeatNetwork"],
								implementationDate: e["Tag"],
							})
					)
				);
			}
		}

		if (modifications?.table?.zac?.length) {
			setFieldValue(
				"zac",
				modifications.table.zac.map((e) =>
					ZacRow({
						name: e["Name"],
						surface: e["Surface"],
						environmentalClass: e["EnvironmentalClass"],
						heatingType: e["HeatingType"],
						city: e["City"],
						implementationDate: e["Tag"],
					})
				)
			);
		}

		if (buildings?.list) {
			setFieldValue(
				"networkConnection",
				buildings.list.map((element) =>
					NetworkConnectionRow({
						building: element["parcel_id"],
						consommation: element["annualEnergyConsumption"],
						connectionNet:
							modifications?.table?.networkConnection?.[
								element["parcel_id"]
							]?.IdHeatNetwork || "",
						implementationDate:
							modifications?.table?.networkConnection?.[
								element["parcel_id"]
							]?.Tag || new Date().getFullYear(),
					})
				)
			);

			setFieldValue(
				"renovation",
				buildings.list.map((element) =>
					RenovationRow({
						building: element["parcel_id"],
						energyClass: element["energy_label"],
						heatLostPercent: element["HeatEnvelopeLossK"] / 100,
						goal:
							modifications?.table?.renovation?.[
								element["parcel_id"]
							]?.RenovationObjective.Type || "averagegain",
						value:
							modifications?.table?.renovation?.[
								element["parcel_id"]
							]?.RenovationObjective.Value || "",
						implementationDate:
							modifications?.table?.renovation?.[
								element["parcel_id"]
							]?.Tag || new Date().getFullYear(),
					})
				)
			);
		}
	}, [setFieldValue, producers?.list, buildings?.list]);

	// Saving form states to redux state
	useEffect(() => {
		if (setScenarioFormValuesAction) {
			updateStoreTable(values);
		}
	}, [values]);

	const updateStoreTable = useCallback(
		debounce((values) => {
			setScenarioFormValuesAction(values);
		}, 2000),
		[]
	);

	useEffect(() => {
		if (values.newNetworkCreation?.length) {
			setFieldsDataFromApi({
				...fieldsDataFromApi,
				heatingType: StaticLists["heatingType"].concat(
					values.newNetworkCreation.map((net) => net.name)
				),
				connectionNet: values.newNetworkCreation.map((net) => net.name),
			});
		} else {
			setFieldsDataFromApi({
				...fieldsDataFromApi,
				heatingType: StaticLists["heatingType"],
				connectionNet: [],
			});
		}
	}, [values.newNetworkCreation]);

	if ([producers?.status, buildings?.status].includes(StatusEnum.LOADING)) {
		return <CircularProgress />;
	}

	return (
		<Box className={classes.tableContainer}>
			<Box className={classes.appBarScenario}>
				<Tabs
					className={classes.tabs}
					value={tabValue}
					onChange={handleChangeTab}
					variant="standard">
					{scenarioTabParams.map((scenarioTabParam) => (
						<Tab
							className={classes.tab}
							key={scenarioTabParam.key}
							id={scenarioTabParam.key}
							label={t(
								`components.scenarioparamstabletabs.tabs.${scenarioTabParam.key}`
							)}
						/>
					))}
				</Tabs>
			</Box>
			{scenarioTabPanel}
		</Box>
	);
};

ScenarioParamsTableTabs.defaultProps = {
	values: {},
	data: [],
};

ScenarioParamsTableTabs.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(React.memo(ScenarioParamsTableTabs));
