import getScenarioByIdFromTree from "./getScenarioByIdFromTree";

export default (scenario, tree) => {
	const list = [];

	const recursiveCallScenario = (scenario, list) => {
		if (scenario) {
			list.push(scenario);
		}
		if (!scenario || !scenario.parentId) return;
		recursiveCallScenario(
			getScenarioByIdFromTree(tree, scenario.parentId),
			list
		);
	};

	recursiveCallScenario(scenario, list);

	return list;
};
