export const GET_INITIAL_MAP_DATA = "GET_INITIAL_MAP_DATA";

export const SET_MAP_FILTER_TYPE_VISIBILITY = "SET_MAP_FILTER_TYPE_VISIBILITY";

export const INTIALIZE_MAP_INSTANCE = "INTIALIZE_MAP_INSTANCE";

export const SET_MAP_FILTER_TYPE = "SET_MAP_FILTER_TYPE";

export const GET_SELECTION_ZONE_FILTERS = "GET_SELECTION_ZONE_FILTERS";

export const GET_SELECTION_BUILDING_FILTERS = "GET_SELECTION_BUILDING_FILTERS";

export const GET_SELECTION_HEAT_ENERGY_TYPE_FILTERS =
	"GET_SELECTION_HEAT_ENERGY_TYPE_FILTERS";

export const GET_SELECTION_ENERGY_LABEL_FILTERS =
	"GET_SELECTION_ENERGY_LABEL_FILTERS";

export const SET_SELECTION_ZONE_FILTERS = "SET_SELECTION_ZONE_FILTERS";

export const SET_SELECTION_BUILDING_FILTERS = "SET_SELECTION_BUILDING_FILTERS";

export const SET_SELECTION_ENERGY_LABEL_FILTERS =
	"SET_SELECTION_ENERGY_LABEL_FILTERS";

export const SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS =
	"SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS";

export const REMOVE_HEAT_ENERGY_FROM_FILTERS_BY_ZONE_ID =
	"REMOVE_HEAT_ENERGY_FROM_FILTERS_BY_ZONE_ID";

export const REMOVE_ENERGY_LABEL_FROM_FILTERS_BY_HEAT_ENERGY_TYPE =
	"REMOVE_ENERGY_LABEL_FROM_FILTERS_BY_HEAT_ENERGY_TYPE";

export const SET_SELECTION_ZONE_CURRENT_FILTERS =
	"SET_SELECTION_ZONE_CURRENT_FILTERS";

export const SET_SELECTION_HEAT_ENERGY_TYPE_CURRENT_FILTERS =
	"SET_SELECTION_HEAT_ENERGY_TYPE_CURRENT_FILTERS";

export const SET_SELECTION_ENERGY_LABEL_CURRENT_FILTERS =
	"SET_SELECTION_ENERGY_LABEL_CURRENT_FILTERS";

export const RESET_SELECTION_CURRENT_FILTERS =
	"RESET_SELECTION_CURRENT_FILTERS";

export const LOAD_MAP_INSTANCE_DATA = "LOAD_MAP_INSTANCE_DATA";

export const GET_POLYGON_SELECTION_BUILDINGS =
	"GET_POLYGON_SELECTION_BUILDINGS";

export const SET_POLYGON_SELECTION_BUILDINGS =
	"SET_POLYGON_SELECTION_BUILDINGS";
