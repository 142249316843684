import MuiAlert from "@material-ui/lab/Alert";
import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import StatusEnum from "utils/enums/StatusEnum";
import useStyles from "./muiStyles";
import {withStyles} from "@material-ui/core";

const severityTypes = {
	[StatusEnum.ERROR]: "error",
	[StatusEnum.SUCCESS]: "success",
	[StatusEnum.IDLE]: "info",
};

function Alert(props) {
	return <MuiAlert variant="filled" {...props} />;
}

const NotificationModal = ({
	classes,
	info,
	isOpen,
	closeNotificationModalAction,
}) => (
	<Snackbar
		open={isOpen}
		autoHideDuration={6000}
		onClose={closeNotificationModalAction}>
		<Alert
			onClose={closeNotificationModalAction}
			severity={severityTypes[info.status]}>
			{info.message}
		</Alert>
	</Snackbar>
);

export default React.memo(withStyles(useStyles)(NotificationModal));
