const useStyles = (theme) => ({
	reportPageContainer: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-evenly",
		margin: "1rem 0",
	},
	reportPage: {
		padding: ".3rem",
	},
});

export default useStyles;
