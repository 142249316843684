const IndicatorsModel = require("./IndicatorsModel");
const SelectionsModel = require("./SelectionsModel");

const AlternativeScenarioCreateDTOModel = ({
	name,
	description,
	scenarioParent,
}) => ({
	name: name || "",
	type: "derive",
	description: description || "",
	parentId: (scenarioParent && scenarioParent.id) || "",
	indicators: IndicatorsModel(
		scenarioParent ? scenarioParent.indicators : {}
	),
	selections: SelectionsModel(
		scenarioParent ? scenarioParent.selections : {}
	),
	modifications: scenarioParent.modifications || [],
});

module.exports = AlternativeScenarioCreateDTOModel;
