import {
	GET_SELECTION_ZONE_FILTERS,
	SET_SELECTION_ZONE_FILTERS,
} from "../constants";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import axios from "axios";

function* selectionZoneFiltersData() {
	const {data} = yield axios.get(ApiRoutePathsEnum.GET_ZONE);
	yield put({type: SET_SELECTION_ZONE_FILTERS, list: data});
}

function* getSelectionZoneFiltersData() {
	yield takeEvery(GET_SELECTION_ZONE_FILTERS, selectionZoneFiltersData);
}

export default getSelectionZoneFiltersData;
