import {
	GET_POLYGON_SELECTION_BUILDINGS,
	INTIALIZE_MAP_INSTANCE,
} from "../constants";
import {all, put, select, take, takeEvery} from "redux-saga/effects";

import {channel} from "redux-saga";
import mapLoader from "utils/constants/mapLoader";
import {selectMapTypeFilters} from "utils/helpers/sagaSelectors";

const redirectChannel = channel();

function* mapDrawEventsSaga(action) {
	const {mapInstance, dispatchLoadMapInstanceData} = action;
	const mapTypeFilters = yield select(selectMapTypeFilters);

	// For Drawing Polygons Event
	yield all(
		["draw.create", "draw.delete", "draw.update"].map((event) => {
			return mapInstance.on(event, () =>
				redirectChannel.put({type: GET_POLYGON_SELECTION_BUILDINGS})
			);
		})
	);

	// For Loading Sources, Layers, Events (Popup ..)
	yield mapInstance.on(
		"load",
		mapLoader(
			mapInstance,
			{
				mapTypeFilters,
			},
			dispatchLoadMapInstanceData
		)
	);
}

export function* watchRedirectChannel() {
	while (true) {
		const action = yield take(redirectChannel);
		yield put(action);
	}
}

export function* initiateMapDrawEventsSaga() {
	yield takeEvery(INTIALIZE_MAP_INSTANCE, mapDrawEventsSaga);
}
