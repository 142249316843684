const recursiveParseTree = (data, id) => {
	for (let i = 0; i < (data.children || []).length; i++) {
		if (data.children[i].id === id) {
			return data.children[i];
		}
		const returnedValue = recursiveParseTree(data.children[i], id);
		if (returnedValue) {
			return returnedValue;
		}
	}
	return null;
};

export default (tree, id) => {
	for (let i = 0; i < tree.length; i++) {
		if (tree[i].id === id) {
			return tree[i];
		}
		const returnedValue = recursiveParseTree(tree[i], id);
		if (returnedValue) {
			return returnedValue;
		}
	}
	return null;
};
