import {
	initializeMapDataAction,
	loadMapInstanceDataAction,
	setMapFiltersTypeVisibilityAction,
} from "domain/map/actions";

import Map from "./Map";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
	mapFiltersTypeVisibility: state.mapReducer.mapTypeFilters.isHidden,
	mapStatus: state.mapReducer.mapData.status,
});

const mapDispatchToProps = {
	setMapFiltersTypeVisibilityAction,
	initializeMapDataAction,
	loadMapInstanceDataAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
