import {
	getSelectionBuildingFiltersAction,
	getSelectionEnergyLabelFiltersAction,
	getSelectionHeatEnergyTypeFiltersAction,
	getSelectionZoneFiltersAction,
	removeEnergyLabelFromFiltersByHeatEnergyTypeAction,
	removeHeatEnergyFromFiltersByZoneIdAction,
	setSelectionEnergyLabelCurrentFiltersAction,
	setSelectionHeatEnergyCurrentFiltersAction,
	setSelectionZoneCurrentFiltersAction,
} from "domain/map/actions";

import MapFiltersSelection from "./MapFiltersSelection";
import {connect} from "react-redux";
import {resetKpisAction} from "domain/kpi/actions";

const mapStateToProps = (state) => ({
	zoneFiltersList: state.mapReducer.mapSelectionFilters.zoneFilters.list,
	heatEnergyTypeFiltersList:
		state.mapReducer.mapSelectionFilters.heatEnergyTypeFilters.list,
	energyLabelFiltersList:
		state.mapReducer.mapSelectionFilters.energyLabelFilters.list,
	buildingFiltersList:
		state.mapReducer.mapSelectionFilters.buildingFilters.list,
	drawnBuildingNumber:
		state.mapReducer.mapSelectionFilters.drawnBuildingNumber,
	currentScenarioZones: state.parametersReducer.zones.list,
	currentScenarioHeatEnergies: state.parametersReducer.heatEnergy.list,
	currentScenarioEnergyLabels: state.parametersReducer.energyLabel.list,
	currentScenarioBuildings: state.parametersReducer.buildings,
});

const mapDispatchToProps = {
	getSelectionZoneFiltersAction,
	getSelectionHeatEnergyTypeFiltersAction,
	getSelectionEnergyLabelFiltersAction,
	getSelectionBuildingFiltersAction,
	setSelectionZoneCurrentFiltersAction,
	setSelectionHeatEnergyCurrentFiltersAction,
	setSelectionEnergyLabelCurrentFiltersAction,
	removeHeatEnergyFromFiltersByZoneIdAction,
	removeEnergyLabelFromFiltersByHeatEnergyTypeAction,
	resetKpisAction,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MapFiltersSelection);
