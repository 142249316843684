export default {
	ESTIMATION_CONSO_CHALEUR: "Estimation-conso-chaleur",
	CPS_RESIDENTIEL_TERTIAIRE_CLUSTER: "cps-residentiel-tertiaire-cluster",
	CPS_RESIDENTIEL_TERTIAIRE_CLUSTER_COUNT:
		"cps-residentiel-tertiaire-cluster-count",
	CPS_RESIDENTIEL_TERTIAIRE_UNCLUSTERED_POINTS:
		"cps-residentiel-tertiaire-unclustered-points",
	RESEAU_CHALEUR: "réseau chaleur",
	GEOTHERMIE_EXPLOITATION: "Geothermie exploitation",
	GEOTHERMIE_EXPLOITATION_BORDURE: "Geothermie exploitation bordure",
	ZAC: "ZAC",
	PARCELLES_FOND: "Parcelles fond",
	RESEAU_GAZ: "Réseau de gaz",
	CPS_DENSITE: "cps densité",
	CPS_BORDER: "cps-border",
	IRIS_BORDER: "iris border",
	BORNES_RECHARGE_ELECTRIQUE: "bornes_de_recharge_electrique",
	BORNES_RECHARGE_ELECTRIQUE_LABELS: "bornes_de_recharge_electrique_labels",
	TRONCON_AERIEN: "Troncon aérien",
	TRONCON_CABLE: "Troncon cable",
	POSTE_SOURCE: "Poste source",
	POSTE_ELECTRIQUE_CLUSTER: "Poste électrique cluster",
	POSTE_ELECTRIQUE_CLUSTER_COUNT: "Poste électrique cluster count",
	POSTE_ELECTRIQUE_UNCLUSTERED_POINTS: "Poste électrique unclustered points",
	WIND_POTENTIAL: "wind_potential",
	ROOFTOP_POTENTIAL: "rooftop_potential",
	DOGGER: "dogger",
	LUSITANIEN: "lusitanien",
	NEOCOMIEN: "Néocomien",
	ALBIEN: "Albien",
};
