import ScenarioParamsTableTabs from "./ScenarioParamsTableTabs";
import WithParamScenario from "utils/enums/WithParamScenario";
import {compose} from "recompose";
import {connect} from "react-redux";
import {setScenarioFormValuesAction} from "domain/scenario/actions";
import withParametersData from "common/hoc/parameters/withParametersData";
import withScenarioParametersForm from "common/hoc/scenario/withScenarioParametersForm";

export default compose(
	withScenarioParametersForm,
	connect(
		(state) => ({
			zonesList: state.mapReducer.mapSelectionFilters.zoneFilters.list,
		}),
		{
			setScenarioFormValuesAction,
		}
	),
	withParametersData([
		WithParamScenario.buildings,
		WithParamScenario.modifications,
	])
)(ScenarioParamsTableTabs);
