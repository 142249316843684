export const GET_SCENARIO_TREE = "GET_SCENARIO_TREE";

export const SET_SCENARIO_TREE = "SET_SCENARIO_TREE";

export const CREATE_SCENARIO = "CREATE_SCENARIO";

export const GET_CURRENT_SCENARIO = "GET_CURRENT_SCENARIO";

export const SET_CURRENT_SCENARIO = "SET_CURRENT_SCENARIO";

export const RESET_CURRENT_SCENARIO = "RESET_CURRENT_SCENARIO";

export const DELETE_SCENARIOS = "DELETE_SCENARIOS";

export const OPEN_CREATION_SCENARIO_MODAL = "OPEN_CREATION_SCENARIO_MODAL";

export const CLOSE_CREATION_SCENARIO_MODAL = "CLOSE_CREATION_SCENARIO_MODAL";

export const LAUNCH_SIMULATION = "LAUNCH_SIMULATION";

export const LAUNCH_SIMULATION_DONE = "LAUNCH_SIMULATION_DONE";

export const LAUNCH_SIMULATION_RESPONSE = "LAUNCH_SIMULATION_RESPONSE";

export const SET_SCENARIO_FORM_VALUES = "SET_SCENARIO_FORM_VALUES";

export const RESET_SCENARIO_FORM_VALUES = "RESET_SCENARIO_FORM_VALUES";

export const LAUNCH_BASE_SIMULATION = "LAUNCH_BASE_SIMULATION";
