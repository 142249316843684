import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	withStyles,
} from "@material-ui/core";

import FilterGroupEnum from "utils/enums/MapFiltersGroupEnum";
import React from "react";
import mapFiltersTypesIcons from "styles/helpers/mapFiltersTypesIcons";
import {mapFiltersTypesParams} from "utils/constants/mapFiltersParams";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const MapFiltersType = ({
	classes,
	setMapFilterTypeEventAction,
	currentFilters,
}) => {
	const {t} = useTranslation();

	return (
		<Grid className={classes.card} container>
			{mapFiltersTypesParams.map((filterTypeParam) => (
				<Grid key={filterTypeParam.category} item xs={3}>
					<FormControl component="fieldset">
						<FormLabel
							component="legend"
							classes={{
								root: classes.legend,
							}}>
							{t(
								`components.mapfilterstype.category.${filterTypeParam.category}`
							)}
						</FormLabel>
						<FormGroup>
							{filterTypeParam.content.map((value) => (
								<React.Fragment key={FilterGroupEnum[value]}>
									<FormControlLabel
										control={
											<Checkbox
												iconstyle={{fill: "white"}}
												inputstyle={{color: "white"}}
												style={{color: "white"}}
												checked={
													currentFilters[
														FilterGroupEnum[value]
													]
												}
												onChange={setMapFilterTypeEventAction(
													FilterGroupEnum[value]
												)}
												name={FilterGroupEnum[value]}
											/>
										}
										label={
											<span className={classes.labelType}>
												{t(
													`components.mapfilterstype.group.${value}`
												)}
												{mapFiltersTypesIcons[value]}
											</span>
										}
									/>
								</React.Fragment>
							))}
						</FormGroup>
					</FormControl>
				</Grid>
			))}
		</Grid>
	);
};

export default withStyles(useStyles)(MapFiltersType);
