const useStyles = (theme) => ({
	containerModal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modal: {
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	title: {
		fontSize: "24px",
		color: theme.palette.white,
	},
	msgContainer: {
		display: "grid",
		gridTemplateColumns: "40px auto",
		padding: "8px",
		marginTop: "20px",
	},
	statusContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	icon: {
		width: 35,
		height: 35,
	},
	msg: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
});

export default useStyles;
