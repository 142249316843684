export const NotificationTitles = {
	SCENARIO: "Scénario :",
	KPI: "Calcul de Kpi :",
	PARCELLE_SELECTION: "Sélection des parcelles :",
	PARAMETERS: "Paramètres :",
	POLYGONS: "Polygones :",
	POWERBI: "Power bi :",
};

export default {
	SCENARIO: {
		success: {
			GET_SCENARIO: "Scénario chargé avec succès",
			GET_SCENARIOS: "Arbre de scénario chargé avec succès",
			ADD_SCENARIO: "Ajout du scénario avec succès",
			DELETE_SCENARIOS:
				"Suppression du scénario et de ses enfants réussie",
			LAUNCH_SIMULATION: "La simulation a été lancée avec succès",
			LAUNCH_BASE_SIMULATION:
				"La simulation du scénario de base a été lancée avec succès",
		},
		error: {
			GET_SCENARIO: "Scénario n'a pas pu être chargé",
			GET_SCENARIOS: "Arbre de scénario n'a pas pu être chargé",
			ADD_SCENARIO: "Le scénario n'a pas pu être ajouté",
			DELETE_SCENARIOS:
				"Échec de la suppression du scénario et de ses enfants",
			LAUNCH_SIMULATION: "Le lancement de la simulation a échoué",
			LAUNCH_BASE_SIMULATION: "Le lancement de la simulation a échoué",
		},
	},
	KPI: {
		success: {
			GET_KPIS: "Kpis calculé avec succès",
		},
		error: {
			GET_KPIS: "Erreur lors du calcul des kpis",
		},
	},
	PARCELLE_SELECTION: {
		error: {
			GET_ID_PARCELLES:
				"Erreur lors de la récuparation des ids des parcelles",
		},
	},

	PARAMETERS: {
		error: {
			GET_PRODUCERS:
				"Erreur lors de l'appel des paramètres des producteurs",
			GET_BUILDINGS:
				"Erreur lors de l'appel des paramètres des bâtiments",
		},
	},

	POLYGONS: {
		error: {
			GET_POLYGON_SELECTION_BUILDINGS:
				"Erreur lors de la récupération des parcelles contenues dans le polygone",
		},
	},

	POWERBI: {
		error: {
			GET_EMBED_INFO:
				"Erreur lors de la récupération des infos de power bi",
		},
	},
};
