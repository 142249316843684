export const useButtonBaseStyles = () => ({
	button: {
		"& .MuiButton-label": {
			color: "white",
		},
		fontSize: "14px",
	},
	disabled: {
		opacity: "0.8",
		filter: "blur(1px)",
	},
});

export const useButtonNewScenarioStyles = () => ({
	button: {
		marginRight: "12px",
		background: "transparent",
		"& .MuiButton-label": {
			color: "#FFAD38",
		},
		"& .MuiButton-startIcon": {
			color: "#FFAD38",
			"& svg": {
				fontSize: "16px",
			},
		},
	},
});

export const useButtonRunSimulationStyles = () => ({
	button: {
		"& .MuiButton-endIcon": {
			color: "white",
			"& svg": {
				fontSize: "16px",
			},
		},
	},
});
