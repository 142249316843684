export default {
	totalGesAndCO2Emission: "totalGesAndCO2Emission",
	totalEnergyProduction: "totalEnergyProduction",
	totalEnergyConsumption: "totalEnergyConsumption",
	annualEnergyConsumption: "annualEnergyConsumption",
	energyProduction: "energyProduction",
	gesAndCO2Emission: "gesAndCO2Emission",
	avgCostPerFinalCustomer: "avgCostPerFinalCustomer",
	investCost: "investCost",
	operatingCost: "operatingCost",
	localEnergyPart: "localEnergyPart",
	frenchEneryPart: "frenchEneryPart",
};
