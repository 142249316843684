export const useStyles = (theme) => ({
	appBarScenario: {
		backgroundColor: "#455A64",
		borderRadius: "10px",
		display: "inline-block",
	},

	tabs: {
		borderRadius: "10px",
	},

	tab: {
		fontSize: "12px",
		fontWeight: "500",
		minWidth: "200px",
		"&.Mui-selected": {
			fontSize: "12px",
			backgroundColor: theme.palette.primary.main,
		},
	},
});
