import {
	GET_PRODUCERS_PARAM,
	GET_SCENARIO_PARAM,
	RESET_SCENARIO_PARAM,
} from "./constants";

import WithParamScenario from "utils/enums/WithParamScenario";

// REDUX ACTION
export const getProducersParametersAction = (scenarioId) => ({
	type: GET_PRODUCERS_PARAM,
	scenarioId,
});

// REDUX ACTION
export const getScenarioParametersAction = (scenarioId) => ({
	type: GET_SCENARIO_PARAM,
	scenarioId,
});

// REDUX ACTION
export const resetScenarioParametersAction = () => ({
	type: RESET_SCENARIO_PARAM,
});

export const ParametersActionsDict = {
	// [WithParamScenario.producers]: "getProducersParametersAction",
	[WithParamScenario.scenario]: "getScenarioParametersAction",
};
