import DashboardView from "views/DashboardView";
import DigitalTwinView from "views/DigitalTwinView";
import React from "react";
import RoutePathsEnum from "../enums/RoutePathsEnum";
import ScenarioManagerView from "views/ScenarioManagerView";

export default [
	{
		key: "digitaltwin",
		to: RoutePathsEnum.DIGITAL_TWIN,
		render: () => <DigitalTwinView />,
	},
	{
		key: "scenariocomparison",
		to: RoutePathsEnum.SCENARIO_COMPARISON,
		// TODO(Views): ScenarioComparisonView
		render: () => <div> ScenarioComparisonView </div>,
		// render: () => <ScenarioComparisonView />,
	},
	{
		key: "scenariomanager",
		to: RoutePathsEnum.SCENARIO_MANAGER,
		render: () => <ScenarioManagerView />,
	},
	{
		key: "dashboard",
		to: RoutePathsEnum.DASHBOARD,
		render: () => <DashboardView />,
	},
];
