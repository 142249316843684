const ScenarioModificationsToScenarioTable = (tableModifications) => {
	const result = {
		newNetworkCreation: [],
		productionSource: {},
		networkConnection: {},
		renovation: {},
		zac: [],
	};

	(tableModifications["CreateHeatNetwork"] || []).forEach((action) => {
		result["newNetworkCreation"].push({
			IdHeatNetwork: action["IdHeatNetwork"],
			CustomersFixedPrice: action["CustomersFixedPrice"],
			VariableMargin: action["VariableMargin"],
		});
	});

	(tableModifications["CreateProducer"] || []).forEach((action) => {
		result["productionSource"][action["IdProducer"]] = {
			IdHeatNetwork: action["IdHeatNetwork"],
		};
	});

	(tableModifications["UpdateProducer"] || []).forEach((action) => {
		result["productionSource"][action["IdProducer"]] = {
			...result["productionSource"][action["IdProducer"]],
			Priority: action["Priority"],
			IsControllable: action["IsControllable"],
			PowerMax: action["PowerMax"],
			GHGRate: action["GHGRate"],
			FixedCost: action["FixedCost"],
			VariableCost: action["VariableCost"],
			InvestmentCost: action["InvestmentCost"],
			Type: action["Type"],
			Tag: action["Tag"],
		};
	});

	(tableModifications["ExtendHeatNetwork"] || []).forEach((action) => {
		result["networkConnection"][action["IdParcel"]] = {
			IdHeatNetwork: action["IdHeatNetwork"],
			Tag: action["Tag"],
		};
	});

	(tableModifications["BuildingUpgrades"] || []).forEach((action) => {
		result["renovation"][action["IdParcel"]] = {
			RenovationObjective: {
				Type: action["RenovationObjective"].Type,
				Value: action["RenovationObjective"].Value,
			},
			Tag: action["Tag"],
		};
	});

	(tableModifications["CreateZac"] || []).forEach((action) => {
		result["zac"].push({
			IdZac: action["IdZac"],
			Name: action["Name"],
			Surface: action["Surface"],
			EnvironmentalClass: action["EnvironmentalClass"],
			HeatingType: action["HeatingType"],
			City: action["City"],
			Tag: action["Tag"],
		});
	});

	return result;
};

export default ScenarioModificationsToScenarioTable;
