import {
	getMapInstance,
	setMapFilterTypeAction,
	setMapFilterTypeEventActionClosure,
} from "domain/map/actions";

import MapFiltersType from "./MapFiltersType";
import {connect} from "react-redux";

export const mapStateToProps = (state) => ({
	currentFilters: state.mapReducer.mapTypeFilters.currentFilters,
});

export const mapDispatchToProps = {
	setMapFilterTypeAction,
};

export const mergeProps = (stateProps, dispatchProps) => ({
	...stateProps,
	...dispatchProps,
	setMapFilterTypeEventAction: setMapFilterTypeEventActionClosure(
		getMapInstance(),
		dispatchProps.setMapFilterTypeAction
	),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	mergeProps
)(MapFiltersType);
