export default {
	root: {
		background: "#FFAD38",
	},
	label: {
		color: "#000000",
	},
	contained: {
		boxShadow:
			"0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
	},
};
