import * as rax from "retry-axios";

import {Auth} from "@cosmotech/core";
import axios from "axios";
import jsonwebtoken from "jsonwebtoken";

// Retry requests that are failing
// eslint-disable-next-line no-unused-vars
const interceptorId = rax.attach();
axios.defaults.raxConfig = {
	retry: Infinity,
	retryDelay: 30000,
	backoffType: "static",
};

// Axios interceptor to get a refreshed token
axios.interceptors.request.use(
	async (request) => {
		// Get current auth access token
		let authAccessToken = localStorage.getItem("authAccessToken");

		// If token defined, check if expired
		if (authAccessToken !== null) {
			// Get token expiration time (seconds since Unix epoch)
			const exp = jsonwebtoken.decode(authAccessToken).exp;

			// Get current time (seconds since Unix epoch)
			const secondsSinceEpoch = Math.round(Date.now() / 1000);

			// If token is expired, try to get a new token with
			// MSAL acquireTokenSilent() function (called from isUserSignedIn())
			if (secondsSinceEpoch > exp) {
				await Auth.isUserSignedIn();
				// Get the new token
				authAccessToken = localStorage.getItem("authAccessToken");
			}
			// Send the token in all requests headers
			request.headers["csm-authorization"] = authAccessToken;
		}
		return request;
	},
	(error) => {
		return Promise.reject(error);
	}
);
