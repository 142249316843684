import {GET_EMBED_INFO, SET_EMBED_INFO} from "../constants";
import {delay, put, takeEvery} from "redux-saga/effects";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {setNotificationInfoAction} from "domain/notification/actions";

function* embedInfoData() {
	let tokenDelay;

	try {
		do {
			const {data} = yield axios.get(ApiRoutePathsEnum.GET_EMBED_INFO);

			yield put({
				type: SET_EMBED_INFO,
				accessToken: data.accessToken,
				embedUrl: data.embedUrl,
				expiry: data.expiry,
			});

			tokenDelay = Date.parse(data.expiry) - Date.now() - 120000;

			yield delay(tokenDelay);
		} while (tokenDelay);
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.POWERBI,
				notificationInfos.POWERBI.error.GET_EMBED_INFO
			)
		);
	}
}

function* getEmbedInfoData() {
	yield takeEvery(GET_EMBED_INFO, embedInfoData);
}

export default getEmbedInfoData;
