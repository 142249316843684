import {
	closeCreationScenarioModalAction,
	createScenarioAction,
} from "domain/scenario/actions";

import ScenarioCreationModal from "./ScenarioCreationModal";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
	currentScenario: state.scenarioReducer.currentScenario,
	scenariosTree: state.scenarioReducer.scenarioTree,
	isCreationModalOpen: state.scenarioReducer.isCreationModalOpen,
});

const mapDispatchToProps = {
	createScenarioAction,
	closeCreationScenarioModalAction,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ScenarioCreationModal);
