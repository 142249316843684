import {
	INTIALIZE_MAP_INSTANCE,
	LOAD_MAP_INSTANCE_DATA,
	REMOVE_ENERGY_LABEL_FROM_FILTERS_BY_HEAT_ENERGY_TYPE,
	REMOVE_HEAT_ENERGY_FROM_FILTERS_BY_ZONE_ID,
	RESET_SELECTION_CURRENT_FILTERS,
	SET_MAP_FILTER_TYPE,
	SET_MAP_FILTER_TYPE_VISIBILITY,
	SET_POLYGON_SELECTION_BUILDINGS,
	SET_SELECTION_BUILDING_FILTERS,
	SET_SELECTION_ENERGY_LABEL_CURRENT_FILTERS,
	SET_SELECTION_ENERGY_LABEL_FILTERS,
	SET_SELECTION_HEAT_ENERGY_TYPE_CURRENT_FILTERS,
	SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS,
	SET_SELECTION_ZONE_CURRENT_FILTERS,
	SET_SELECTION_ZONE_FILTERS,
} from "./constants";

import StatusEnum from "utils/enums/StatusEnum";
import {combineReducers} from "redux";
import {mapfiltersTypesDict} from "utils/constants/mapFiltersParams";

const mapReducer = combineReducers({
	mapData: (
		state = {
			status: StatusEnum.IDLE,
			mapInstance: null,
			drawInstance: null,
		},
		action
	) => {
		switch (action.type) {
			case INTIALIZE_MAP_INSTANCE:
				return {
					...state,
					status: StatusEnum.LOADING,
					mapInstance: "Initialized",
					drawInstance: "Initialized",
				};
			case LOAD_MAP_INSTANCE_DATA:
				return {
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	mapTypeFilters: (
		state = {
			isHidden: true,
			currentFilters: mapfiltersTypesDict,
		},
		action
	) => {
		switch (action.type) {
			case SET_MAP_FILTER_TYPE_VISIBILITY:
				return {
					...state,
					isHidden: action.isHidden,
				};
			case SET_MAP_FILTER_TYPE:
				return {
					...state,
					currentFilters: {
						...state.currentFilters,
						[action.currentFilter]: action.checked,
					},
				};
			default:
				return state;
		}
	},

	mapSelectionFilters: (
		state = {
			zoneFilters: {
				list: [],
				currentList: [],
			},
			heatEnergyTypeFilters: {
				list: [],
				currentList: [],
			},
			energyLabelFilters: {
				list: [],
				currentList: [],
			},
			buildingFilters: {
				list: [],
			},
			polygonFilters: {
				list: [],
				polygons: [],
			},
			drawnBuildingNumber: 0,
		},
		action
	) => {
		switch (action.type) {
			case RESET_SELECTION_CURRENT_FILTERS:
				return {
					...state,
					zoneFilters: {
						...state.zoneFilters,
						currentList: [],
					},
					heatEnergyTypeFilters: {
						list: [],
						currentList: [],
					},
					energyLabelFilters: {
						list: [],
						currentList: [],
					},
					buildingFilters: {
						list: [],
						polygons: [],
					},
					polygonFilters: {
						list: [],
						polygons: [],
					},
					drawnBuildingNumber: 0,
				};
			case SET_SELECTION_ZONE_FILTERS:
				return {
					...state,
					zoneFilters: {
						...state.zoneFilters,
						list: action.list,
					},
				};
			case SET_SELECTION_ZONE_CURRENT_FILTERS:
				return {
					...state,
					zoneFilters: {
						...state.zoneFilters,
						currentList: action.list,
					},
				};
			case SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS:
				return {
					...state,
					heatEnergyTypeFilters: {
						...state.heatEnergyTypeFilters,
						list: action.list,
					},
				};
			case SET_SELECTION_HEAT_ENERGY_TYPE_CURRENT_FILTERS:
				return {
					...state,
					heatEnergyTypeFilters: {
						...state.heatEnergyTypeFilters,
						currentList: action.list,
					},
				};
			case SET_SELECTION_ENERGY_LABEL_FILTERS:
				return {
					...state,
					energyLabelFilters: {
						...state.energyLabelFilters,
						list: action.list,
					},
				};
			case SET_SELECTION_ENERGY_LABEL_CURRENT_FILTERS:
				return {
					...state,
					energyLabelFilters: {
						...state.energyLabelFilters,
						currentList: action.list,
					},
				};
			case REMOVE_HEAT_ENERGY_FROM_FILTERS_BY_ZONE_ID:
				return {
					...state,
					heatEnergyTypeFilters: {
						list: (state.heatEnergyTypeFilters.list || []).filter(
							(heatEnergy) => heatEnergy.idzone !== action.idzone
						),
					},
					energyLabelFilters: {
						list: (state.energyLabelFilters.list || []).filter(
							(energyLabel) =>
								energyLabel.idzone !== action.idzone
						),
					},
				};
			case REMOVE_ENERGY_LABEL_FROM_FILTERS_BY_HEAT_ENERGY_TYPE:
				return {
					...state,
					energyLabelFilters: {
						list: (state.energyLabelFilters.list || []).filter(
							(energyLabel) =>
								energyLabel.heatenergytype !==
								action.heatenergytype
						),
					},
				};
			case SET_SELECTION_BUILDING_FILTERS:
				return {
					...state,
					buildingFilters: {
						list: action.list,
					},
					drawnBuildingNumber: action.list?.length || 0,
				};
			case SET_POLYGON_SELECTION_BUILDINGS:
				return {
					...state,
					polygonFilters: {
						list: action.buildingIds,
						polygons: action.polygons,
					},
					drawnBuildingNumber: action.buildingIds?.length || 0,
				};
			default:
				return state;
		}
	},
});

export default mapReducer;
