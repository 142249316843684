import {
	Box,
	Card,
	CardContent,
	CircularProgress,
	Typography,
	withStyles,
} from "@material-ui/core";

import React from "react";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import cn from "classnames";
import useStyles from "./muiStyles";

const KPICard = ({
	classes,
	onClick,
	value,
	title,
	isLoading,
	isSubTotalPositif,
	fixedDigits,
	type,
	subValue,
}) => (
	<Card onClick={onClick} className={classes.card}>
		<CardContent className={classes.content}>
			<Typography className={classes.title} color="textSecondary">
				{title}
			</Typography>
			<Box className={classes.numerics}>
				<Box className={classes.kpiResultsContainer}>
					{isLoading ? (
						<CircularProgress />
					) : (
						<>
							{!(value === undefined || value === null) && (
								<>
									<Typography
										component="p"
										className={classes.value}>
										{Number(value).toFixed(
											fixedDigits ? fixedDigits : 0
										)}
										{type === "percent" && "%"}
									</Typography>
								</>
							)}
							{!(subValue === undefined || subValue === null) && (
								<Typography
									component="p"
									className={cn({
										[classes.subValue]: true,
										[classes.isSubTotalPositifSubValue]: isSubTotalPositif,
										[classes.isNegatifSubValue]: !isSubTotalPositif,
									})}>
									{subValue > 0 ? (
										<TrendingUpIcon
											className={classes.subValueIcon}
										/>
									) : (
										<TrendingDownIcon
											className={classes.subValueIcon}
										/>
									)}
									{Number(subValue).toFixed(2)} %
								</Typography>
							)}
						</>
					)}
				</Box>
			</Box>
		</CardContent>
	</Card>
);

export default withStyles(useStyles)(KPICard);
