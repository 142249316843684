const IndicatorsModel = require("./IndicatorsModel");
const SelectionsModel = require("./SelectionsModel");
const OutputIndicatorsModel = require("./OutputIndicatorsModel");

const ScenarioDTOModel = ({
	id,
	name,
	type,
	indicators,
	outputIndicators,
	selections,
	modifications,
	producers,
	parentId,
	status,
	sagaId,
}) => ({
	id,
	name: name || "",
	type: type || "base",
	indicators: IndicatorsModel(indicators || {}),
	selections: SelectionsModel(selections || {}),
	outputIndicators: OutputIndicatorsModel(outputIndicators || {}),
	modifications: modifications || {},
	producers: producers || [],
	parentId: parentId || "",
	status: status || "",
	sagaId: sagaId || "",
});

module.exports = ScenarioDTOModel;
