import {
	ParametersActionsDict,
	getScenarioParametersAction,
} from "domain/parameters/actions";
import {compose, lifecycle} from "recompose";

import {connect} from "react-redux";

export default (paramIds = []) => (component) =>
	compose(
		connect(
			(state) => {
				const mapState = {};
				paramIds.forEach((paramId) => {
					mapState[paramId] = state.parametersReducer[paramId];
				});
				return {
					currentScenarioId:
						state.scenarioReducer.currentScenario?.scenario?.id,
					...mapState,
				};
			},
			{
				getScenarioParametersAction,
			}
		),

		lifecycle({
			componentDidMount() {
				paramIds.forEach((paramId) => {
					const dispatch = this.props[ParametersActionsDict[paramId]];
					if (dispatch && this.props.currentScenarioId) {
						dispatch(this.props.currentScenarioId);
					}
				});
			},

			UNSAFE_componentWillUpdate(nextProps) {
				paramIds.forEach((paramId) => {
					const dispatch = this.props[ParametersActionsDict[paramId]];
					if (
						nextProps.currentScenarioId !==
							this.props.currentScenarioId &&
						dispatch
					) {
						dispatch(nextProps.currentScenarioId);
					}
				});
			},
		})
	)(component);
