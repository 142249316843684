// ** Map Layers Data Management

export const filterByLayerDataFeatureMapAction = async (
	mapInstance,
	layerId,
	attr,
	featureData
) => {
	if (featureData.length) {
		// Show Only the specific featureData
		if (mapInstance) {
			await mapInstance.setFilter(layerId, [
				"match",
				["get", attr],
				featureData,
				true,
				false,
			]);
		}
	}
};
