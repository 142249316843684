export default (contentValues, title, titleColor, fieldColor) => {
	return `<div>
            ${
				title
					? `<div
                style='color: ${titleColor ? titleColor : "black"};
                font-size: 14px;
                font-weight: bold;'>
                    ${title} :
                </div>`
					: ""
			}
        ${Object.keys(contentValues)
			.map(
				(content) =>
					`<div class="popup-list-container">
                        <div class="popup-div popup-left">
                            <span style='font-weight: bold;${
								fieldColor ? "color: " + fieldColor : ""
							}'>${content} :
                            </span>
                        </div>
                        <div class="popup-div popup-right">${
							contentValues[content]
						}</div>
                        <div class="popup-dotted"></div>
                    </div>`
			)
			.join("")}
        </div>`;
};
