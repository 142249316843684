import {GET_PRODUCERS_PARAM, SET_PRODUCERS_PARAM} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {setNotificationInfoAction} from "domain/notification/actions";

function* producersParamData() {
	try {
		const {data} = yield axios.get(ApiRoutePathsEnum.GET_PRODUCERS);
		yield put({type: SET_PRODUCERS_PARAM, list: data});
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.PARAMETERS,
				notificationInfos.PARAMETERS.error.GET_PRODUCERS
			)
		);
	}
}

function* getProducersParamData() {
	yield takeEvery(GET_PRODUCERS_PARAM, producersParamData);
}

export default getProducersParamData;
