import "./styles.scss";

import {Add, Cancel, Delete} from "@material-ui/icons";
import {
	Box,
	Card,
	CircularProgress,
	Grid,
	Modal,
	withStyles,
} from "@material-ui/core";
import React, {useCallback, useMemo, useState} from "react";

import Backdrop from "@material-ui/core/Backdrop";
import ButtonBase from "common/presentational/components/Button/ButtonBase";
import Chart from "react-google-charts";
import Fade from "@material-ui/core/Fade";
import ScenarioBreadcrumb from "business/scenario/ScenarioBreadcrumb";
import StatusEnum from "utils/enums/StatusEnum";
import generateChartStyledData from "utils/helpers/generateChartStyledData";
import getScenarioAndChildrenIdList from "utils/helpers/getScenarioAndChildrenIdList";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const chartOptions = {
	allowHtml: true,
	nodeClass: "scenarioChartNode",
	selectedNodeClass: "scenarioChartNodeSelected",
};

const chartStyle = {
	minWidth: "100%",
	height: "100%",
	display: "flex",
	flexDirection: "column",
	padding: "8px 16px",
	overflowY: "auto",
};

const ScenarioManagerView = ({
	classes,
	scenariosTree,
	setCurrentScenarioAction,
	deleteScenariosAction,
	openCreationScenarioModalAction,
	getScenarioParametersAction,
	resetScenarioParametersAction,
	resetSelectionCurrentFiltersAction,
	resetKpisAction,
	resetScenarioFormValuesAction,
	getScenarioTreeAction,
}) => {
	const {t} = useTranslation();

	const [isModalDeleteScenarioOpen, setIsModalDeleteScenarioOpen] = useState(
		false
	);

	const [scenarioDeleteId, setScenarioDeleteId] = useState("");

	const handleCloseDeleteScenarioModal = useCallback(() => {
		setIsModalDeleteScenarioOpen(false);
	}, [setIsModalDeleteScenarioOpen]);

	const handleOpenDeleteScenarioModal = useCallback(() => {
		setIsModalDeleteScenarioOpen(true);
	}, [setIsModalDeleteScenarioOpen]);

	const handleDeletionScenario = useCallback(() => {
		setCurrentScenarioAction({scenario: null});
		getScenarioTreeAction();
		deleteScenariosAction(
			getScenarioAndChildrenIdList(scenariosTree.list, scenarioDeleteId)
		);
		setIsModalDeleteScenarioOpen(false);
	}, [
		scenarioDeleteId,
		setIsModalDeleteScenarioOpen,
		deleteScenariosAction,
		scenariosTree.list,
		setCurrentScenarioAction,
		getScenarioTreeAction,
	]);

	const generatedChartData = useMemo(
		() => [
			["Name", "Parent"],
			...generateChartStyledData(scenariosTree.list),
		],
		[scenariosTree.list]
	);

	const chartEvents = useMemo(
		() => [
			{
				eventName: "select",
				callback: ({chartWrapper}) => {
					const chart = chartWrapper.getChart();
					const selection = chart.getSelection();
					const dataTable = chartWrapper.getDataTable();
					if (selection.length === 1) {
						resetScenarioParametersAction();
						resetSelectionCurrentFiltersAction();
						resetKpisAction();
						resetScenarioFormValuesAction();
						setCurrentScenarioAction({
							scenario: {
								id: dataTable.getValue(selection[0].row, 0),
							},
						});
						getScenarioParametersAction(
							dataTable.getValue(selection[0].row, 0)
						);
					}
				},
			},
			{
				eventName: "ready",
				callback: ({chartWrapper}) => {
					const chart = chartWrapper.getChart();
					chart.container.addEventListener("click", (ev) => {
						const idToDelete = ev?.target?.id?.split("--")[1];
						if (idToDelete) {
							setScenarioDeleteId(idToDelete);
							handleOpenDeleteScenarioModal();
						}
					});
				},
			},
		],
		[
			setScenarioDeleteId,
			setCurrentScenarioAction,
			handleOpenDeleteScenarioModal,
			getScenarioParametersAction,
			resetScenarioParametersAction,
			resetSelectionCurrentFiltersAction,
			resetKpisAction,
			resetScenarioFormValuesAction,
		]
	);

	return (
		<>
			<Box className={classes.page} component="main">
				<Grid container className={classes.navBarContainer}>
					<Grid item xs={10}>
						<Box className={classes.breadcrumbScenarioContainer}>
							<ScenarioBreadcrumb />
						</Box>
					</Grid>
					<Grid
						item
						xs={2}
						className={classes.scenarioButtonsContainer}>
						<Box>
							<Fade in={true}>
								<ButtonBase
									color="secondary"
									style={{
										width: "100%",
									}}
									onClick={() => {
										openCreationScenarioModalAction();
									}}
									endIcon={<Add />}>
									{t(
										"views.scenariomanager.newscenariobutton.label"
									)}
								</ButtonBase>
							</Fade>
						</Box>
					</Grid>
				</Grid>
				<Box className={classes.scenarioManagerPanel}>
					<Card className={classes.mainCard}>
						<Grid container spacing={2} className={classes.grid}>
							<Grid
								item
								xs={12}
								className={classes.managerCircularProgress}>
								{scenariosTree.status ===
									StatusEnum.LOADING && (
									<CircularProgress size={80} disableShrink />
								)}
								{scenariosTree.status ===
									StatusEnum.SUCCESS && (
									<Chart
										loader={
											<CircularProgress
												size={80}
												disableShrink
											/>
										}
										className="scenarioChart"
										chartType="OrgChart"
										data={generatedChartData}
										options={chartOptions}
										style={chartStyle}
										chartEvents={chartEvents}
									/>
								)}
							</Grid>
						</Grid>
					</Card>
				</Box>
			</Box>
			<Modal
				className={classes.containerModalScenarioCreation}
				open={isModalDeleteScenarioOpen}
				onClose={handleCloseDeleteScenarioModal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 300,
				}}>
				<Fade in={isModalDeleteScenarioOpen}>
					<div className={classes.modalScenarioCreation}>
						<div className={classes.modalScenarioTitleContainer}>
							Voulez-vous vraiment supprimer le scénario ?
						</div>
						<div className={classes.deleteScenarioModalContainer}>
							<div
								className={
									classes.deleteScenarioButtonContainer
								}>
								<ButtonBase
									color="default"
									onClick={handleCloseDeleteScenarioModal}
									endIcon={<Cancel />}>
									Annuler
								</ButtonBase>
							</div>
							<div
								className={
									classes.deleteScenarioButtonContainer
								}>
								<ButtonBase
									color="secondary"
									onClick={handleDeletionScenario}
									endIcon={<Delete />}>
									Supprimer
								</ButtonBase>
							</div>
						</div>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

export default withStyles(useStyles)(React.memo(ScenarioManagerView));
