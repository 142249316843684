import {
	LAUNCH_BASE_SIMULATION,
	LAUNCH_SIMULATION_DONE,
	LAUNCH_SIMULATION_RESPONSE,
} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, select, takeEvery} from "redux-saga/effects";
import {
	selectCurrentScenario,
	selectKpiIndicators,
	selectModifications,
	selectSelectionFilters,
	selectSelectionZonesFilters,
} from "utils/helpers/sagaSelectors";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import ScenarioUpdateDTOModel from "utils/models/ScenarioUpdateDTOModel";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {setNotificationInfoAction} from "domain/notification/actions";
import {withPathParams} from "utils/helpers/apiHelpers";

function* scenarioData(action) {
	try {
		const currentScenario = yield select(selectCurrentScenario);

		const indicators = yield select(selectKpiIndicators);
		const selections = yield select(selectSelectionFilters);
		const modifications = yield select(selectModifications);
		const zonesList = yield select(selectSelectionZonesFilters);
		const cityCodeList = Object.assign(
			{},
			...zonesList
				.filter((elt) =>
					modifications?.CreateZac?.map((o) => o.City).includes(
						elt.name
					)
				)
				.map((item) => ({[item.name]: item.idzone}))
		);

		const {data} = yield axios.post(
			withPathParams({id: action.scenarioId})(
				ApiRoutePathsEnum.LAUNCH_SIMULATION
			),
			{
				scenario: {...currentScenario, modifications},
				cityCodeList: cityCodeList,
				buildingIds: selections.buildingIds,
			}
		);

		yield put({
			type: LAUNCH_SIMULATION_RESPONSE,
			sagaId: data.sagaId,
			jobName: data.jobName,
		});

		const scenarioUpdateDTO = ScenarioUpdateDTOModel({
			id: currentScenario.id,
			sagaId: data.sagaId,
			indicators,
			selections,
			modifications,
		});

		yield axios.patch(ApiRoutePathsEnum.UPDATE_SCENARIO, {
			scenarioUpdateDTO,
		});

		yield put(
			setNotificationInfoAction(
				StatusEnum.SUCCESS,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.success.LAUNCH_BASE_SIMULATION
			)
		);

		yield new Promise((r) => setTimeout(r, 180000));

		yield put({type: LAUNCH_SIMULATION_DONE});
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.error.LAUNCH_BASE_SIMULATION
			)
		);
	}
}

function* launchBaseSimulationData() {
	yield takeEvery(LAUNCH_BASE_SIMULATION, scenarioData);
}

export default launchBaseSimulationData;
