export const GET_KPIS_AFTER_SELECTION = "GET_KPIS_AFTER_SELECTION";

export const SET_KPIS_AFTER_SELECTION = "SET_KPIS_AFTER_SELECTION";

export const GET_OUTPUT_KPIS = "GET_OUTPUT_KPIS";

export const SET_KPIS_AFTER_SIMULATION = "SET_KPIS_AFTER_SIMULATION";

export const RESET_KPIS = "RESET_KPIS";

export const KPI_LOADING = "KPI_LOADING";

export const KPI_SUCCESS = "KPI_SUCCESS";

export const KPI_IDLE = "KPI_IDLE";
