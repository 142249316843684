import ButtonBase from "./ButtonBase";
import React from "react";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import {useButtonRunSimulationStyles} from "./muiStyles";
import {useTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";

let ButtonRunBaseSimulation = ({classes, ...props}) => {
	const {t} = useTranslation();

	return (
		<ButtonBase
			className={classes.button}
			endIcon={<SaveAltIcon />}
			{...props}>
			{t("components.buttonrunbasesimulation.label")}
		</ButtonBase>
	);
};

ButtonRunBaseSimulation = withStyles(useButtonRunSimulationStyles)(
	ButtonRunBaseSimulation
);

export default ButtonRunBaseSimulation;
