const ProducerModel = ({
	name,
	connectionNetwork,
	priority,
	maxPower,
	greenhouseGasEmissions,
	fixedCost,
	supplyCost,
	investmentCost,
	type,
	implementationDate,
	inputType,
	coords,
	state,
	date,
}) => ({
	name: name || "",
	connectionNetwork: connectionNetwork || "",
	priority: priority || 1,
	maxPower: maxPower || "",
	greenhouseGasEmissions: greenhouseGasEmissions || "",
	fixedCost: fixedCost || "",
	supplyCost: supplyCost || "",
	investmentCost: investmentCost || "",
	type: type || "",
	implementationDate: implementationDate || new Date().getFullYear(),
	inputType: inputType || "",
	// ? Need to know the source of this data
	coords: coords || {},
	// ? Need to know the source of this data
	state: state,
	date: date || Date.now(),
});

module.exports = ProducerModel;
