/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo} from "react";
import {
	displayDrawControls,
	getDrawInstance,
	getMapInstance,
} from "domain/map/actions";

import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import LayerIdEnum from "utils/enums/LayerIdEnum";
import ScenarioAutocomplete from "common/presentational/components/ScenarioAutocomplete";
import StatusEnum from "utils/enums/StatusEnum";
import {TextField} from "@material-ui/core";
import getParentListFromScenario from "utils/helpers/getParentListFromScenario";
import getScenarioByIdFromTree from "utils/helpers/getScenarioByIdFromTree";
import {useScenarioBreadcrumbStyles} from "./muiStyles";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {v4 as uuidv4} from "uuid";
import {withStyles} from "@material-ui/core/styles";

const ScenarioBreadcrumb = ({
	classes,
	scenarioTree,
	currentScenario,
	setCurrentScenarioAction,
	resetScenarioParametersAction,
	resetSelectionCurrentFiltersAction,
	getScenarioParametersAction,
	resetKpisAction,
	resetScenarioFormValuesAction,
}) => {
	const {t} = useTranslation();

	const [selectedScenarios, setSelectedScenarios] = useState([]);

	const globalReset = (resetMap) => {
		resetScenarioParametersAction();
		resetSelectionCurrentFiltersAction();
		resetKpisAction();
		if (resetMap) {
			if (getMapInstance()?.style) {
				getMapInstance().setFilter(LayerIdEnum.PARCELLES_FOND);
			}
			if (getDrawInstance()) {
				getDrawInstance().deleteAll();
				displayDrawControls();
			}
		}
		resetScenarioFormValuesAction();
	};

	useEffect(() => {
		const parents = getParentListFromScenario(
			getScenarioByIdFromTree(
				scenarioTree.list,
				currentScenario?.scenario?.id
			),
			scenarioTree.list
		).reverse();
		setSelectedScenarios(parents);
		if (parents.length) {
			setCurrentScenarioAction({scenario: parents[parents.length - 1]});
			getScenarioParametersAction(parents[parents.length - 1].id);
		}
	}, [scenarioTree.list, currentScenario?.scenario?.id]);

	const handleChangeSelectedScenario = useCallback(
		(_, newScenarioValue) => {
			if (!newScenarioValue) return;
			globalReset(false);
			const indexOfScenario = selectedScenarios.findIndex(
				(s) => s?.parentId === newScenarioValue?.parentId
			);
			if (indexOfScenario > 0) {
				const parentScenarios = selectedScenarios.slice(
					0,
					indexOfScenario
				);
				setSelectedScenarios([...parentScenarios, newScenarioValue]);
			} else {
				setSelectedScenarios([...selectedScenarios, newScenarioValue]);
			}
			setCurrentScenarioAction({scenario: newScenarioValue});
			getScenarioParametersAction(newScenarioValue.id);
		},
		[selectedScenarios, setSelectedScenarios]
	);

	const handleChangeBaseScenario = useCallback(
		(_, newScenarioValue) => {
			if (newScenarioValue) {
				globalReset(true);
				setSelectedScenarios([newScenarioValue]);
				setCurrentScenarioAction({scenario: newScenarioValue});
				getScenarioParametersAction(newScenarioValue.id);
			}
		},
		[setSelectedScenarios]
	);

	const handleBaseCancelButton = useCallback(() => {
		setSelectedScenarios([]);
		setCurrentScenarioAction({scenario: null});
		globalReset(true);
	}, [
		setSelectedScenarios,
		setCurrentScenarioAction,
		resetScenarioParametersAction,
		resetSelectionCurrentFiltersAction,
		resetKpisAction,
	]);

	const handleCancelButton = useCallback(
		(newScenarioValue) => {
			if (!newScenarioValue) return;
			globalReset(false);
			const indexOfScenario = selectedScenarios.findIndex(
				(s) => s?.id === newScenarioValue?.id
			);
			setSelectedScenarios(selectedScenarios.slice(0, indexOfScenario));
			setCurrentScenarioAction({
				scenario: selectedScenarios[indexOfScenario - 1],
			});
			getScenarioParametersAction(
				selectedScenarios[indexOfScenario - 1].id
			);
		},
		[
			selectedScenarios,
			setSelectedScenarios,
			setCurrentScenarioAction,
			getScenarioParametersAction,
		]
	);

	const renderedSelectedScenario = useMemo(() => {
		return (
			<>
				<ScenarioAutocomplete
					key={selectedScenarios[0]?.id || uuidv4()}
					value={selectedScenarios[0]}
					onChange={handleChangeBaseScenario}
					options={scenarioTree.list}
					renderInput={(params) => (
						<TextField
							{...params}
							label={t(
								"components.scenariobreadcrumb.basescenario.label"
							)}
							variant="outlined"
						/>
					)}
				/>
				<CloseIcon
					className={classes.cancelButton}
					onClick={handleBaseCancelButton}
				/>
				{selectedScenarios.map((alternativeScenario, index) => {
					if (!alternativeScenario?.children?.length) return null;
					return (
						<React.Fragment
							key={selectedScenarios[index + 1]?.id || uuidv4()}>
							<div className={classes.separatorArrow} />
							<ScenarioAutocomplete
								key={selectedScenarios[index + 1]?.id}
								value={selectedScenarios[index + 1]}
								onChange={handleChangeSelectedScenario}
								options={alternativeScenario.children}
								renderInput={(params) => (
									<TextField {...params} variant="outlined" />
								)}
							/>
							{selectedScenarios[index + 1] && (
								<CloseIcon
									className={classes.cancelButton}
									onClick={() =>
										handleCancelButton(
											selectedScenarios[index + 1]
										)
									}
								/>
							)}
						</React.Fragment>
					);
				})}
			</>
		);
	}, [
		selectedScenarios,
		scenarioTree.list,
		t("components.scenariobreadcrumb.basescenario.label"),
	]);

	return (
		<>
			{scenarioTree.status === StatusEnum.LOADING ||
			currentScenario.status === StatusEnum.LOADING ? (
				<CircularProgress />
			) : (
				renderedSelectedScenario
			)}
		</>
	);
};

ScenarioBreadcrumb.defaultProps = {
	classes: {},
	scenarioTree: {list: []},
	currentScenario: {scenario: null},
};

export default withStyles(useScenarioBreadcrumbStyles)(ScenarioBreadcrumb);
