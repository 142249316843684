const generateStyledChartHtml = (data) => {
	return `
		<div class="org-chart-container">
			<div class="delete-scenario-container">
				<svg class="delete-scenario-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
					<path id="deleted--${data.id}" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
				</svg>
			</div>
			<div class="org-chart-name">${data.name}</div>
			<div class="org-chart-description">${data.description}</div>
			<div class="org-chart-username">${data.userName}</div>
		</div>
	`;
};

const generateStyledChartData = (data) => ({
	v: data.id,
	f: generateStyledChartHtml(data),
});

const recursiveParseTree = (data, dataList) => {
	(data.children || []).forEach((children) => {
		dataList.push([generateStyledChartData(children), data.id]);
		recursiveParseTree(children, dataList);
	});
	return;
};

export default (tree) => {
	const dataList = [];
	tree.forEach((v) => {
		dataList.push([generateStyledChartData(v), ""]);
		recursiveParseTree(v, dataList);
	});
	return dataList;
};
