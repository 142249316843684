const useStyles = (theme) => ({
	bar: {
		background: theme.palette.background.secondary,
		color: "#FFFFFF",
		display: "flex",
		justifyContent: "space-between",
		boxShadow: "0 2px 2px 0px rgba(0,0,0,0.6)",
	},
	tabs: {
		width: "100%",
		maxWidth: "900px",
	},
	tab: {
		textTransform: "none",
		minWidth: 0,
		fontSize: "14px",
		fontWeight: "500",
		letterSpacing: "0",
		lineHeight: "15px",
		textAlign: "center",
		flexGrow: 1,
		opacity: 1,
		color: theme.palette.text.grey,
		"&.Mui-selected": {
			fontWeight: "bold",
			color: theme.palette.primary.contrastText,
		},
	},
	barDiv: {
		display: "flex",
		justifyContent: "space-between",
	},
});

export default useStyles;
