import ButtonBase from "./ButtonBase";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import React from "react";
import {useButtonRunSimulationStyles} from "./muiStyles";
import {useTranslation} from "react-i18next";
import {withStyles} from "@material-ui/core/styles";

let ButtonRunSimulation = ({classes, ...props}) => {
	const {t} = useTranslation();

	return (
		<ButtonBase
			className={classes.button}
			endIcon={<PlayCircleOutlineIcon />}
			{...props}>
			{t("components.buttonrunsimulation.label")}
		</ButtonBase>
	);
};

ButtonRunSimulation = withStyles(useButtonRunSimulationStyles)(
	ButtonRunSimulation
);

export default ButtonRunSimulation;
