import "./Map.scss";

import React, {useEffect, useRef} from "react";
import {setDrawInstance, setMapInstance} from "domain/map/actions";

import ButtonBase from "common/presentational/components/Button/ButtonBase";
import {CircularProgress} from "@material-ui/core";
import StatusEnum from "utils/enums/StatusEnum";
import cn from "classnames";
import {useTranslation} from "react-i18next";

const handleFiltersVisibility = (dispatch, isHidden) => () =>
	dispatch(!isHidden);

const Map = ({
	initializeMapDataAction,
	mapFiltersTypeVisibility,
	setMapFiltersTypeVisibilityAction,
	loadMapInstanceDataAction,
	mapStatus,
}) => {
	const {t} = useTranslation();

	const mapContainerRef = useRef(null);

	// Initialize map when component mounts
	useEffect(() => {
		const {mapInstance} = initializeMapDataAction(
			mapContainerRef,
			loadMapInstanceDataAction
		);

		// Clean up on unmount
		return () => {
			mapInstance.remove();
			setMapInstance(null);
			setDrawInstance(null);
		};
	}, [initializeMapDataAction, loadMapInstanceDataAction]);

	return (
		<>
			{mapStatus === StatusEnum.LOADING && (
				<div className="loading-map-container">
					<CircularProgress
						style={{
							width: "80px",
							height: "80px",
						}}
						disableShrink
					/>
				</div>
			)}
			<div
				className={cn({
					"map-container": true,
					"hide-map": mapStatus !== StatusEnum.SUCCESS,
				})}
				ref={mapContainerRef}>
				<div className="map-filter-button-container">
					<ButtonBase
						onClick={handleFiltersVisibility(
							setMapFiltersTypeVisibilityAction,
							mapFiltersTypeVisibility
						)}>
						{t("components.map.filters.label")}
					</ButtonBase>
				</div>
			</div>
		</>
	);
};

export default Map;
