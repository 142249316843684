import FilterGroupEnum from "utils/enums/MapFiltersGroupEnum";
import LayerIdEnum from "utils/enums/LayerIdEnum";

export const mapfiltersTypesDict = {
	[FilterGroupEnum.IRIS_BORDER]: false,
	[FilterGroupEnum.CPS_BORDER]: true,
	[FilterGroupEnum.CPS_DENSITE]: false,
	[FilterGroupEnum.PARCELLES_FOND]: true,
	[FilterGroupEnum.ZAC]: false,
	[FilterGroupEnum.BORNES_RECHARGE_ELECTRIQUE]: false,
	[FilterGroupEnum.RESEAUX_GAZ]: false,
	[FilterGroupEnum.RESEAUX_CHALEUR]: false,
	[FilterGroupEnum.RESEAUX_ELECTRIQUE_CABLE]: false,
	[FilterGroupEnum.RESEAUX_ELECTRIQUE_POSTE]: false,
	[FilterGroupEnum.GEOTHERMIE_EXPLOITATION]: false,
	[FilterGroupEnum.CONSO_CHALEUR_VOIRIE]: false,
	[FilterGroupEnum.CONSO_CHALEUR_BATI]: false,
	[FilterGroupEnum.DOGGER]: false,
	[FilterGroupEnum.LUSITANIEN]: false,
	[FilterGroupEnum.NEOCOMIEN]: false,
	[FilterGroupEnum.ALBIEN]: false,
	[FilterGroupEnum.WIND_POTENTIAL]: false,
	[FilterGroupEnum.ROOFTOP_POTENTIAL]: false,
};

export const mapFiltersTypeGroups = {
	[FilterGroupEnum.IRIS_BORDER]: [LayerIdEnum.IRIS_BORDER],
	[FilterGroupEnum.CPS_BORDER]: [LayerIdEnum.CPS_BORDER],
	[FilterGroupEnum.CPS_DENSITE]: [LayerIdEnum.CPS_DENSITE],
	[FilterGroupEnum.PARCELLES_FOND]: [LayerIdEnum.PARCELLES_FOND],
	[FilterGroupEnum.ZAC]: [LayerIdEnum.ZAC],
	[FilterGroupEnum.BORNES_RECHARGE_ELECTRIQUE]: [
		LayerIdEnum.BORNES_RECHARGE_ELECTRIQUE,
		LayerIdEnum.BORNES_RECHARGE_ELECTRIQUE_LABELS,
	],
	[FilterGroupEnum.RESEAUX_GAZ]: [LayerIdEnum.RESEAU_GAZ],
	[FilterGroupEnum.RESEAUX_CHALEUR]: [LayerIdEnum.RESEAU_CHALEUR],
	[FilterGroupEnum.RESEAUX_ELECTRIQUE_CABLE]: [
		LayerIdEnum.TRONCON_CABLE,
		LayerIdEnum.TRONCON_AERIEN,
	],
	[FilterGroupEnum.RESEAUX_ELECTRIQUE_POSTE]: [
		LayerIdEnum.POSTE_SOURCE,
		LayerIdEnum.POSTE_ELECTRIQUE_CLUSTER,
		LayerIdEnum.POSTE_ELECTRIQUE_CLUSTER_COUNT,
		LayerIdEnum.POSTE_ELECTRIQUE_UNCLUSTERED_POINTS,
	],
	[FilterGroupEnum.GEOTHERMIE_EXPLOITATION]: [
		LayerIdEnum.GEOTHERMIE_EXPLOITATION,
		LayerIdEnum.GEOTHERMIE_EXPLOITATION_BORDURE,
	],
	[FilterGroupEnum.CONSO_CHALEUR_VOIRIE]: [
		LayerIdEnum.ESTIMATION_CONSO_CHALEUR,
	],
	[FilterGroupEnum.CONSO_CHALEUR_BATI]: [
		LayerIdEnum.CPS_RESIDENTIEL_TERTIAIRE_CLUSTER,
		LayerIdEnum.CPS_RESIDENTIEL_TERTIAIRE_CLUSTER_COUNT,
		LayerIdEnum.CPS_RESIDENTIEL_TERTIAIRE_UNCLUSTERED_POINTS,
	],
	[FilterGroupEnum.DOGGER]: [LayerIdEnum.DOGGER],
	[FilterGroupEnum.LUSITANIEN]: [LayerIdEnum.LUSITANIEN],
	[FilterGroupEnum.NEOCOMIEN]: [LayerIdEnum.NEOCOMIEN],
	[FilterGroupEnum.ALBIEN]: [LayerIdEnum.ALBIEN],
	[FilterGroupEnum.WIND_POTENTIAL]: [LayerIdEnum.WIND_POTENTIAL],
	[FilterGroupEnum.ROOFTOP_POTENTIAL]: [LayerIdEnum.ROOFTOP_POTENTIAL],
};

export const mapFiltersTypesParams = [
	{
		category: "TERRITORY",
		content: [
			"IRIS_BORDER",
			"CPS_BORDER",
			"CPS_DENSITE",
			"PARCELLES_FOND",
			"ZAC",
			"BORNES_RECHARGE_ELECTRIQUE",
		],
	},
	{
		category: "NETWORKS",
		content: [
			"RESEAUX_GAZ",
			"RESEAUX_CHALEUR",
			"RESEAUX_ELECTRIQUE_CABLE",
			"RESEAUX_ELECTRIQUE_POSTE",
			"GEOTHERMIE_EXPLOITATION",
		],
	},
	{
		category: "CONSUMPTION",
		content: ["CONSO_CHALEUR_VOIRIE", "CONSO_CHALEUR_BATI"],
	},
	{
		category: "POTENTIAL",
		content: [
			"DOGGER",
			"LUSITANIEN",
			"NEOCOMIEN",
			"ALBIEN",
			"WIND_POTENTIAL",
			"ROOFTOP_POTENTIAL",
		],
	},
];
