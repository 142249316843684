import addEvents from "./addEvents";
import addLayers from "./addLayers";
import addSources from "./addSources";
import {mapFiltersTypeGroups} from "../mapFiltersParams";

export default (map, initialMapValues, dispatchLoadMapInstanceData) => () => {
	const {mapTypeFilters} = initialMapValues;

	addSources(map);

	addLayers(map);

	addEvents(map);

	Object.keys(mapTypeFilters).forEach((filtersType) => {
		let isVisible = mapTypeFilters[filtersType];
		for (const filterType of mapFiltersTypeGroups[filtersType]) {
			map.setLayoutProperty(
				filterType,
				"visibility",
				isVisible ? "visible" : "none"
			);
		}
	});

	dispatchLoadMapInstanceData();
};
