const recursiveParseTree = (data, dataList) => {
	(data.children || []).forEach((children) => {
		dataList.push(children);
		recursiveParseTree(children, dataList);
	});
	return;
};

export default (tree) => {
	const dataList = [];
	tree.forEach((v) => {
		dataList.push(v);
		recursiveParseTree(v, dataList);
	});
	return dataList;
};
