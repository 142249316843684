import "./styles.scss";

import {AddBox, Delete, Edit} from "@material-ui/icons";
import {Field, FieldArray} from "formik";
import {InputBase, NativeSelect} from "@material-ui/core";
import React, {forwardRef} from "react";
import {SOURCE, TYPE} from "utils/constants/scenarioTabParams";

import {FormSectionMapToModel} from "domain/scenario/models/scenarioParamsModels";
import LayerIdEnum from "utils/enums/LayerIdEnum";
import cn from "classnames";
import generateInfoPopup from "utils/helpers/generateInfoPopup";
import {getMapInstance} from "domain/map/actions";
import {grey} from "styles/typeFiltersColors.scss";
import i18n from "../../../../i18next.config";
import mapboxgl from "mapbox-gl";
import {withTranslation} from "react-i18next";

const FieldsComponentDict = {
	string: forwardRef((props, ref) => (
		<InputBase
			onClick={
				(props?.name).includes("building")
					? (e) => {
							const selectedFeature = getMapInstance()
								.queryRenderedFeatures({
									layers: [LayerIdEnum.PARCELLES_FOND],
								})
								?.filter(
									(feature) =>
										feature.properties?.idpar ===
										(e?.currentTarget?.children?.[0]?.value).replaceAll(
											"_",
											" "
										)
								)?.[0];
							const coordinates =
								selectedFeature?.geometry
									?.coordinates?.[0]?.[0] ||
								selectedFeature?.geometry?.coordinates?.[0];
							if (coordinates) {
								getMapInstance().fire("closeAllPopups");
								getMapInstance().jumpTo({
									center: coordinates,
									zoom: 14,
								});
								const popup = new mapboxgl.Popup({
									maxWidth: "26rem",
									anchor: "right",
								})
									.setLngLat(coordinates)
									.setHTML(
										generateInfoPopup(
											{
												[i18n.t(
													"components.scenarioparamtable.popup.id"
												)]: selectedFeature.properties
													.idpar,
												[i18n.t(
													"components.scenarioparamtable.popup.surface"
												)]: selectedFeature.properties
													.surface,
												[i18n.t(
													"components.scenarioparamtable.popup.constructionyear"
												)]: selectedFeature.properties
													.constructionyear,
												[i18n.t(
													"components.scenarioparamtable.popup.heatenergytype"
												)]: i18n.t(
													`components.scenarioparamtable.popup.heatenergytypeenum.${selectedFeature.properties.heatenergytype}`
												),
												[i18n.t(
													"components.scenarioparamtable.popup.energyclass"
												)]: selectedFeature.properties
													.energycat,
												[i18n.t(
													"components.scenarioparamtable.popup.livingquarters"
												)]: selectedFeature.properties
													.napart,
												[i18n.t(
													"components.scenarioparamtable.popup.floors"
												)]: selectedFeature.properties
													.nlevel,
												[i18n.t(
													"components.scenarioparamtable.popup.hlm"
												)]: selectedFeature.properties
													.nloghlm,
												[i18n.t(
													"components.scenarioparamtable.popup.owner"
												)]: selectedFeature.properties
													.noccprop,
												[i18n.t(
													"components.scenarioparamtable.popup.tenant"
												)]: selectedFeature.properties
													.nocclocat,
											},
											i18n.t(
												"components.scenarioparamtable.popup.title"
											),
											grey
										)
									)
									.addTo(getMapInstance());
								// Add a custom event listener to the map
								getMapInstance().on("closeAllPopups", () => {
									popup.remove();
								});
							}
					  }
					: undefined
			}
			inputProps={{style: {cursor: "inherit"}}}
			style={{width: "100%", cursor: "inherit"}}
			{...props}
			ref={ref}
		/>
	)),
	numeric: forwardRef((props, ref) => (
		<InputBase style={{width: "100%"}} type="number" {...props} ref={ref} />
	)),
	list: forwardRef((props, ref) => (
		<NativeSelect
			style={{width: "100%"}}
			{...props}
			input={<InputBase />}
			ref={ref}>
			{(props.options || []).map((value) => (
				<option key={value} value={value}>
					{props.source === "static"
						? i18n.t(
								`components.scenarioparamtable.staticlists.${props.fieldid}.${value}`
						  )
						: value}
				</option>
			))}
		</NativeSelect>
	)),
	date: forwardRef((props, ref) => (
		<InputBase
			style={{width: "100%"}}
			type="number"
			placeholder="YYYY"
			min="{new Date().getFullYear()}"
			max="3000"
			{...props}
			ref={ref}></InputBase>
	)),
};

class ScenarioParamTable extends React.Component {
	shouldComponentUpdate(nextProps) {
		const {fields, rowsNumber, formSection} = this.props;
		return (
			fields.length !== nextProps.fields.length ||
			rowsNumber !== nextProps.rowsNumber ||
			formSection !== nextProps.formSection
		);
	}

	render() {
		const {
			t,
			formSection,
			fields,
			rowsNumber,
			fieldsDataFromApi,
		} = this.props;

		return (
			<div className="scenario-param-table-container">
				<FieldArray
					name={formSection}
					render={(arrayHelpers) => (
						<>
							<table className="scenario-param-table">
								<thead>
									<tr>
										{(fields || []).map((field) => (
											<th key={field.id}>
												{t(
													`components.scenariotabparams.${formSection}.${field.id}`
												)}{" "}
												{field.units
													? ` (${t(
															"components.scenariotabparams.unity"
													  )} ${field.units})`
													: ""}
											</th>
										))}
										<th />
									</tr>
								</thead>
								<tbody>
									{new Array(rowsNumber)
										.fill(0)
										.map((_, index) => (
											<tr
												key={`${formSection}[${index}]`}>
												{(fields || []).map((field) => (
													<td
														key={`${field.id}-${index}`}
														className={cn({
															"hover-building-td":
																field.id ===
																"building",
														})}>
														<Field
															endAdornment={
																field.source ===
																	SOURCE.intern &&
																field.type !==
																	TYPE.list ? (
																	<Edit />
																) : null
															}
															disabled={
																field.isFixed
															}
															options={
																field.source ===
																SOURCE.static
																	? field.options
																	: fieldsDataFromApi?.[
																			field
																				.id
																	  ]?.concat(
																			field.defaultValue ===
																				null
																				? []
																				: [
																						field.defaultValue,
																				  ]
																	  )
															}
															as={
																FieldsComponentDict[
																	field.type
																]
															}
															name={`${formSection}[${index}].${field.id}`}
															fieldid={field.id}
															source={
																field.source
															}
														/>
													</td>
												))}
												<td key={`delete-${index}`}>
													<button
														type="button"
														onClick={() => {
															arrayHelpers.remove(
																index
															);
														}}>
														<Delete />
													</button>
												</td>
											</tr>
										))}
								</tbody>
							</table>
							<div>
								<button
									type="button"
									onClick={() =>
										arrayHelpers.push(
											FormSectionMapToModel[formSection]()
										)
									}>
									<AddBox />
								</button>
							</div>
						</>
					)}
				/>
			</div>
		);
	}
}

export default withTranslation()(ScenarioParamTable);
