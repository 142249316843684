import {Box, withStyles} from "@material-ui/core";

import NotificationModalContainer from "../NotificationModal/NotificationModalContainer";
import React from "react";
import {Route} from "react-router-dom";
import ScenarioCreationModal from "business/scenario/ScenarioCreationModal";
import ScenarioSimulationWaitingModal from "business/scenario/ScenarioSimulationWaitingModal";
import Tabs from "../TabsLayout";
import useStyles from "./muiStyles";

const Layout = ({children, classes}) => (
	<Route
		path="/"
		render={({location}) => (
			<>
				<NotificationModalContainer />
				<ScenarioCreationModal />
				<ScenarioSimulationWaitingModal />
				<Tabs pathname={location.pathname} />
				<Box className={classes.content}>{children}</Box>
			</>
		)}
	/>
);

export default withStyles(useStyles)(React.memo(Layout));
