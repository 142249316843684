import {Redirect, HashRouter as Router, Switch} from "react-router-dom";

import Layout from "./common/presentational/components/Layout";
import PrivateRoute from "./common/presentational/components/PrivateRoute";
import PublicRoute from "./common/presentational/components/PublicRoute";
import React from "react";
import RoutePathsEnum from "./utils/enums/RoutePathsEnum";
import SignInView from "./views/SignInView";
import tabParams from "./utils/constants/tabParams";

const Routes = ({
	isAuthenticated,
	isAuthorized,
	handleSignIn,
	handleSignOut,
}) => {
	return (
		<Router>
			<Switch>
				<Redirect exact from="/" to="/digitaltwin" />
				<PublicRoute
					exact
					path={RoutePathsEnum.SIGN_IN}
					authenticated={isAuthenticated}
					authorized={isAuthorized}
					component={() => <SignInView handleSignIn={handleSignIn} />}
					redirectTo={RoutePathsEnum.DIGITAL_TWIN}
					unauthorizedPath={RoutePathsEnum.UNAUTHORIZED}
				/>
				<PublicRoute
					exact
					path={RoutePathsEnum.UNAUTHORIZED}
					authenticated={isAuthenticated}
					authorized={isAuthorized}
					// TODO(Views): UnauthorizedView View
					// component={UnauthorizedView}
					component={() => <div>UnauthorizedView</div>}
					redirectTo={RoutePathsEnum.DIGITAL_TWIN}
					unauthorizedPath={
						RoutePathsEnum.UNAUTHORIZED
					}></PublicRoute>
				<Layout handleSignOut={handleSignOut}>
					{tabParams.map((tab) => (
						<PrivateRoute
							key={tab.key}
							path={tab.to}
							render={tab.render}
							authenticated={isAuthenticated}
							authorized={isAuthorized}
							noAuthRedirect={RoutePathsEnum.SIGN_IN}
							noPermRedirect={RoutePathsEnum.UNAUTHORIZED}
						/>
					))}
				</Layout>
			</Switch>
		</Router>
	);
};

export default Routes;
