import {
	KPI_IDLE,
	KPI_LOADING,
	KPI_SUCCESS,
	RESET_KPIS,
	SET_KPIS_AFTER_SELECTION,
	SET_KPIS_AFTER_SIMULATION,
} from "./constants";
import {
	generateKpiAfterSelectionReducer,
	generateKpiAfterSimulationReducer,
	initialStateKpiAfterSelection,
	initialStateKpiAfterSimulation,
} from "./helper";

import KPIKeysEnum from "utils/enums/KPIKeysEnum";
import StatusEnum from "utils/enums/StatusEnum";
import {combineReducers} from "redux";
import {isNumber} from "lodash-es";

const kpiReducer = combineReducers({
	status: (state = StatusEnum.IDLE, action) => {
		switch (action.type) {
			case KPI_LOADING:
				return StatusEnum.LOADING;
			case KPI_SUCCESS:
				return StatusEnum.SUCCESS;
			case KPI_IDLE:
				return StatusEnum.IDLE;
			default:
				return state;
		}
	},
	isActive: (state = false, action) => {
		switch (action.type) {
			case RESET_KPIS:
				return false;
			case SET_KPIS_AFTER_SELECTION:
				return (
					isNumber(action?.annualEnergyConsumptionKpi?.value) ||
					isNumber(action?.energyProductionKpi?.value) ||
					isNumber(action?.gesAndCO2EmissionKpi?.value)
				);
			default:
				return state;
		}
	},

	isAfterSimulation: (state = false, action) => {
		switch (action.type) {
			case RESET_KPIS:
				return false;
			case SET_KPIS_AFTER_SIMULATION:
				return (
					isNumber(action?.totalEnergyConsumptionKpi?.value) ||
					isNumber(action?.totalEnergyProductionKpi?.value) ||
					isNumber(action?.totalGesAndCO2EmissionKpi?.value) ||
					isNumber(action?.avgCostPerFinalCustomerKpi?.value) ||
					isNumber(action?.investCostKpi?.value) ||
					isNumber(action?.operatingCostKpi?.value) ||
					isNumber(action?.localEnergyPartKpi?.value) ||
					isNumber(action?.frenchEneryPartKpi?.value)
				);
			default:
				return state;
		}
	},

	totalEnergyConsumptionKpi: (
		state = initialStateKpiAfterSimulation(
			KPIKeysEnum.totalEnergyConsumption
		),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"totalEnergyConsumptionKpi"
		);
	},

	totalEnergyProductionKpi: (
		state = initialStateKpiAfterSimulation(
			KPIKeysEnum.totalEnergyProduction
		),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"totalEnergyProductionKpi"
		);
	},

	totalGesAndCO2EmissionKpi: (
		state = initialStateKpiAfterSimulation(
			KPIKeysEnum.totalGesAndCO2Emission
		),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"totalGesAndCO2EmissionKpi"
		);
	},

	avgCostPerFinalCustomerKpi: (
		state = initialStateKpiAfterSimulation(
			KPIKeysEnum.avgCostPerFinalCustomer
		),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"avgCostPerFinalCustomerKpi"
		);
	},

	investCostKpi: (
		state = initialStateKpiAfterSimulation(KPIKeysEnum.investCost),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"investCostKpi"
		);
	},

	operatingCostKpi: (
		state = initialStateKpiAfterSimulation(KPIKeysEnum.operatingCost),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"operatingCostKpi"
		);
	},

	localEnergyPartKpi: (
		state = initialStateKpiAfterSimulation(KPIKeysEnum.localEnergyPart),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"localEnergyPartKpi"
		);
	},

	frenchEneryPartKpi: (
		state = initialStateKpiAfterSimulation(KPIKeysEnum.frenchEneryPart),
		action
	) => {
		return generateKpiAfterSimulationReducer(
			state,
			action,
			"frenchEneryPartKpi"
		);
	},

	commonKpi: (state = initialStateKpiAfterSelection(), action) => {
		return generateKpiAfterSelectionReducer(state, action, "commonKpi");
	},

	annualEnergyConsumptionKpi: (
		state = initialStateKpiAfterSelection(
			KPIKeysEnum.annualEnergyConsumption
		),
		action
	) => {
		return generateKpiAfterSelectionReducer(
			state,
			action,
			"annualEnergyConsumptionKpi"
		);
	},

	energyProductionKpi: (
		state = initialStateKpiAfterSelection(KPIKeysEnum.energyProduction),
		action
	) => {
		return generateKpiAfterSelectionReducer(
			state,
			action,
			"energyProductionKpi"
		);
	},

	gesAndCO2EmissionKpi: (
		state = initialStateKpiAfterSelection(KPIKeysEnum.gesAndCO2Emission),
		action
	) => {
		return generateKpiAfterSelectionReducer(
			state,
			action,
			"gesAndCO2EmissionKpi"
		);
	},
});

export default kpiReducer;
