export default {
	RESEAU_GAZ: {id: "reseau_gaz", path: "./assets/data/reseau_gaz.geojson"},
	ESTIMATION_CONSO_CHALEUR: {
		id: "estimation-conso-chaleur-ligne",
		path: "./assets/data/estimation-conso-chaleur-ligne.geojson",
	},
	RESEAU_CHALEUR: {
		id: "reseau_chaleur",
		path: "./assets/data/reseau_ch_cps.geojson",
	},
	PARCELLES: {
		id: "parcelles",
		path: "./assets/data/parcelles_for_visu.geojson",
	},
	CPS_RESIDENTIEL_TERTIAIRE: {
		id: "cps-residentiel-tertiaire-conso",
		path: "./assets/data/cps-residentiel-tertiaire.geojson",
	},
	GEOTHERMIE_EXPLOITATION: {
		id: "Geothermie_exploitation",
		path: "./assets/data/Geothermie_exploitation.geojson",
	},
	GEOTHERMAL_POTENTIAL: {
		id: "geothermal_potential",
		path: "./assets/data/cps_geothermal_potential.geojson",
	},
	ZAC: {id: "ZAC", path: "./assets/data/badora_od.geojson"},
	CPS: {id: "cps", path: "./assets/data/cps.geojson"},
	IRIS: {id: "cps iris", path: "./assets/data/iris.geojson"},
	BORNES_RECHARGE_ELECTRIQUE: {
		id: "bornes_de_recharge_electrique",
		path: "./assets/data/bornes_de_recharge_electrique.geojson",
	},
	TRONCON_AERIEN: {
		id: "troncon_aerien",
		path: "./assets/data/Export_troncon_aerien_BT.geojson",
	},
	TRONCON_CABLE: {
		id: "troncon_cable",
		path: "./assets/data/Export_troncon_cable_BT.geojson",
	},
	POSTE_SOURCE: {
		id: "poste_source",
		path: "./assets/data/Export_Poste_Source.geojson",
	},
	POSTE_ELEC: {
		id: "poste_elec",
		path: "./assets/data/Export_poste_electrique_HTA_BT_point_2.geojson",
	},
	WIND_POTENTIAL: {
		id: "wind_potential",
		path: "./assets/data/Zones_favorables.geojson",
	},
	ROOFTOP_POTENTIAL: {
		id: "rooftop_potential",
		path: "./assets/data/toiture.geojson",
	},
};
