export const StaticLists = {
	type: [
		"other",
		"electricity",
		"fuel",
		"gas",
		"biomass",
		"wasteincinerator",
		"photovoltaic",
		"geothermal",
	],
	controlable: ["yes", "no"],
	goal: ["averagegain", "investmentcost"],
	heatingType: ["gas", "fuel", "electricity"],
};

export const SOURCE = {
	static: "static", // Hard coded values
	extern: "extern", // FROM API
	intern: "intern", // Entered by the user
};

export const TYPE = {
	string: "string",
	numeric: "numeric",
	list: "list",
	date: "date",
};

const generateFieldObject = (
	id,
	type = null,
	source = SOURCE.intern,
	defaultValue = null,
	isFixed = false,
	units = null
) => ({
	id,
	type,
	source,
	options: source === SOURCE.static ? StaticLists[id] : null,
	defaultValue,
	isFixed,
	units,
});

export default [
	{
		key: "newNetworkCreation",
		index: 0,
		initialData: [],
		fields: [
			generateFieldObject("name", TYPE.string, SOURCE.extern, ""),
			generateFieldObject(
				"fixedPrice",
				TYPE.numeric,
				SOURCE.intern,
				null,
				null,
				"€"
			),
			generateFieldObject(
				"variableMargin",
				TYPE.numeric,
				SOURCE.intern,
				null,
				null,
				"%"
			),
		],
	},
	{
		key: "productionSource",
		index: 1,
		initialData: [],
		fields: [
			generateFieldObject("connectionNet", TYPE.list, SOURCE.extern, ""),
			generateFieldObject("priority", TYPE.numeric, SOURCE.intern, 1),
			generateFieldObject("controlable", TYPE.list, SOURCE.static, "yes"),
			generateFieldObject(
				"maxPower",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"MWh"
			),
			generateFieldObject(
				"gesPercent",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"kgCO2e/kWh"
			),
			generateFieldObject(
				"supplyCost",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"€/kWh"
			),
			generateFieldObject(
				"fixedCost",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"€"
			),
			generateFieldObject(
				"investmentCost",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"€"
			),
			generateFieldObject("type", TYPE.list, SOURCE.static, "gas"),
			generateFieldObject(
				"implementationDate",
				TYPE.date,
				SOURCE.intern,
				new Date().getFullYear()
			),
		],
	},
	{
		key: "networkConnection",
		index: 2,
		initialData: [],
		fields: [
			generateFieldObject("building", TYPE.string, SOURCE.extern),
			generateFieldObject(
				"consommation",
				TYPE.numeric,
				SOURCE.extern,
				null,
				true,
				"kWh"
			),
			generateFieldObject("connectionNet", TYPE.list, SOURCE.extern, ""),
			generateFieldObject(
				"implementationDate",
				TYPE.date,
				SOURCE.intern,
				new Date().getFullYear()
			),
		],
	},
	{
		key: "renovation",
		index: 3,
		initialData: [],
		fields: [
			generateFieldObject("building", TYPE.string, SOURCE.extern),
			generateFieldObject(
				"energyClass",
				TYPE.string,
				SOURCE.extern,
				null,
				true
			),
			generateFieldObject(
				"heatLostPercent",
				TYPE.numeric,
				SOURCE.extern,
				null,
				true,
				"W/K"
			),
			generateFieldObject(
				"implementationDate",
				TYPE.date,
				SOURCE.intern,
				new Date().getFullYear()
			),
			generateFieldObject(
				"goal",
				TYPE.list,
				SOURCE.static,
				"averagegain"
			),
			generateFieldObject("value", TYPE.numeric, SOURCE.intern, ""),
		],
	},
	{
		key: "energyProduction",
		index: 4,
		initialData: [],
		fields: [],
	},
	{
		key: "zac",
		index: 5,
		initialData: [],
		fields: [
			generateFieldObject("name", TYPE.string, SOURCE.intern),
			generateFieldObject(
				"surface",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"m²"
			),
			generateFieldObject(
				"environmentalClass",
				TYPE.numeric,
				SOURCE.intern,
				0,
				null,
				"kWh/m²/an"
			),
			generateFieldObject("heatingType", TYPE.list, SOURCE.extern, ""),
			generateFieldObject("city", TYPE.list, SOURCE.extern, ""),
			generateFieldObject(
				"implementationDate",
				TYPE.date,
				SOURCE.intern,
				new Date().getFullYear()
			),
		],
	},
];
