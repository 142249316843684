import {Auth} from "@cosmotech/core";
import {AuthMSAL} from "@cosmotech/azure";

// AuthMSAL configuration
const msalConfig = {
	loginRequest: {
		scopes: ["user.read"],
	},
	accessRequest: {
		scopes: [
			"https://cosmotechweb.onmicrosoft.com/23fd972f-7a4d-4b96-b8f0-26033ae428ac/Platform.Access",
		],
	},
	msalConfig: {
		auth: {
			clientId: "23fd972f-7a4d-4b96-b8f0-26033ae428ac",
			redirectUri:
				window.location.protocol + "//" + window.location.host + "/",
			authority:
				"https://login.microsoftonline.com/1fcfc752-2be8-42b2-be24-0f1bb2ef2164",
			knownAuthorities: [
				"https://login.microsoftonline.com/1fcfc752-2be8-42b2-be24-0f1bb2ef2164",
			],
		},
		cache: {
			cacheLocation: "localStorage",
			storeAuthStateInCookie: true,
		},
	},
};

// Register the providers used in the application
Auth.addProvider(AuthMSAL).setConfig(msalConfig);
