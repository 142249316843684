import {Formik} from "formik";
import React from "react";

export const initialValues = {
	productionSource: [],
	networkConnection: [],
	newNetworkCreation: [],
	renovation: [],
	energyProduction: [],
	zac: [],
};

export default (component) => () => (
	<Formik initialValues={initialValues}>
		{(props) => (
			<form>
				{component &&
					React.createElement(component, {
						values: props.values,
						setFieldValue: props.setFieldValue,
					})}
			</form>
		)}
	</Formik>
);
