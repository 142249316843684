import KPIGroupKeysEnum from "../enums/KPIGroupKeysEnum";
import KPIKeysEnum from "../enums/KPIKeysEnum";

export const kpiTypes = {
	COST: "COST",
	PERCENT: "PERCENT",
	CURRENCY: "CURRENCY",
};

export const kpiLabels = {
	[KPIKeysEnum.annualEnergyConsumption]: "Consommation d'énergie, MWh/an",
	[KPIKeysEnum.totalEnergyConsumption]: "Total Consommation d'énergie, MWh",
	[KPIKeysEnum.energyProduction]: "Production d'En&R, kWh",
	[KPIKeysEnum.totalEnergyProduction]: "Total Production d'En&R, kWh",
	[KPIKeysEnum.gesAndCO2Emission]: "Emissions de GES, T eq.CO2/an",
	[KPIKeysEnum.totalGesAndCO2Emission]: "Total Emissions de GES, T eq.CO2",
	[KPIKeysEnum.avgCostPerFinalCustomer]:
		"Coût moyen du kWh pour le client final, €",
	[KPIKeysEnum.investCost]: "Coût d'investissement, k€",
	[KPIKeysEnum.operatingCost]: "Coût d'exploitation, k€",
	[KPIKeysEnum.localEnergyPart]: "Part d'énergie locale",
	[KPIKeysEnum.frenchEneryPart]: "Part d'énergie d'origine française",
};

// TODO: Must Add Other params to specify the types of kpis
export default {
	beforeSimulation: [
		{
			groupName: KPIGroupKeysEnum.globalIndicators,
			kpis: [
				{
					key: KPIKeysEnum.annualEnergyConsumption,
					title: "annualEnergyConsumption",
				},
				// ? Temporarly we hide this kpi
				// {
				// 	key: KPIKeysEnum.energyProduction,
				// 	title: kpiLabels.energyProduction,
				// },
				{
					key: KPIKeysEnum.gesAndCO2Emission,
					title: "gesAndCO2Emission",
				},
			],
		},
	],
	afterSimulation: [
		{
			groupName: KPIGroupKeysEnum.globalIndicators,
			kpis: [
				{
					key: KPIKeysEnum.totalEnergyConsumption,
					type: kpiTypes.COST,
					title: "totalEnergyConsumption",
				},
				{
					key: KPIKeysEnum.totalEnergyProduction,
					type: kpiTypes.COST,
					title: "totalEnergyProduction",
				},
				{
					key: KPIKeysEnum.totalGesAndCO2Emission,
					type: kpiTypes.COST,
					title: "totalGesAndCO2Emission",
				},
			],
		},
		{
			groupName: KPIGroupKeysEnum.financialIndicators,
			kpis: [
				{
					key: KPIKeysEnum.avgCostPerFinalCustomer,
					type: kpiTypes.COST,
					fixedDigits: 3,
					title: "avgCostPerFinalCustomer",
				},
				{
					key: KPIKeysEnum.investCost,
					type: kpiTypes.COST,
					fixedDigits: 3,
					title: "investCost",
				},
				{
					key: KPIKeysEnum.operatingCost,
					type: kpiTypes.COST,
					title: "operatingCost",
				},
			],
		},
		// ? Temporarly we hide this kpis
		// {
		// 	groupName: kpiGroupLabels.localIndicators,
		// 	kpis: [
		// 		{
		// 			key: KPIKeysEnum.localEnergyPart,
		//          type: kpiTypes.PERCENT,
		// 			title: kpiLabels.localEnergyPart,
		// 		},
		// 		{
		// 			key: KPIKeysEnum.frenchEneryPart,
		//          type: kpiTypes.PERCENT,
		// 			title: kpiLabels.frenchEneryPart,
		// 		},
		// 	],
		// },
	],
};
