import React from "react";

const ScenarioTabPanel = ({
	children,
	value,
	index,
	className,
	...otherProps
}) => {
	if (value !== index) return null;
	return <div {...otherProps}>{children}</div>;
};

export default React.memo(ScenarioTabPanel);
