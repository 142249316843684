import {
	black,
	darkGreen,
	darkPurple,
	paleOrange,
	yellow,
} from "../typeFiltersColors.scss";
import {
	blue,
	brown,
	darkBlue,
	darkBrown,
	darkPink,
	darkRed,
	darkerBlue,
	darkerTurquoise,
	gold,
	greenSea,
	purple,
	turquoise,
	veryLightPurple,
} from "styles/mapLayoutColors.scss";

import React from "react";

const generateIconStyle = (backgroundColor, otherStyles = {}) => ({
	display: "inline-block",
	borderRadius: "14px",
	height: "14px",
	width: "14px",
	backgroundColor,
	...otherStyles,
});

export default {
	IRIS_BORDER: <div style={generateIconStyle(black)} />,
	CPS_BORDER: <div style={generateIconStyle(black)} />,
	CPS_DENSITE: <div style={generateIconStyle(darkBlue)} />,
	PARCELLES_FOND: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${blue}, ${yellow})`,
			})}
		/>
	),
	ZAC: <div style={generateIconStyle(paleOrange)} />,
	BORNES_RECHARGE_ELECTRIQUE: <div style={generateIconStyle(darkGreen)} />,
	RESEAUX_GAZ: <div style={generateIconStyle(gold)} />,
	RESEAUX_CHALEUR: <div style={generateIconStyle(darkRed)} />,
	RESEAUX_ELECTRIQUE_CABLE: <div style={generateIconStyle(darkPurple)} />,
	RESEAUX_ELECTRIQUE_POSTE: <div style={generateIconStyle(darkPink)} />,
	GEOTHERMIE_EXPLOITATION: <div style={generateIconStyle(paleOrange)} />,
	CONSO_CHALEUR_VOIRIE: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${turquoise}, ${darkerBlue})`,
			})}
		/>
	),
	CONSO_CHALEUR_BATI: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${turquoise}, ${darkerBlue})`,
			})}
		/>
	),
	DOGGER: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${brown}, ${darkBrown})`,
			})}
		/>
	),
	LUSITANIEN: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${brown}, ${darkBrown})`,
			})}
		/>
	),
	NEOCOMIEN: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${brown}, ${darkBrown})`,
			})}
		/>
	),
	ALBIEN: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${brown}, ${darkBrown})`,
			})}
		/>
	),
	WIND_POTENTIAL: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${veryLightPurple}, ${purple})`,
			})}
		/>
	),
	ROOFTOP_POTENTIAL: (
		<div
			style={generateIconStyle("", {
				background: `linear-gradient(90deg, ${darkerTurquoise}, ${greenSea})`,
			})}
		/>
	),
};
