import {
	GET_BUILDINGS_PARAM,
	GET_MODIFICATIONS_PARAM,
	GET_SCENARIO_PARAM,
	SET_BUILDINGS_PARAM,
	SET_ENERGY_LABEL_PARAM,
	SET_HEAT_ENERGY_PARAM,
	SET_MODIFICATIONS_PARAM,
	SET_ZONES_PARAM,
} from "../constants";
import {
	GET_KPIS_AFTER_SELECTION,
	SET_KPIS_AFTER_SELECTION,
} from "domain/kpi/constants";
import {all, call, put, select, takeLatest} from "redux-saga/effects";
import {
	getDrawInstance,
	getMapInstance,
	hideDrawControls,
	setMapFilterTypeAction,
} from "domain/map/actions";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import LayerIdEnum from "utils/enums/LayerIdEnum";
import MapFiltersGroupEnum from "utils/enums/MapFiltersGroupEnum";
import {SET_CURRENT_SCENARIO} from "domain/scenario/constants";
import ScenarioModificationsToScenarioTable from "utils/models/mappers/ScenarioModificationsToScenarioTable";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {filterByLayerDataFeatureMapAction} from "domain/map/layerActions";
import {selectSelectionZonesFilters} from "utils/helpers/sagaSelectors";
import {setKpisAfterSimulationAction} from "domain/kpi/actions";
import {setNotificationInfoAction} from "domain/notification/actions";
import {v4 as uuidv4} from "uuid";
import {withPathParams} from "utils/helpers/apiHelpers";

function* kpisData(indicators, outputIndicators) {
	if (indicators && Object.keys(indicators).length) {
		yield put({
			type: GET_KPIS_AFTER_SELECTION,
		});
		yield put({
			type: SET_KPIS_AFTER_SELECTION,
			annualEnergyConsumptionKpi: indicators.annualEnergyConsumption,
			gesAndCO2EmissionKpi: indicators.gesAndCO2Emission,
		});
		if (outputIndicators && Object.keys(outputIndicators).length) {
			yield put(
				setKpisAfterSimulationAction({
					totalEnergyConsumptionKpi:
						outputIndicators.totalEnergyConsumptionKpi,
					totalEnergyProductionKpi:
						outputIndicators.totalEnergyProductionKpi,
					totalGesAndCO2EmissionKpi:
						outputIndicators.totalGesAndCO2EmissionKpi,
					avgCostPerFinalCustomerKpi:
						outputIndicators.avgCostPerFinalCustomerKpi,
					investCostKpi: outputIndicators.investCostKpi,
					operatingCostKpi: outputIndicators.operatingCostKpi,
					// ? This Kpis are not yet saved in the database
					localEnergyPartKpi: {
						value: null,
						subValue: null,
					},
					frenchEneryPartKpi: {
						value: null,
						subValue: null,
					},
				})
			);
		}
	}
}

function* selectionsData(selections) {
	if (!(selections?.zoneIds?.length || selections?.buildingIds?.length)) {
		return;
	}
	yield put({type: GET_BUILDINGS_PARAM});
	const zones = yield select(selectSelectionZonesFilters);
	yield put({
		type: SET_ZONES_PARAM,
		list: zones
			.filter((zone) => (selections?.zoneIds).includes(zone["idzone"]))
			.map((zone) => zone["idzone"] + "+" + zone["name"]),
	});
	if (selections?.heatEnergyIds?.length > 0) {
		yield put({
			type: SET_HEAT_ENERGY_PARAM,
			list: (selections?.heatEnergyIds).map((e) => e + "+"),
		});
	}
	if (selections?.energyLabelIds?.length > 0) {
		yield put({
			type: SET_ENERGY_LABEL_PARAM,
			list: (selections?.energyLabelIds).map((e) => e + "+"),
		});
	}
	if (!selections?.buildingIds) {
		yield put({type: SET_BUILDINGS_PARAM, list: []});
	}
	if (selections?.buildingIds?.length > 0) {
		const parcelIdList = selections.buildingIds;
		const {data} = yield axios.post(ApiRoutePathsEnum.GET_TABLE, {
			parcelIdList: parcelIdList,
		});
		yield put({type: SET_BUILDINGS_PARAM, list: data});
		yield put(
			setMapFilterTypeAction(MapFiltersGroupEnum.PARCELLES_FOND, true)
		);
		// TODO: fix the issue of the styles of the map may not completely load when we navigate from page to page
		if (getMapInstance()) {
			yield getMapInstance().setLayoutProperty(
				LayerIdEnum.PARCELLES_FOND,
				"visibility",
				"visible"
			);
			yield filterByLayerDataFeatureMapAction(
				getMapInstance(),
				LayerIdEnum.PARCELLES_FOND,
				"idpar",
				parcelIdList.map((d) => d.replaceAll("_", " "))
			);
		}
		if (getDrawInstance()) {
			hideDrawControls();
		}
	}
	if (getMapInstance() && getDrawInstance()) {
		getDrawInstance().deleteAll();
		getDrawInstance().changeMode("static");
		getDrawInstance().add({
			id: uuidv4(),
			type: "Feature",
			properties: {},
			geometry: {
				type: "MultiPolygon",
				coordinates: selections.polygons,
			},
		});
	}
}

function* modificationsData(modifications) {
	if (modifications) {
		yield put({
			type: GET_MODIFICATIONS_PARAM,
		});
		yield put({
			type: SET_MODIFICATIONS_PARAM,
			table: ScenarioModificationsToScenarioTable(modifications),
		});
	}
}

function* scenarioParamData(action) {
	try {
		const {
			data: {
				name,
				simulation_start,
				simulation_end,
				sagaId,
				selections,
				indicators,
				outputIndicators,
				modifications,
			},
		} = yield axios.get(
			withPathParams({
				id: action.scenarioId,
			})(ApiRoutePathsEnum.GET_SCENARIO)
		);
		yield put({
			type: SET_CURRENT_SCENARIO,
			scenario: {name, simulation_start, simulation_end, sagaId},
		});
		yield all([
			call(kpisData, indicators, outputIndicators),
			call(selectionsData, selections),
			call(modificationsData, modifications),
		]);
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.KPI,
				notificationInfos.KPI.error.GET_KPIS
			)
		);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.PARAMETERS,
				notificationInfos.PARAMETERS.error.GET_BUILDINGS
			)
		);
	}
}

function* getScenarioParamData() {
	yield takeLatest(GET_SCENARIO_PARAM, scenarioParamData);
}

export default getScenarioParamData;
