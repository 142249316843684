import {CREATE_SCENARIO, RESET_SCENARIO_FORM_VALUES} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, select, takeEvery} from "redux-saga/effects";
import {
	selectCurrentScenario,
	selectFromViewScenarioCreation,
	selectKpiIndicators,
	selectSelectionFilters,
} from "utils/helpers/sagaSelectors";

import AlternativeScenarioCreateDTOModel from "utils/models/AlternativeScenarioCreateDTOModel";
import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import FromView from "utils/enums/FromView";
import {RESET_KPIS} from "domain/kpi/constants";
import {RESET_SCENARIO_PARAM} from "domain/parameters/constants";
import {RESET_SELECTION_CURRENT_FILTERS} from "domain/map/constants";
import ScenarioModel from "utils/models/ScenarioModel";
import ScenarioUpdateDTOModel from "utils/models/ScenarioUpdateDTOModel";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {getScenarioTreeAction} from "../actions";
import {setNotificationInfoAction} from "domain/notification/actions";
import {withPathParams} from "utils/helpers/apiHelpers";

function* scenarioData(action) {
	try {
		const fromView = yield select(selectFromViewScenarioCreation);
		const currentScenario = yield select(selectCurrentScenario);

		if (fromView === FromView.scenarioView) {
			if (!action.scenario.parentId) {
				yield axios.post(
					ApiRoutePathsEnum.CREATE_SCENARIO,
					ScenarioModel(action.scenario)
				);
			} else {
				const {data} = yield axios.get(
					withPathParams({
						id: action.scenario.parentId,
					})(ApiRoutePathsEnum.GET_SCENARIO)
				);
				const alternativeScenario = AlternativeScenarioCreateDTOModel({
					name: action.scenario.name,
					description: action.scenario.description,
					scenarioParent: data,
				});
				yield axios.post(
					ApiRoutePathsEnum.CREATE_SCENARIO,
					alternativeScenario
				);
			}
		} else if (fromView === FromView.digitalTwinView) {
			if (currentScenario.type === "base") {
				let scenarioParent;
				if (!currentScenario.children.length) {
					const indicators = yield select(selectKpiIndicators);
					const selections = yield select(selectSelectionFilters);
					const scenarioUpdateDTO = ScenarioUpdateDTOModel({
						id: action.scenario.parentId,
						indicators,
						selections,
					});
					const responseUpdate = yield axios.patch(
						ApiRoutePathsEnum.UPDATE_SCENARIO,
						{
							scenarioUpdateDTO,
						}
					);
					scenarioParent = ScenarioModel(responseUpdate.data);
				} else {
					const {data} = yield axios.get(
						withPathParams({
							id: action.scenario.parentId,
						})(ApiRoutePathsEnum.GET_SCENARIO)
					);
					scenarioParent = ScenarioModel(data);
				}
				const alternativeScenarioCreateDTOModel = AlternativeScenarioCreateDTOModel(
					{
						name: action.scenario.name,
						description: action.scenario.description,
						scenarioParent,
					}
				);
				yield axios.post(
					ApiRoutePathsEnum.CREATE_SCENARIO,
					alternativeScenarioCreateDTOModel
				);
			} else {
				const {data} = yield axios.get(
					withPathParams({
						id: action.scenario.parentId,
					})(ApiRoutePathsEnum.GET_SCENARIO)
				);
				const alternativeScenario = AlternativeScenarioCreateDTOModel({
					name: action.scenario.name,
					description: action.scenario.description,
					scenarioParent: data,
				});
				yield axios.post(
					ApiRoutePathsEnum.CREATE_SCENARIO,
					alternativeScenario
				);
			}
		}

		yield put(
			getScenarioTreeAction({
				from: "fromCreate",
			})
		);

		yield put({type: RESET_SCENARIO_PARAM});

		yield put({type: RESET_SELECTION_CURRENT_FILTERS});

		yield put({type: RESET_KPIS});

		yield put({type: RESET_SCENARIO_FORM_VALUES});

		yield put(
			setNotificationInfoAction(
				StatusEnum.SUCCESS,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.success.ADD_SCENARIO
			)
		);
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.error.ADD_SCENARIO
			)
		);
	}
}

function* createScenarioData() {
	yield takeEvery(CREATE_SCENARIO, scenarioData);
}

export default createScenarioData;
