export const ProductionSourceRow = ({
	connectionNet = "",
	controlable = "yes",
	priority = 1,
	maxPower = 0,
	gesPercent = 0,
	fixedCost = 0,
	supplyCost = 0,
	investmentCost = 0,
	type = "gas",
	implementationDate = new Date().getFullYear(),
} = {}) => ({
	connectionNet,
	controlable,
	priority,
	maxPower,
	gesPercent,
	fixedCost,
	supplyCost,
	investmentCost,
	type,
	implementationDate,
});

export const NetworkConnectionRow = ({
	building = "",
	consommation,
	connectionNet = "",
	implementationDate = new Date().getFullYear(),
} = {}) => ({
	building,
	consommation,
	connectionNet,
	implementationDate,
});

export const NewNetworkCreationRow = ({
	name = "",
	fixedPrice = 0,
	variableMargin = 0,
} = {}) => ({
	name,
	fixedPrice,
	variableMargin,
});

export const RenovationRow = ({
	building = "",
	energyClass = "",
	heatLostPercent,
	implementationDate = new Date().getFullYear(),
	goal = "averagegain",
	value,
} = {}) => ({
	building,
	energyClass,
	heatLostPercent,
	implementationDate,
	goal,
	value,
});

export const ZacRow = ({
	name = "",
	surface = 0,
	environmentalClass = 0,
	heatingType = "gas",
	city = "",
	implementationDate = new Date().getFullYear(),
} = {}) => ({
	name,
	surface,
	environmentalClass,
	heatingType,
	city,
	implementationDate,
});

export const FormSectionMapToModel = {
	productionSource: ProductionSourceRow,
	networkConnection: NetworkConnectionRow,
	newNetworkCreation: NewNetworkCreationRow,
	renovation: RenovationRow,
	energyProduction: () => ({}),
	zac: ZacRow,
};
