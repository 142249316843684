export const useScenarioBreadcrumbStyles = (theme) => ({
	separatorArrow: {
		fontSize: "18px",
		color: theme.palette.text.grey,
		margin: "0 8px",
	},
	cancelButton: {
		fontSize: "18px",
		color: theme.palette.text.grey,
		margin: "0 8px",
		cursor: "pointer",
	},
});
