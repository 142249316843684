import {Checkbox} from "@material-ui/core";
import React from "react";

const CheckBoxBase = ({children, ...props}) => (
	<Checkbox
		iconstyle={{fill: "white"}}
		inputstyle={{color: "white"}}
		style={{color: "white"}}
		{...props}>
		{children}
	</Checkbox>
);

export default CheckBoxBase;
