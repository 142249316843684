import {
	CLOSE_CREATION_SCENARIO_MODAL,
	CREATE_SCENARIO,
	DELETE_SCENARIOS,
	GET_CURRENT_SCENARIO,
	GET_SCENARIO_TREE,
	LAUNCH_BASE_SIMULATION,
	LAUNCH_SIMULATION,
	OPEN_CREATION_SCENARIO_MODAL,
	RESET_SCENARIO_FORM_VALUES,
	SET_CURRENT_SCENARIO,
	SET_SCENARIO_FORM_VALUES,
} from "./constants";

import FromView from "utils/enums/FromView";

// Redux Action
export const getScenarioTreeAction = (payLoad) => ({
	type: GET_SCENARIO_TREE,
	...payLoad,
});

// Redux Action
export const createScenarioAction = (scenario) => ({
	type: CREATE_SCENARIO,
	scenario,
});

// Redux Action
export const getCurrentScenarioAction = (id) => ({
	type: GET_CURRENT_SCENARIO,
	id,
});

// Redux Action
export const setCurrentScenarioAction = (scenario) => ({
	type: SET_CURRENT_SCENARIO,
	...scenario,
});

// Redux Action
export const deleteScenariosAction = (scenarios) => ({
	type: DELETE_SCENARIOS,
	scenarios,
});

// Redux Action
export const openCreationScenarioModalAction = (
	from = FromView.scenarioView
) => ({
	type: OPEN_CREATION_SCENARIO_MODAL,
	from,
});

// Redux Action
export const closeCreationScenarioModalAction = () => ({
	type: CLOSE_CREATION_SCENARIO_MODAL,
});

// Redux Action
export const launchSimulationAction = (scenarioId) => ({
	type: LAUNCH_SIMULATION,
	scenarioId,
});

// Redux Action
export const setScenarioFormValuesAction = (formValues) => ({
	type: SET_SCENARIO_FORM_VALUES,
	formValues,
});

// Redux Action
export const resetScenarioFormValuesAction = () => ({
	type: RESET_SCENARIO_FORM_VALUES,
});

// Redux Action
export const launchBaseSimulationAction = (scenarioId) => ({
	type: LAUNCH_BASE_SIMULATION,
	scenarioId,
});
