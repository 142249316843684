import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	withStyles,
} from "@material-ui/core";
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
	hydrateCurrentEnergyLabelFromheatEnergyTypeFiltersList,
	hydrateCurrentHeatEnergyTypeFromheatEnergyTypeFiltersList,
	mergeZonesForEnergyLabelFilters,
	mergeZonesForHeatEnergyTypeFilters,
} from "domain/map/mappers";

import {CancelRounded} from "@material-ui/icons";
import CheckBoxBase from "common/presentational/components/CheckBox";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getDrawInstance} from "domain/map/actions";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const MapFiltersSelection = ({
	classes,
	zoneFiltersList,
	heatEnergyTypeFiltersList,
	energyLabelFiltersList,
	buildingFiltersList,
	getSelectionZoneFiltersAction,
	getSelectionHeatEnergyTypeFiltersAction,
	getSelectionEnergyLabelFiltersAction,
	getSelectionBuildingFiltersAction,
	removeHeatEnergyFromFiltersByZoneIdAction,
	removeEnergyLabelFromFiltersByHeatEnergyTypeAction,
	setSelectionZoneCurrentFiltersAction,
	setSelectionHeatEnergyCurrentFiltersAction,
	setSelectionEnergyLabelCurrentFiltersAction,
	currentScenarioZones,
	currentScenarioHeatEnergies,
	currentScenarioEnergyLabels,
	currentScenarioBuildings,
	drawnBuildingNumber,
	resetKpisAction,
}) => {
	const {t} = useTranslation();
	// ** States
	const [currentZones, setCurrentZones] = useState([]);

	const [currentHeatEnergyTypes, setCurrentHeatEnergyTypes] = useState([]);

	const [currentEnergyLabels, setCurrentEnergyLabels] = useState([]);

	const [isHeatEnergyTypesLoaded, setHeatEnergyTypesLoaded] = useState(false);

	const [isEnergyLabelsLoaded, setEnergyLabelsLoaded] = useState(false);

	const [buildingsNumber, setBuildingsNumber] = useState(0);

	// ** Effects
	// Load all the zones on Mount
	useEffect(() => {
		getSelectionZoneFiltersAction();
	}, []);

	// It's used when we update the current zones therefore the heatEnergyTypeFiltersList is refreshed and we update selection info
	useEffect(() => {
		if (currentHeatEnergyTypes.length) {
			const newCurrentHeatEnergyTypes = hydrateCurrentHeatEnergyTypeFromheatEnergyTypeFiltersList(
				heatEnergyTypeFiltersList,
				currentHeatEnergyTypes
			);
			let buildingNumbers = 0;
			(newCurrentHeatEnergyTypes || []).forEach((heatEnergyInfos) => {
				buildingNumbers += parseInt(heatEnergyInfos.split("+")[1]);
			});
			setBuildingsNumber(buildingNumbers);
			setCurrentHeatEnergyTypes(newCurrentHeatEnergyTypes);
		}
	}, [heatEnergyTypeFiltersList]);

	// It's used when we update the current heat energies therefore the energyLabelFiltersList is refreshed and we update selection info
	useEffect(() => {
		if (currentEnergyLabels.length) {
			const newCurrentEnergyLabels = hydrateCurrentEnergyLabelFromheatEnergyTypeFiltersList(
				energyLabelFiltersList,
				currentEnergyLabels
			);
			let buildingNumbers = 0;
			(newCurrentEnergyLabels || []).forEach((energyLabelInfos) => {
				buildingNumbers += parseInt(energyLabelInfos.split("+")[1]);
			});
			setBuildingsNumber(buildingNumbers);
			setCurrentEnergyLabels(newCurrentEnergyLabels);
		}
	}, [energyLabelFiltersList]);

	// It's used to reset all the information of selection ( for an example when we clear the current scenario)
	useEffect(() => {
		if (
			!currentScenarioZones.length &&
			!currentScenarioHeatEnergies.length &&
			!currentScenarioEnergyLabels.length
		) {
			setCurrentZones([]);
			setCurrentHeatEnergyTypes([]);
			setCurrentEnergyLabels([]);
			setHeatEnergyTypesLoaded(false);
			setEnergyLabelsLoaded(false);
		}
	}, [
		currentScenarioZones,
		currentScenarioHeatEnergies,
		currentScenarioEnergyLabels,
	]);

	// Refresh the building number according to several inputs (polygons , buildings, currentScenarioBuildings)
	useEffect(() => {
		if (currentScenarioBuildings.list?.length > 0) {
			setBuildingsNumber(currentScenarioBuildings.list?.length);
		} else if (
			!buildingFiltersList?.length &&
			!drawnBuildingNumber &&
			!currentScenarioZones.length &&
			!currentScenarioHeatEnergies.length &&
			!currentScenarioEnergyLabels.length
		) {
			setBuildingsNumber(0);
		} else if (drawnBuildingNumber) {
			setBuildingsNumber(drawnBuildingNumber);
		} else if (buildingFiltersList?.length && !drawnBuildingNumber) {
			setBuildingsNumber(buildingFiltersList?.length);
		}
	}, [
		buildingFiltersList,
		drawnBuildingNumber,
		currentScenarioBuildings,
		currentScenarioZones,
		currentScenarioHeatEnergies,
		currentScenarioEnergyLabels,
	]);

	// Trigger the action to get the information of buildings when we update the current selections
	useEffect(() => {
		if (
			currentZones.length &&
			currentHeatEnergyTypes.length &&
			currentEnergyLabels.length
		) {
			getSelectionBuildingFiltersAction(
				(currentZones || [])
					.map((value) => value.split("+")[0])
					.join(","),
				(currentHeatEnergyTypes || [])
					.map((value) => value.split("+")[0])
					.join(","),
				(currentEnergyLabels || [])
					.map((value) => value.split("+")[0])
					.join(",")
			);
		}
	}, [currentZones, currentHeatEnergyTypes, currentEnergyLabels]);

	// Reset Kpis when we flush the currentEnergyLabels List
	useEffect(() => {
		if (
			currentZones.length &&
			currentHeatEnergyTypes.length &&
			!currentEnergyLabels?.length
		) {
			resetKpisAction();
		}
	}, [currentZones, currentHeatEnergyTypes, currentEnergyLabels]);

	// ** Callbacks :
	const handleZonesChange = useCallback((event) => {
		let buildingNumbers = 0;
		(event.target.value || []).forEach((zoneInfos) => {
			buildingNumbers += parseInt(zoneInfos.split("+")[2]);
		});
		setBuildingsNumber(buildingNumbers);
		setCurrentZones(event.target.value);
		// We set the Current zones to prepare selections data when we want to create/update scenarios
		setSelectionZoneCurrentFiltersAction(
			event.target.value.map((zoneInfos) => zoneInfos.split("+")[0])
		);
	}, []);

	const handleHeatEnergyChange = useCallback((event) => {
		let buildingNumbers = 0;
		(event.target.value || []).forEach((heatEnergyInfos) => {
			buildingNumbers += parseInt(heatEnergyInfos.split("+")[1]);
		});
		setBuildingsNumber(buildingNumbers);
		setCurrentHeatEnergyTypes(event.target.value);
		// We set the Current Heat Energies to prepare selections data when we want to create/update scenarios
		setSelectionHeatEnergyCurrentFiltersAction(
			event.target.value.map(
				(heatEnergyInfos) => heatEnergyInfos.split("+")[0]
			)
		);
	}, []);

	const handleEnergyLabelChange = useCallback((event) => {
		let buildingNumbers = 0;
		(event.target.value || []).forEach((energyLabelInfos) => {
			buildingNumbers += parseInt(energyLabelInfos.split("+")[1]);
		});
		setBuildingsNumber(buildingNumbers);
		setCurrentEnergyLabels(event.target.value);
		// We set the Current Energy labels to prepare selections data when we want to create/update scenarios
		setSelectionEnergyLabelCurrentFiltersAction(
			event.target.value.map(
				(energyLabelInfos) => energyLabelInfos.split("+")[0]
			)
		);
	}, []);

	// We Load the list of heat energies when we close the select field of zones
	const handleZonesClose = useCallback(() => {
		if (currentZones.length) {
			getSelectionHeatEnergyTypeFiltersAction(
				currentZones.map((value) => value.split("+")[0]).join(",")
			);
			setHeatEnergyTypesLoaded(true);
		}
	}, [currentZones]);

	// We Load the list of energy labels when we close the select field of heat energies
	const handleHeatEnergyClose = useCallback(() => {
		if (currentZones.length && currentHeatEnergyTypes.length) {
			getSelectionEnergyLabelFiltersAction(
				currentZones.map((value) => value.split("+")[0]).join(","),
				currentHeatEnergyTypes
					.map((value) => value.split("+")[0])
					.join(",")
			);
			setEnergyLabelsLoaded(true);
		}
	}, [currentZones, currentHeatEnergyTypes]);

	const removeCurrentZone = useCallback(
		(zone) => () => {
			removeHeatEnergyFromFiltersByZoneIdAction(
				parseInt(zone.split("+")[0])
			);
			if (currentZones.length === 1) {
				setHeatEnergyTypesLoaded(false);
				setEnergyLabelsLoaded(false);
			}
			setCurrentZones(currentZones.filter((name) => zone !== name));
			if (
				Math.max(
					currentEnergyLabels.length,
					currentHeatEnergyTypes.length
				) === 0 &&
				buildingsNumber > 0
			) {
				setBuildingsNumber(
					buildingsNumber - parseInt(zone.split("+")[2])
				);
			}
			if (getDrawInstance()) {
				getDrawInstance().deleteAll();
			}
		},
		[
			buildingsNumber,
			currentEnergyLabels,
			currentHeatEnergyTypes,
			currentZones,
			removeHeatEnergyFromFiltersByZoneIdAction,
		]
	);

	const removeCurrentHeatEnergy = useCallback(
		(heatEnergy) => () => {
			removeEnergyLabelFromFiltersByHeatEnergyTypeAction(
				heatEnergy.split("+")[0]
			);
			setCurrentHeatEnergyTypes(
				currentHeatEnergyTypes.filter((name) => heatEnergy !== name)
			);
			if (currentEnergyLabels.length === 0) {
				setBuildingsNumber(
					buildingsNumber - parseInt(heatEnergy.split("+")[1])
				);
			}
			if (currentHeatEnergyTypes.length === 1) {
				setEnergyLabelsLoaded(false);
				let buildingNumbersValue = 0;
				currentZones.forEach((zoneInfos) => {
					buildingNumbersValue += parseInt(zoneInfos.split("+")[2]);
				});
				setBuildingsNumber(buildingNumbersValue);
			}
			if (getDrawInstance()) {
				getDrawInstance().deleteAll();
			}
		},
		[
			currentZones,
			buildingsNumber,
			currentEnergyLabels,
			currentHeatEnergyTypes,
		]
	);

	const removeCurrentEnergyLabel = useCallback(
		(energyLabel) => () => {
			setCurrentEnergyLabels(
				currentEnergyLabels.filter((name) => energyLabel !== name)
			);
			if (currentEnergyLabels.length === 1) {
				let buildingNumbersValue = 0;
				currentHeatEnergyTypes.forEach((heatEnergy) => {
					buildingNumbersValue += parseInt(heatEnergy.split("+")[1]);
				});
				setBuildingsNumber(buildingNumbersValue);
			} else {
				setBuildingsNumber(
					buildingsNumber - parseInt(energyLabel.split("+")[1])
				);
			}
			if (getDrawInstance()) {
				getDrawInstance().deleteAll();
			}
		},
		[
			currentZones,
			buildingsNumber,
			currentEnergyLabels,
			currentHeatEnergyTypes,
		]
	);

	const zoneTagsItems = useMemo(
		() =>
			(currentScenarioZones?.length > 0
				? currentScenarioZones
				: currentZones
			).map((zone) => (
				<div key={zone.split("+")[0]} className={classes.zoneTag}>
					<div className={classes.zoneTagName}>
						{zone.split("+")[1]}
					</div>
					{!currentScenarioZones?.length && (
						<CancelRounded
							style={{cursor: "pointer", marginLeft: "2px"}}
							onClick={
								currentScenarioZones?.length > 0
									? null
									: removeCurrentZone(zone)
							}
						/>
					)}
				</div>
			)),
		[currentZones, currentScenarioZones, classes.zoneTag, removeCurrentZone]
	);

	const heatEnergyTagsItems = useMemo(
		() =>
			(currentScenarioHeatEnergies?.length > 0
				? currentScenarioHeatEnergies
				: currentHeatEnergyTypes
			).map((heatEnergy) => (
				<div key={heatEnergy.split("+")[0]} className={classes.zoneTag}>
					{heatEnergy.split("+")[0]}
					{!currentScenarioHeatEnergies?.length && (
						<CancelRounded
							style={{cursor: "pointer"}}
							onClick={removeCurrentHeatEnergy(heatEnergy)}
						/>
					)}
				</div>
			)),
		[
			currentHeatEnergyTypes,
			currentScenarioHeatEnergies,
			classes.zoneTag,
			removeCurrentHeatEnergy,
		]
	);

	const energyLabelTagsItems = useMemo(
		() =>
			(currentScenarioEnergyLabels?.length > 0
				? currentScenarioEnergyLabels
				: currentEnergyLabels
			).map((energyLabel) => (
				<div
					key={energyLabel.split("+")[0]}
					className={classes.zoneTag}>
					{energyLabel.split("+")[0]}
					{!currentScenarioEnergyLabels?.length && (
						<CancelRounded
							style={{cursor: "pointer"}}
							onClick={removeCurrentEnergyLabel(energyLabel)}
						/>
					)}
				</div>
			)),
		[
			currentEnergyLabels,
			currentScenarioEnergyLabels,
			classes.zoneTag,
			removeCurrentZone,
		]
	);

	const zoneMenuItems = useMemo(
		() =>
			zoneFiltersList.map((zone) => (
				<MenuItem
					id={`zone-${zone.idzone}`}
					key={zone.idzone}
					value={`${zone.idzone}+${zone.name}+${zone.countbuilding}`}>
					<CheckBoxBase
						id={`check-zone-${zone.idzone}`}
						checked={currentZones.includes(
							`${zone.idzone}+${zone.name}+${zone.countbuilding}`
						)}
					/>
					{zone.name}
				</MenuItem>
			)),
		[zoneFiltersList, currentZones]
	);

	const heatEnergyTypesMenuItems = useMemo(
		() =>
			mergeZonesForHeatEnergyTypeFilters(heatEnergyTypeFiltersList).map(
				(heatEnergy) => (
					<MenuItem
						id={`heatEnergy-${heatEnergy.heatenergytype}`}
						key={heatEnergy.heatenergytype}
						value={`${heatEnergy.heatenergytype}+${heatEnergy.countbuilding}`}>
						<CheckBoxBase
							id={`check-heatEnergy-${heatEnergy.heatenergytype}`}
							checked={currentHeatEnergyTypes.includes(
								`${heatEnergy.heatenergytype}+${heatEnergy.countbuilding}`
							)}
						/>
						{heatEnergy.heatenergytype}
					</MenuItem>
				)
			),
		[heatEnergyTypeFiltersList, currentHeatEnergyTypes]
	);

	const energyLabelsMenuItems = useMemo(
		() =>
			mergeZonesForEnergyLabelFilters(energyLabelFiltersList).map(
				(energy) => (
					<MenuItem
						id={`energy-${energy.energylabel}`}
						key={energy.energylabel}
						value={`${energy.energylabel}+${energy.countbuilding}`}>
						<CheckBoxBase
							id={`check-energy-${energy.energylabel}`}
							checked={currentEnergyLabels.includes(
								`${energy.energylabel}+${energy.countbuilding}`
							)}
						/>
						{energy.energylabel}
					</MenuItem>
				)
			),
		[energyLabelFiltersList, currentEnergyLabels]
	);

	return (
		<div>
			<div className={classes.titleContainer}>
				<div>{t("components.mapfiltersselection.title")}</div>
				<div className={classes.buildingNumberTag}>
					{buildingsNumber}{" "}
					{buildingsNumber === 1
						? t(
								"components.mapfiltersselection.buildingsnumber.singular"
						  )
						: t(
								"components.mapfiltersselection.buildingsnumber.plural"
						  )}
				</div>
			</div>
			<div className={classes.zoneTagsContainer}>
				{zoneTagsItems.length > 0 && (
					<>
						<div style={{width: "100%"}}>
							{zoneTagsItems.length > 1
								? t(
										"components.mapfiltersselection.zoneTagsItems.plural"
								  )
								: t(
										"components.mapfiltersselection.zoneTagsItems.singular"
								  )}{" "}
							:
						</div>
						{zoneTagsItems}
					</>
				)}
				{heatEnergyTagsItems.length > 0 && (
					<>
						<div style={{width: "100%"}}>
							{heatEnergyTagsItems.length > 1
								? t(
										"components.mapfiltersselection.heatEnergyTagsItems.plural"
								  )
								: t(
										"components.mapfiltersselection.heatEnergyTagsItems.singular"
								  )}{" "}
							:
						</div>
						{heatEnergyTagsItems}
					</>
				)}
				{energyLabelTagsItems.length > 0 && (
					<>
						<div style={{width: "100%"}}>
							{energyLabelTagsItems.length > 1
								? t(
										"components.mapfiltersselection.energyLabelTagsItems.plural"
								  )
								: t(
										"components.mapfiltersselection.energyLabelTagsItems.singular"
								  )}{" "}
							:
						</div>
						{energyLabelTagsItems}
					</>
				)}
			</div>
			{!currentScenarioBuildings?.list?.length &&
				(!currentScenarioZones?.length ||
					!currentScenarioHeatEnergies?.length) && (
					<>
						{zoneMenuItems.length > 0 ? (
							<FormControl className={classes.formControl}>
								<InputLabel shrink>
									{t(
										"components.mapfiltersselection.zoneMenuItems.title"
									)}
								</InputLabel>
								<InputLabel shrink={false}>
									{t(
										"components.mapfiltersselection.zoneMenuItems.selectlabel"
									)}
								</InputLabel>
								<Select
									id="select-zones-id"
									multiple
									value={currentZones}
									onChange={handleZonesChange}
									renderValue={() => null}
									onClose={handleZonesClose}>
									{zoneMenuItems}
								</Select>
							</FormControl>
						) : (
							<CircularProgress />
						)}
						{isHeatEnergyTypesLoaded &&
							heatEnergyTypesMenuItems.length === 0 && (
								<CircularProgress />
							)}
						{heatEnergyTypesMenuItems.length > 0 && (
							<FormControl className={classes.formControl}>
								<InputLabel shrink>
									{t(
										"components.mapfiltersselection.heatEnergyTypesMenuItems.title"
									)}
								</InputLabel>
								<InputLabel shrink={false}>
									{t(
										"components.mapfiltersselection.heatEnergyTypesMenuItems.selectlabel"
									)}
								</InputLabel>
								<Select
									id="select-heat-energies-id"
									multiple
									value={currentHeatEnergyTypes}
									onChange={handleHeatEnergyChange}
									renderValue={() => null}
									onClose={handleHeatEnergyClose}>
									{heatEnergyTypesMenuItems}
								</Select>
							</FormControl>
						)}
						{isEnergyLabelsLoaded &&
							energyLabelsMenuItems.length === 0 && (
								<CircularProgress />
							)}
						{energyLabelsMenuItems.length > 0 && (
							<FormControl className={classes.formControl}>
								<InputLabel shrink>
									{t(
										"components.mapfiltersselection.energyLabelsMenuItems.title"
									)}
								</InputLabel>
								<InputLabel shrink={false}>
									{t(
										"components.mapfiltersselection.energyLabelsMenuItems.selectlabel"
									)}
								</InputLabel>
								<Select
									id="select-energies-labels-id"
									multiple
									value={currentEnergyLabels}
									onChange={handleEnergyLabelChange}
									renderValue={() => null}>
									{energyLabelsMenuItems}
								</Select>
							</FormControl>
						)}
					</>
				)}
		</div>
	);
};

export default withStyles(useStyles)(MapFiltersSelection);
