import {
	GET_OUTPUT_KPIS,
	KPI_IDLE,
	KPI_LOADING,
	KPI_SUCCESS,
} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, select, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import ScenarioUpdateDTOModel from "utils/models/ScenarioUpdateDTOModel";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {selectOutputKpiIndicators} from "utils/helpers/sagaSelectors";
import {setKpisAfterSimulationAction} from "../actions";
import {setNotificationInfoAction} from "domain/notification/actions";
import {withQueries} from "utils/helpers/apiHelpers";

function* outputKpis(action) {
	try {
		yield put({type: KPI_LOADING});

		const {data} = yield axios.get(
			withQueries({
				sagaid: action.sagaId,
				parentScenarioSagaId: action.parentScenarioSagaId,
			})(ApiRoutePathsEnum.GET_OUTPUT_KPIS)
		);

		yield put({type: KPI_SUCCESS});

		yield put(
			setKpisAfterSimulationAction({
				totalEnergyConsumptionKpi: data.totalEnergyConsumption,
				totalEnergyProductionKpi: data.totalEnergyProduction,
				totalGesAndCO2EmissionKpi: data.totalGesAndCO2Emission,
				avgCostPerFinalCustomerKpi: data.avgCostPerFinalCustomer,
				investCostKpi: data.investCost,
				operatingCostKpi: data.operatingCost,
				// ? This Kpis are not yet saved in the database
				localEnergyPartKpi: {
					value: null,
					subValue: null,
				},
				frenchEneryPartKpi: {
					value: null,
					subValue: null,
				},
			})
		);

		yield put({type: KPI_IDLE});

		const outputKpiIndicators = yield select(selectOutputKpiIndicators);

		const scenarioUpdateDTO = ScenarioUpdateDTOModel({
			id: action.currentScenarioId,
			outputIndicators: outputKpiIndicators,
		});

		yield axios.patch(ApiRoutePathsEnum.UPDATE_ALTERNATIVE_SCENARIO, {
			scenarioUpdateDTO,
		});
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.KPI,
				notificationInfos.KPI.error.GET_KPI
			)
		);
	}
}

function* getOutputKpis() {
	yield takeEvery(GET_OUTPUT_KPIS, outputKpis);
}

export default getOutputKpis;
