import MuiButton from "./MuiButton";
import MuiCard from "./MuiCard";
import MuiIconButton from "./MuiIconButton";
import MuiPaper from "./MuiPaper";
import MuiTableCell from "./MuiTableCell";
import MuiTableHead from "./MuiTableHead";
import MuiTypography from "./MuiTypography";

export default {
	MuiButton,
	MuiCard,
	MuiCardHeader: {
		title: {
			fontSize: "14px",
		},
	},
	MuiIconButton,
	MuiPaper,
	MuiTableCell,
	MuiTableHead,
	MuiTypography,
};
