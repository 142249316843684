import {Modal, withStyles} from "@material-ui/core";

import React from "react";
import StatusEnum from "utils/enums/StatusEnum";
import Timer from "styles/assets/timer";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const ScenarioSimulationWaitingModal = ({simulationStatus, classes}) => {
	const {t} = useTranslation();
	return (
		<Modal
			className={classes.containerModalScenarioCreation}
			open={simulationStatus === StatusEnum.LOADING}
			onClose={() => ({})}>
			<div className={classes.modalScenarioCreation}>
				<div className={classes.iconContainer}>
					<Timer className={classes.icon} />
				</div>
				<div className={classes.modalScenarioTitleContainer}>
					{t("components.popuprunsimulation.label")}
				</div>
			</div>
		</Modal>
	);
};

export default withStyles(useStyles)(
	React.memo(ScenarioSimulationWaitingModal)
);
