import {
	GET_BUILDINGS_PARAM,
	GET_MODIFICATIONS_PARAM,
	GET_PRODUCERS_PARAM,
	RESET_SCENARIO_PARAM,
	SET_BUILDINGS_PARAM,
	SET_ENERGY_LABEL_PARAM,
	SET_HEAT_ENERGY_PARAM,
	SET_MODIFICATIONS_PARAM,
	SET_PRODUCERS_PARAM,
	SET_ZONES_PARAM,
} from "domain/parameters/constants";

import {NotificationTitles} from "utils/constants/notificationInfos";
import {SET_NOTIFICATION_INFO} from "domain/notification/constants";
import StatusEnum from "utils/enums/StatusEnum";
import {combineReducers} from "redux";

const parametersReducer = combineReducers({
	producers: (
		state = {
			list: [],
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				if (
					action.status === StatusEnum.ERROR &&
					action.title === NotificationTitles.PARAMETERS
				) {
					return {
						...state,
						status: StatusEnum.ERROR,
					};
				}
				return state;
			case RESET_SCENARIO_PARAM:
				return {
					...state,
					list: [],
				};
			case GET_PRODUCERS_PARAM:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case SET_PRODUCERS_PARAM:
				return {
					...state,
					list: action.list,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	buildings: (
		state = {
			list: [],
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				if (
					action.status === StatusEnum.ERROR &&
					action.title === NotificationTitles.PARAMETERS
				) {
					return {
						...state,
						status: StatusEnum.ERROR,
					};
				}
				return state;
			case RESET_SCENARIO_PARAM:
				return {
					...state,
					list: [],
				};
			case GET_BUILDINGS_PARAM:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case SET_BUILDINGS_PARAM:
				return {
					...state,
					list: action.list,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	zones: (
		state = {
			list: [],
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				if (
					action.status === StatusEnum.ERROR &&
					action.title === NotificationTitles.PARAMETERS
				) {
					return {
						...state,
						status: StatusEnum.ERROR,
					};
				}
				return state;
			case RESET_SCENARIO_PARAM:
				return {
					...state,
					list: [],
				};
			case SET_ZONES_PARAM:
				return {
					...state,
					list: action.list,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	heatEnergy: (
		state = {
			list: [],
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				if (
					action.status === StatusEnum.ERROR &&
					action.title === NotificationTitles.PARAMETERS
				) {
					return {
						...state,
						status: StatusEnum.ERROR,
					};
				}
				return state;
			case RESET_SCENARIO_PARAM:
				return {
					...state,
					list: [],
				};
			case SET_HEAT_ENERGY_PARAM:
				return {
					...state,
					list: action.list,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	energyLabel: (
		state = {
			list: [],
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				if (
					action.status === StatusEnum.ERROR &&
					action.title === NotificationTitles.PARAMETERS
				) {
					return {
						...state,
						status: StatusEnum.ERROR,
					};
				}
				return state;
			case RESET_SCENARIO_PARAM:
				return {
					...state,
					list: [],
				};
			case SET_ENERGY_LABEL_PARAM:
				return {
					...state,
					list: action.list,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	modifications: (
		state = {
			table: {},
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case SET_NOTIFICATION_INFO:
				if (
					action.status === StatusEnum.ERROR &&
					action.title === NotificationTitles.PARAMETERS
				) {
					return {
						...state,
						status: StatusEnum.ERROR,
					};
				}
				return state;
			case RESET_SCENARIO_PARAM:
				return {
					...state,
					table: {},
				};
			case GET_MODIFICATIONS_PARAM:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case SET_MODIFICATIONS_PARAM:
				return {
					...state,
					table: action.table,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},
});

export default parametersReducer;
