import {
	GET_SELECTION_HEAT_ENERGY_TYPE_FILTERS,
	SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS,
} from "../constants";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import axios from "axios";
import {withQueries} from "utils/helpers/apiHelpers";

function* selectionHeatEnergyTypeFiltersData(action) {
	if (!action.zoneIds) {
		yield put({type: SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS, list: []});
		return;
	}

	const {data} = yield axios.get(
		withQueries({
			zones: action.zoneIds,
		})(ApiRoutePathsEnum.GET_HEAT_ENERGY_TYPE)
	);

	yield put({type: SET_SELECTION_HEAT_ENERGY_TYPE_FILTERS, list: data});
}

function* getSelectionHeatEnergyTypeFiltersData() {
	yield takeEvery(
		GET_SELECTION_HEAT_ENERGY_TYPE_FILTERS,
		selectionHeatEnergyTypeFiltersData
	);
}

export default getSelectionHeatEnergyTypeFiltersData;
