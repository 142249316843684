import KPICardList from "./KPICardList";
import {connect} from "react-redux";

const mapStateToProps = (state) => ({
	annualEnergyConsumptionKpi: state.kpiReducer.annualEnergyConsumptionKpi,
	energyProductionKpi: state.kpiReducer.energyProductionKpi,
	gesAndCO2EmissionKpi: state.kpiReducer.gesAndCO2EmissionKpi,
	commonKpi: state.kpiReducer.commonKpi,
	isKpiAfterSimulation: state.kpiReducer.isAfterSimulation,
	totalEnergyConsumptionKpi: state.kpiReducer.totalEnergyConsumptionKpi,
	totalEnergyProductionKpi: state.kpiReducer.totalEnergyProductionKpi,
	totalGesAndCO2EmissionKpi: state.kpiReducer.totalGesAndCO2EmissionKpi,
	avgCostPerFinalCustomerKpi: state.kpiReducer.avgCostPerFinalCustomerKpi,
	investCostKpi: state.kpiReducer.investCostKpi,
	operatingCostKpi: state.kpiReducer.operatingCostKpi,
	localEnergyPartKpi: state.kpiReducer.localEnergyPartKpi,
	frenchEneryPartKpi: state.kpiReducer.frenchEneryPartKpi,
	kpiStatus: state.kpiReducer.status,
});

export default connect(mapStateToProps)(KPICardList);
