import IndicatorsModel from "utils/models/IndicatorsModel";
import OutputIndicatorsModel from "utils/models/OutputIndicatorsModel";
import ScenarioTableToScenarioModifications from "utils/models/mappers/ScenarioTableToScenarioModifications";
import SelectionsModel from "utils/models/SelectionsModel";

export const selectFromViewScenarioCreation = (state) =>
	state.scenarioReducer.isCreationModalOpen.from;

export const selectKpiIndicators = (state) =>
	IndicatorsModel({
		annualEnergyConsumption: state.kpiReducer.annualEnergyConsumptionKpi,
		energyProduction: state.kpiReducer.energyProductionKpi,
		gesAndCO2Emission: state.kpiReducer.gesAndCO2EmissionKpi,
	});

export const selectOutputKpiIndicators = (state) =>
	OutputIndicatorsModel({
		totalEnergyConsumptionKpi: state.kpiReducer.totalEnergyConsumptionKpi,
		totalEnergyProductionKpi: state.kpiReducer.totalEnergyProductionKpi,
		totalGesAndCO2EmissionKpi: state.kpiReducer.totalGesAndCO2EmissionKpi,
		avgCostPerFinalCustomerKpi: state.kpiReducer.avgCostPerFinalCustomerKpi,
		investCostKpi: state.kpiReducer.investCostKpi,
		operatingCostKpi: state.kpiReducer.operatingCostKpi,
		localEnergyPartKpi: state.kpiReducer.localEnergyPartKpi,
		frenchEneryPartKpi: state.kpiReducer.frenchEneryPartKpi,
	});

export const selectSelectionFilters = (state) =>
	SelectionsModel({
		zoneIds: (
			state.mapReducer.mapSelectionFilters.zoneFilters.currentList || []
		).map((z) => +z),
		heatEnergyIds:
			state.mapReducer.mapSelectionFilters.heatEnergyTypeFilters
				.currentList || [],
		energyLabelIds:
			state.mapReducer.mapSelectionFilters.energyLabelFilters
				.currentList || [],
		buildingIds:
			state.mapReducer.mapSelectionFilters.polygonFilters.list || [],
		polygons:
			state.mapReducer.mapSelectionFilters.polygonFilters.polygons || [],
	});

export const selectSelectionZonesFilters = (state) =>
	state.mapReducer.mapSelectionFilters.zoneFilters.list;

export const selectModifications = (state) =>
	ScenarioTableToScenarioModifications({
		tableModifications: state.scenarioReducer.scenarioFormValues,
	});

export const selectMapTypeFilters = (state) =>
	state.mapReducer.mapTypeFilters.currentFilters;

export const selectCurrentScenario = (state) =>
	state.scenarioReducer.currentScenario?.scenario
		? state.scenarioReducer.currentScenario.scenario
		: null;

export const selectFiltersBuildingList = (state) =>
	state.mapReducer.mapSelectionFilters.buildingFilters.list.map(
		(v) => v.Idparcel
	);

export const selectScenarioTree = (state) =>
	state.scenarioReducer.scenarioTree.list;
