import {all, fork} from "redux-saga/effects";

import kpiSaga from "./kpi/kpiSaga";
import mapSaga from "./map/mapSaga";
import parametersSaga from "./parameters/parametersSaga";
import powerbiSaga from "./powerbi/powerbiSaga";
import scenarioSaga from "./scenario/scenarioSaga";

export default function* rootSaga() {
	yield all([
		fork(mapSaga),
		fork(scenarioSaga),
		fork(parametersSaga),
		fork(kpiSaga),
		fork(powerbiSaga),
	]);
}
