import "./style.scss";

import {
	Box,
	Card,
	CircularProgress,
	Fade,
	Grid,
	Paper,
	Slide as SlideTransition,
	withStyles,
} from "@material-ui/core";
import {CarouselProvider, DotGroup, Slide, Slider} from "pure-react-carousel";
import {
	POWERBI_DASHBOARD_MAINPAGE_PAGES,
	POWERBI_REPORTS,
} from "utils/enums/PowerBiReportsEnum";
import React, {useCallback, useEffect, useRef, useState} from "react";

import ButtonBase from "common/presentational/components/Button/ButtonBase";
import ButtonNewScenario from "common/presentational/components/Button/ButtonNewScenario";
import ButtonRunBaseSimulation from "common/presentational/components/Button/ButtonRunBaseSimulation";
import ButtonRunSimulation from "common/presentational/components/Button/ButtonRunSimulation";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FromView from "utils/enums/FromView";
import KPICardListContainer from "business/kpis/KPICardListContainer";
import MapContainer from "business/map/MapContainer";
import MapFiltersSelectionContainer from "business/map/mapFiltersSelection/MapFiltersSelectionContainer";
import MapFiltersTypeContainer from "business/map/mapFiltersType/MapFiltersTypeContainer";
import {PowerBIEmbed} from "powerbi-client-react";
import ScenarioBreadcrumb from "business/scenario/ScenarioBreadcrumb";
import ScenarioParameters from "business/scenario/ScenarioParameters";
import Skeleton from "@material-ui/lab/Skeleton";
import StatusEnum from "utils/enums/StatusEnum";
import {models} from "powerbi-client";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const DigitalTwinView = ({
	classes,
	mapFiltersTypeVisibility,
	isSimulationReady,
	currentScenarioId,
	simulationLaunch,
	openCreationScenarioModalAction,
	launchSimulationAction,
	isCurrentScenarioNull,
	isCurrentScenarioBase,
	mapStatus,
	isKpisActive,
	buildingList,
	hasSelectedPolygons,
	hasSagaId,
	launchBaseSimulationAction,
	accessToken,
	embedUrl,
}) => {
	const {i18n} = useTranslation();

	const isInitialMount = useRef(true);
	const isReportRendered = useRef(false);

	const [openSelectionCard, setOpenSelectionCard] = useState(true);

	const [baseScenarioSaved, setBaseScenarioSaved] = useState(hasSagaId);

	const [reportAccessToken, setReportAccessToken] = useState(null);
	const [reportId, setReportId] = useState(null);
	const [reportEmbedUrl, setReportEmbedUrl] = useState(null);

	const [reportPages, setReportPages] = useState([]);

	const [buildings, setBuildings] = useState([]);

	useEffect(() => {
		setBaseScenarioSaved(hasSagaId);
	}, [hasSagaId, setBaseScenarioSaved]);

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (buildingList.length && buildingList !== buildings)
			setBuildings(buildingList);
		if (isCurrentScenarioNull) setBuildings([]);
	}, [buildingList]);

	// set powerbi embed data on first mount
	// and refresh silently the access token when receiving a new one
	useEffect(() => {
		if (isInitialMount.current) {
			if (accessToken && embedUrl) {
				setReportAccessToken(accessToken);
				setReportId(
					embedUrl[POWERBI_REPORTS.POWERBI_DASHBOARD_MAINPAGE]
						.reportId
				);
				setReportEmbedUrl(
					embedUrl[POWERBI_REPORTS.POWERBI_DASHBOARD_MAINPAGE]
						.embedUrl
				);
				isInitialMount.current = false;
			}
		} else {
			if (accessToken && window.report) {
				window.report.setAccessToken(accessToken);
			}
		}
	}, [accessToken, embedUrl]);

	// callback to change report page
	const reportPageChange = useCallback((name) => {
		window.report.setPage(name);
	}, []);

	// switch report tabs/pages to another language
	const updatePages = useCallback(() => {
		window.report.getPages().then((pages) => {
			isReportRendered.current = true;
			let filterReportPages = pages.filter((page) =>
				POWERBI_DASHBOARD_MAINPAGE_PAGES[i18n.language].includes(
					page.displayName
				)
			);
			setReportPages(filterReportPages);
			window.report.setPage(filterReportPages[0].name);
		});
	}, [i18n.language]);

	// on language change -> update pages language
	useEffect(() => {
		if (isReportRendered.current) {
			updatePages();
		}
	}, [i18n.language, updatePages]);

	const openCreationScenarioModalFromDigitalTwin = useCallback(() => {
		openCreationScenarioModalAction(FromView.digitalTwinView);
	}, [openCreationScenarioModalAction]);

	const handleLaunchSimulation = useCallback(() => {
		launchSimulationAction(currentScenarioId);
	}, [currentScenarioId, launchSimulationAction]);

	const handleOpenSelectionCard = useCallback(() => {
		setOpenSelectionCard(!openSelectionCard);
	}, [openSelectionCard, setOpenSelectionCard]);

	const handleLaunchBaseSimulation = useCallback(() => {
		launchBaseSimulationAction(currentScenarioId);
		setBaseScenarioSaved(true);
	}, [currentScenarioId, launchBaseSimulationAction]);

	return (
		<Box className={classes.page} component="main">
			<Grid container className={classes.navBarContainer}>
				<Grid item xs={8}>
					<Box className={classes.breadcrumbScenarioContainer}>
						{mapStatus === StatusEnum.LOADING && (
							<Skeleton
								animation="wave"
								variant="text"
								width={300}
								height={80}
							/>
						)}
						{mapStatus === StatusEnum.SUCCESS && (
							<ScenarioBreadcrumb />
						)}
					</Box>
				</Grid>
				<Grid item xs={4} className={classes.scenarioButtonsContainer}>
					<Box>
						{baseScenarioSaved && (
							<Fade in={true}>
								<ButtonNewScenario
									onClick={
										openCreationScenarioModalFromDigitalTwin
									}
									disabled={
										(!isCurrentScenarioBase &&
											!isSimulationReady) ||
										(isCurrentScenarioBase &&
											!isKpisActive) ||
										isCurrentScenarioNull
									}
								/>
							</Fade>
						)}

						{isSimulationReady &&
							(simulationLaunch.status === StatusEnum.LOADING ? (
								<Fade in={isSimulationReady}>
									<ButtonRunSimulation
										endIcon={
											<CircularProgress
												disableShrink
												color="inherit"
												size={20}
											/>
										}
									/>
								</Fade>
							) : (
								<Fade in={isSimulationReady}>
									<ButtonRunSimulation
										onClick={handleLaunchSimulation}
									/>
								</Fade>
							))}

						{!isCurrentScenarioNull &&
							!baseScenarioSaved &&
							isCurrentScenarioBase &&
							(simulationLaunch.status === StatusEnum.LOADING ? (
								<Fade in={!baseScenarioSaved}>
									<ButtonRunBaseSimulation
										disabled={
											!hasSelectedPolygons ||
											(!isCurrentScenarioBase &&
												!isSimulationReady) ||
											(isCurrentScenarioBase &&
												!isKpisActive) ||
											isCurrentScenarioNull
										}
										endIcon={
											<CircularProgress
												disableShrink
												color="inherit"
												size={20}
											/>
										}
									/>
								</Fade>
							) : (
								<Fade in={!baseScenarioSaved}>
									<ButtonRunBaseSimulation
										disabled={
											!hasSelectedPolygons ||
											(!isCurrentScenarioBase &&
												!isSimulationReady) ||
											(isCurrentScenarioBase &&
												!isKpisActive) ||
											isCurrentScenarioNull
										}
										onClick={handleLaunchBaseSimulation}
									/>
								</Fade>
							))}
					</Box>
				</Grid>
			</Grid>
			<Box className={classes.digitalTwinPanel}>
				<KPICardListContainer />
				<Grid container className={classes.grid}>
					<Grid item xs={openSelectionCard ? 8 : 12}>
						<Box className={classes.cardMapContainer}>
							<Card className={classes.cardMap}>
								<MapContainer />
							</Card>
							<Paper
								className={classes.mapPanel}
								onClick={handleOpenSelectionCard}>
								{openSelectionCard ? (
									<ChevronRightIcon
										className={classes.arrowForwardIosIcon}
									/>
								) : (
									<ChevronLeftIcon
										className={classes.arrowBackIosIcon}
									/>
								)}
							</Paper>
						</Box>
					</Grid>
					<Grid
						item
						xs={openSelectionCard ? 4 : null}
						style={{
							visibility: openSelectionCard
								? "visible"
								: "collapse",
						}}>
						<Card className={classes.cardFilters}>
							<SlideTransition
								direction="left"
								in={openSelectionCard}>
								<CarouselProvider
									naturalSlideWidth={100}
									naturalSlideHeight={100}
									totalSlides={
										buildings?.length &&
										buildings.length < 500
											? 2
											: 1
									}
									className={classes.carouselProvider}>
									<Slider className={classes.carouselSlider}>
										<Slide
											key={0}
											index={0}
											className={classes.carouselSlide}>
											<MapFiltersSelectionContainer />
										</Slide>
										{Boolean(buildings?.length) &&
											Boolean(buildings.length < 500) && (
												<Slide
													key={1}
													index={1}
													className={
														classes.carouselSlide
													}>
													<PowerBIEmbed
														embedConfig={{
															type: "report",
															id: reportId,
															embedUrl: reportEmbedUrl,
															accessToken: reportAccessToken,
															tokenType:
																models.TokenType
																	.Embed,
															filters: [
																{
																	$schema:
																		"http://powerbi.com/product/schema#basic",
																	target: {
																		table:
																			"Buildings",
																		column:
																			"IdParcel",
																	},
																	operator:
																		"in",
																	values: buildings.map(
																		(
																			building
																		) =>
																			building?.parcel_id ||
																			building?.Idparcel ||
																			building
																	),
																},
															],
															settings: {
																navContentPaneEnabled: false,
															},
														}}
														cssClassName={
															"pse-powerbi-mainpage"
														}
														getEmbeddedComponent={(
															embeddedReport
														) => {
															window.report = embeddedReport;
														}}
														eventHandlers={
															new Map([
																[
																	"loaded",
																	() =>
																		updatePages(),
																],
															])
														}
													/>
													{reportPages.length > 0 && (
														<Box
															className={
																classes.reportPageContainer
															}>
															{reportPages.map(
																(page) => (
																	<ButtonBase
																		key={
																			page.name
																		}
																		className={
																			classes.reportPage
																		}
																		size="small"
																		onClick={() =>
																			reportPageChange(
																				page.name
																			)
																		}>
																		{
																			page.displayName
																		}
																	</ButtonBase>
																)
															)}
														</Box>
													)}
												</Slide>
											)}
									</Slider>
									<DotGroup />
								</CarouselProvider>
							</SlideTransition>
						</Card>
					</Grid>
				</Grid>
				{!mapFiltersTypeVisibility && (
					<Grid
						item
						xs={openSelectionCard ? 8 : 12}
						className={classes.mapFiltersTypeGrid}>
						<MapFiltersTypeContainer />
					</Grid>
				)}
				{!isCurrentScenarioNull && !isCurrentScenarioBase && (
					<ScenarioParameters />
				)}
			</Box>
		</Box>
	);
};

export default withStyles(useStyles)(DigitalTwinView);
