import "utils/constants/authConfig";

import React, {useCallback, useEffect, useState} from "react";

import {Auth} from "@cosmotech/core";
import {AuthMSAL} from "@cosmotech/azure";
import CircularProgress from "@material-ui/core/CircularProgress";
import Routes from "../../routes";

const App = ({getScenarioTreeAction, getEmbedInfo}) => {
	const [isAuthenticated, setIsAutenticated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	// ? temporarly it will be set to true , waiting for roles management
	const isAuthorized = true;

	useEffect(() => {
		getScenarioTreeAction();
	}, [getScenarioTreeAction]);

	useEffect(() => {
		getEmbedInfo();
	}, [getEmbedInfo]);

	useEffect(() => {
		(async function handleFetchLogin() {
			const isAuthenticated = await Auth.isUserSignedIn();
			if (isAuthenticated) {
				setIsAutenticated(isAuthenticated);
				setIsLoading(false);
			}
			// Bind callback to update state on authentication data change
			Auth.onAuthStateChanged((authData) => {
				if (authData) {
					setIsAutenticated(authData.authenticated);
					setIsLoading(false);
				}
			});
		})();
	}, []);

	const handleSignIn = useCallback(() => {
		setIsLoading(true);
		Auth.setProvider(AuthMSAL.name);
		Auth.signIn();
	}, [setIsLoading]);

	const handleSignOut = useCallback(() => {
		setIsAutenticated(false);
	}, [setIsAutenticated]);

	return (
		<>
			{isLoading ? (
				<CircularProgress />
			) : (
				<Routes
					isAuthenticated={isAuthenticated}
					isAuthorized={isAuthorized}
					handleSignIn={handleSignIn}
					handleSignOut={handleSignOut}
				/>
			)}
		</>
	);
};

export default App;
