import {all, fork} from "redux-saga/effects";

import createScenarioData from "./createScenarioData";
import deleteScenariosData from "./deleteScenariosData";
import getScenarioData from "./getScenarioData";
import getScenarioTreeData from "./getScenarioTreeData";
import launchBaseSimulationData from "./launchBaseSimulationData";
import launchSimulationData from "./launchSimulationData";

export default function* scenarioSaga() {
	yield all([
		fork(getScenarioTreeData),
		fork(createScenarioData),
		fork(deleteScenariosData),
		fork(launchSimulationData),
		fork(getScenarioData),
		fork(launchBaseSimulationData),
	]);
}
