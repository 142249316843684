const useStyles = (theme) => ({
	containerModalScenarioCreation: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modalScenarioTitleContainer: {
		width: "100%",
		textAlign: "center",
		color: theme.palette.white,
		fontSize: "18px",
		padding: "8px",
	},
	modalScenarioCreation: {
		position: "relative",
		bottom: "10%",
		width: "500px",
		backgroundColor: theme.palette.background.secondary,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: "10px",
	},
	iconContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "200px",
	},
	icon: {
		fill: "wheat",
		height: "120px",
	},
});

export default useStyles;
