import {v4 as uuidv4} from "uuid";

const ScenarioTableToScenarioModifications = (tableModifications) => {
	const result = {
		ExtendHeatNetwork: [],
		BuildingUpgrades: [],
		CreateHeatNetwork: [],
		CreateProducer: [],
		UpdateProducer: [],
		CreateZac: [],
	};

	tableModifications.tableModifications.newNetworkCreation.forEach(
		(connection) => {
			if (connection?.name) {
				result["CreateHeatNetwork"].push({
					IdHeatNetwork: connection?.name,
					CustomersFixedPrice: connection?.fixedPrice,
					VariableMargin: connection?.variableMargin,
				});
			}
		}
	);

	tableModifications.tableModifications.productionSource.forEach((source) => {
		if (source?.connectionNet) {
			const idSource = uuidv4();

			result["CreateProducer"].push({
				IdHeatNetwork: source?.connectionNet,
				IdProducer: idSource,
			});

			result["UpdateProducer"].push({
				IdProducer: idSource,
				IsControllable: source?.controlable,
				Priority: source?.priority,
				PowerMax: source?.maxPower,
				GHGRate: source?.gesPercent,
				FixedCost: source?.fixedCost,
				VariableCost: source?.supplyCost,
				InvestmentCost: source?.investmentCost,
				Type: source?.type,
				Tag: source?.implementationDate,
			});
		}
	});

	tableModifications.tableModifications.networkConnection.forEach(
		(connection) => {
			if (connection?.connectionNet) {
				result["ExtendHeatNetwork"].push({
					IdParcel: connection?.building,
					IdHeatNetwork: connection?.connectionNet,
					Tag: connection?.implementationDate,
				});
			}
		}
	);

	tableModifications.tableModifications.renovation.forEach((renovation) => {
		if (renovation?.value) {
			result["BuildingUpgrades"].push({
				IdParcel: renovation?.building,
				RenovationObjective: {
					Value: renovation?.value,
					Type: renovation?.goal,
				},
				Tag: renovation?.implementationDate,
			});
		}
	});

	tableModifications.tableModifications.zac.forEach((source) => {
		if (source?.name) {
			const idSource = uuidv4();

			result["CreateZac"].push({
				IdZac: idSource,
				Name: source?.name,
				Surface: source?.surface,
				EnvironmentalClass: source?.environmentalClass,
				HeatingType: source?.heatingType,
				City: source?.city,
				Tag: source?.implementationDate,
			});
		}
	});

	return result;
};

export default ScenarioTableToScenarioModifications;
