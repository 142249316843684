import {GET_SCENARIO_TREE, SET_SCENARIO_TREE} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, takeEvery} from "redux-saga/effects";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {setNotificationInfoAction} from "domain/notification/actions";

function* scenarioTreeData(action) {
	try {
		const {data} = yield axios.get(ApiRoutePathsEnum.GET_SCENARIOS);
		yield put({type: SET_SCENARIO_TREE, tree: data});
		if (action.from !== "fromCreate") {
			yield put(
				setNotificationInfoAction(
					StatusEnum.SUCCESS,
					NotificationTitles.SCENARIO,
					notificationInfos.SCENARIO.success.GET_SCENARIOS
				)
			);
		}
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.error.GET_SCENARIOS
			)
		);
	}
}

function* getScenarioTreeData() {
	yield takeEvery(GET_SCENARIO_TREE, scenarioTreeData);
}

export default getScenarioTreeData;
