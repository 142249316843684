import {
	CLOSE_CREATION_SCENARIO_MODAL,
	GET_CURRENT_SCENARIO,
	GET_SCENARIO_TREE,
	LAUNCH_BASE_SIMULATION,
	LAUNCH_SIMULATION,
	LAUNCH_SIMULATION_DONE,
	LAUNCH_SIMULATION_RESPONSE,
	OPEN_CREATION_SCENARIO_MODAL,
	RESET_CURRENT_SCENARIO,
	RESET_SCENARIO_FORM_VALUES,
	SET_CURRENT_SCENARIO,
	SET_SCENARIO_FORM_VALUES,
	SET_SCENARIO_TREE,
} from "./constants";

import FromView from "utils/enums/FromView";
import StatusEnum from "utils/enums/StatusEnum";
import {combineReducers} from "redux";
import {initialValues} from "common/hoc/scenario/withScenarioParametersForm";

const scenarioReducer = combineReducers({
	isCreationModalOpen: (
		state = {
			value: false,
			from: FromView.scenarioView,
		},
		action
	) => {
		switch (action.type) {
			case OPEN_CREATION_SCENARIO_MODAL:
				return {
					...state,
					from: action.from,
					value: true,
				};
			case CLOSE_CREATION_SCENARIO_MODAL:
				return {
					...state,
					value: false,
				};
			default:
				return state;
		}
	},

	scenarioTree: (
		state = {
			list: [],
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case GET_SCENARIO_TREE:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case SET_SCENARIO_TREE:
				return {
					...state,
					list: action.tree,
					status: StatusEnum.SUCCESS,
				};
			default:
				return state;
		}
	},

	currentScenario: (
		state = {
			scenario: null,
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case GET_CURRENT_SCENARIO:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case SET_CURRENT_SCENARIO:
				return {
					...state,
					scenario:
						action.scenario === null
							? null
							: {...state.scenario, ...action.scenario},
					status: StatusEnum.SUCCESS,
				};
			case RESET_CURRENT_SCENARIO:
				return {...state, scenario: null, status: StatusEnum.IDLE};
			default:
				return state;
		}
	},

	simulationLaunch: (
		state = {
			sagaId: null,
			jobName: null,
			status: StatusEnum.IDLE,
		},
		action
	) => {
		switch (action.type) {
			case LAUNCH_SIMULATION:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case LAUNCH_BASE_SIMULATION:
				return {
					...state,
					status: StatusEnum.LOADING,
				};
			case LAUNCH_SIMULATION_DONE:
				return {
					...state,
					status: StatusEnum.SUCCESS,
				};
			case LAUNCH_SIMULATION_RESPONSE:
				return {
					...state,
					sagaId: action.sagaId,
					jobName: action.jobName,
				};
			default:
				return state;
		}
	},

	scenarioFormValues: (state = initialValues, action) => {
		switch (action.type) {
			case SET_SCENARIO_FORM_VALUES:
				return {
					...state,
					...action.formValues,
				};
			case RESET_SCENARIO_FORM_VALUES:
				return initialValues;
			default:
				return state;
		}
	},
});

export default scenarioReducer;
