import {applyMiddleware, compose, createStore} from "redux";

import createSagaMiddleware from "redux-saga";
import rootReducer from "./domain/rootReducer";
import rootSaga from "./domain/rootSaga";

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);
const composeEnhacers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const thestore = createStore(rootReducer, composeEnhacers(middleware));

sagaMiddleware.run(rootSaga);

export default thestore;
