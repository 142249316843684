import {
	GET_KPIS_AFTER_SELECTION,
	GET_OUTPUT_KPIS,
	RESET_KPIS,
	SET_KPIS_AFTER_SELECTION,
	SET_KPIS_AFTER_SIMULATION,
} from "./constants";

export const getKpisAfterSelectionAction = () => ({
	type: GET_KPIS_AFTER_SELECTION,
});

export const getOuputKpisAction = (
	sagaId,
	currentScenarioId,
	parentScenarioSagaId
) => ({
	type: GET_OUTPUT_KPIS,
	sagaId,
	currentScenarioId,
	parentScenarioSagaId,
});

export const setKpisAfterSelectionAction = ({
	annualEnergyConsumptionKpi,
	energyProductionKpi,
	gesAndCO2EmissionKpi,
	commonKpi,
} = {}) => ({
	type: SET_KPIS_AFTER_SELECTION,
	annualEnergyConsumptionKpi,
	energyProductionKpi,
	gesAndCO2EmissionKpi,
	commonKpi,
});

export const setKpisAfterSimulationAction = ({
	totalEnergyConsumptionKpi,
	totalEnergyProductionKpi,
	totalGesAndCO2EmissionKpi,
	avgCostPerFinalCustomerKpi,
	investCostKpi,
	operatingCostKpi,
	localEnergyPartKpi,
	frenchEneryPartKpi,
} = {}) => ({
	type: SET_KPIS_AFTER_SIMULATION,
	totalEnergyConsumptionKpi,
	totalEnergyProductionKpi,
	totalGesAndCO2EmissionKpi,
	avgCostPerFinalCustomerKpi,
	investCostKpi,
	operatingCostKpi,
	localEnergyPartKpi,
	frenchEneryPartKpi,
});

export const resetKpisAction = () => ({
	type: RESET_KPIS,
});
