import {Autocomplete} from "@material-ui/lab";
import React from "react";
import useStyles from "./muiStyles";
import {withStyles} from "@material-ui/core/styles";

const ScenarioAutocomplete = ({children, style, classes, ...props}) => (
	<Autocomplete
		disableClearable
		className={classes.autocompleteScenario}
		getOptionLabel={(option) => option.name || ""}
		style={style}
		{...props}
	/>
);

ScenarioAutocomplete.defaultProps = {
	style: {width: 300},
};

export default withStyles(useStyles)(ScenarioAutocomplete);
