const OutputIndicatorsModel = ({
	totalEnergyConsumptionKpi,
	totalEnergyProductionKpi,
	totalGesAndCO2EmissionKpi,
	avgCostPerFinalCustomerKpi,
	investCostKpi,
	operatingCostKpi,
	localEnergyPartKpi,
	frenchEneryPartKpi,
}) => ({
	totalEnergyConsumptionKpi,
	totalEnergyProductionKpi,
	totalGesAndCO2EmissionKpi,
	avgCostPerFinalCustomerKpi,
	investCostKpi,
	operatingCostKpi,
	localEnergyPartKpi,
	frenchEneryPartKpi,
});

module.exports = OutputIndicatorsModel;
