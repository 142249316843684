import {
	GET_SELECTION_BUILDING_FILTERS,
	GET_SELECTION_ENERGY_LABEL_FILTERS,
	GET_SELECTION_HEAT_ENERGY_TYPE_FILTERS,
	GET_SELECTION_ZONE_FILTERS,
	INTIALIZE_MAP_INSTANCE,
	LOAD_MAP_INSTANCE_DATA,
	REMOVE_ENERGY_LABEL_FROM_FILTERS_BY_HEAT_ENERGY_TYPE,
	REMOVE_HEAT_ENERGY_FROM_FILTERS_BY_ZONE_ID,
	RESET_SELECTION_CURRENT_FILTERS,
	SET_MAP_FILTER_TYPE,
	SET_MAP_FILTER_TYPE_VISIBILITY,
	SET_POLYGON_SELECTION_BUILDINGS,
	SET_SELECTION_ENERGY_LABEL_CURRENT_FILTERS,
	SET_SELECTION_HEAT_ENERGY_TYPE_CURRENT_FILTERS,
	SET_SELECTION_ZONE_CURRENT_FILTERS,
} from "./constants";
import {initialMapParams, mapStyle} from "utils/constants/mapParams";
import {
	mapFiltersTypeGroups,
	mapfiltersTypesDict,
} from "utils/constants/mapFiltersParams";

import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import StaticMode from "@mapbox/mapbox-gl-draw-static-mode";
import i18n from "../../i18next.config";
import mapboxgl from "mapbox-gl";

let mapInstance = null;

let drawInstance = null;

export const setMapInstance = (map) => {
	mapInstance = map;
};

export const getMapInstance = () => {
	return mapInstance;
};

export const setDrawInstance = (draw) => {
	drawInstance = draw;
};

export const getDrawInstance = () => {
	return drawInstance;
};

mapboxgl.accessToken =
	"pk.eyJ1IjoiYWhtZWRhbXJhbmkxMDAiLCJhIjoiY2xma3lwdjNlMGZzNTQwczR4cmRhaTdnNiJ9._jKs7_U1F0dT51EX_a9pAA";

// ** Map Initialization
export const initializeMapDataAction = (
	mapContainerRef,
	dispatchLoadMapInstanceData
) => {
	const mapInstance = new mapboxgl.Map({
		container: mapContainerRef.current,
		style: mapStyle,
		center: [initialMapParams.lng, initialMapParams.lat],
		zoom: initialMapParams.zoom,
		maxBounds: initialMapParams.maxBounds,
	});

	let modes = MapboxDraw.modes;

	modes.static = StaticMode;

	const drawInstance = new MapboxDraw({
		displayControlsDefault: false,
		controls: {
			polygon: true,
			trash: true,
		},
		modes: modes,
		userProperties: true,
		styles: [
			{
				id: "gl-draw-polygon-fill-inactive",
				type: "fill",
				filter: [
					"all",
					["==", "active", "false"],
					["==", "$type", "Polygon"],
					["!=", "mode", "static"],
				],
				paint: {
					"fill-color": "#3bb2d0",
					"fill-outline-color": "#3bb2d0",
					"fill-opacity": 0.1,
				},
			},
			{
				id: "gl-draw-polygon-fill-active",
				type: "fill",
				filter: [
					"all",
					["==", "active", "true"],
					["==", "$type", "Polygon"],
				],
				paint: {
					"fill-color": "#fbb03b",
					"fill-outline-color": "#fbb03b",
					"fill-opacity": 0.1,
				},
			},
			{
				id: "gl-draw-polygon-midpoint",
				type: "circle",
				filter: [
					"all",
					["==", "$type", "Point"],
					["==", "meta", "midpoint"],
				],
				paint: {
					"circle-radius": 3,
					"circle-color": "#fbb03b",
				},
			},
			{
				id: "gl-draw-polygon-stroke-inactive",
				type: "line",
				filter: [
					"all",
					["==", "active", "false"],
					["==", "$type", "Polygon"],
					["!=", "mode", "static"],
				],
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#3bb2d0",
					"line-width": 2,
				},
			},
			{
				id: "gl-draw-polygon-stroke-active",
				type: "line",
				filter: [
					"all",
					["==", "active", "true"],
					["==", "$type", "Polygon"],
				],
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#fbb03b",
					"line-dasharray": [0.2, 2],
					"line-width": 2,
				},
			},
			{
				id: "gl-draw-line-inactive",
				type: "line",
				filter: [
					"all",
					["==", "active", "false"],
					["==", "$type", "LineString"],
					["!=", "mode", "static"],
				],
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#3bb2d0",
					"line-width": 2,
				},
			},
			{
				id: "gl-draw-line-active",
				type: "line",
				filter: [
					"all",
					["==", "$type", "LineString"],
					["==", "active", "true"],
				],
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#fbb03b",
					"line-dasharray": [0.2, 2],
					"line-width": 2,
				},
			},
			{
				id: "gl-draw-polygon-and-line-vertex-stroke-inactive",
				type: "circle",
				filter: [
					"all",
					["==", "meta", "vertex"],
					["==", "$type", "Point"],
					["!=", "mode", "static"],
				],
				paint: {
					"circle-radius": 5,
					"circle-color": "#fff",
				},
			},
			{
				id: "gl-draw-polygon-and-line-vertex-inactive",
				type: "circle",
				filter: [
					"all",
					["==", "meta", "vertex"],
					["==", "$type", "Point"],
					["!=", "mode", "static"],
				],
				paint: {
					"circle-radius": 3,
					"circle-color": "#fbb03b",
				},
			},
			{
				id: "gl-draw-point-point-stroke-inactive",
				type: "circle",
				filter: [
					"all",
					["==", "active", "false"],
					["==", "$type", "Point"],
					["==", "meta", "feature"],
					["!=", "mode", "static"],
				],
				paint: {
					"circle-radius": 5,
					"circle-opacity": 1,
					"circle-color": "#fff",
				},
			},
			{
				id: "gl-draw-point-inactive",
				type: "circle",
				filter: [
					"all",
					["==", "active", "false"],
					["==", "$type", "Point"],
					["==", "meta", "feature"],
					["!=", "mode", "static"],
				],
				paint: {
					"circle-radius": 3,
					"circle-color": "#3bb2d0",
				},
			},
			{
				id: "gl-draw-point-stroke-active",
				type: "circle",
				filter: [
					"all",
					["==", "$type", "Point"],
					["==", "active", "true"],
					["!=", "meta", "midpoint"],
				],
				paint: {
					"circle-radius": 7,
					"circle-color": "#fff",
				},
			},
			{
				id: "gl-draw-point-active",
				type: "circle",
				filter: [
					"all",
					["==", "$type", "Point"],
					["!=", "meta", "midpoint"],
					["==", "active", "true"],
				],
				paint: {
					"circle-radius": 5,
					"circle-color": "#fbb03b",
				},
			},
			{
				id: "gl-draw-polygon-fill-static",
				type: "fill",
				filter: [
					"all",
					["==", "mode", "static"],
					["==", "$type", "Polygon"],
				],
				paint: {
					"fill-color": "#3bb2d0",
					"fill-outline-color": "#3bb2d0",
					"fill-opacity": 0.1,
				},
			},
			{
				id: "gl-draw-polygon-stroke-static",
				type: "line",
				filter: [
					"all",
					["==", "mode", "static"],
					["==", "$type", "Polygon"],
				],
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#3bb2d0",
					"line-width": 2,
				},
			},
			{
				id: "gl-draw-line-static",
				type: "line",
				filter: [
					"all",
					["==", "mode", "static"],
					["==", "$type", "LineString"],
				],
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#3bb2d0",
					"line-width": 2,
				},
			},
			{
				id: "gl-draw-point-static",
				type: "circle",
				filter: [
					"all",
					["==", "mode", "static"],
					["==", "$type", "Point"],
				],
				paint: {
					"circle-radius": 5,
					"circle-color": "#3bb2d0",
				},
			},
		],
	});

	// Add navigation controls
	mapInstance.addControl(new mapboxgl.NavigationControl(), "bottom-right");
	mapInstance.addControl(drawInstance, "bottom-right");

	// Add fullscreen control
	mapInstance.addControl(new mapboxgl.FullscreenControl());

	// Language change
	// TODO : not working
	mapInstance.addControl(
		new MapboxLanguage({
			defaultLanguage: i18n.language,
		})
	);

	setMapInstance(mapInstance);

	setDrawInstance(drawInstance);

	return {
		type: INTIALIZE_MAP_INSTANCE,
		mapInstance,
		dispatchLoadMapInstanceData,
	};
};

const controlsClassname = "mapbox-gl-draw_ctrl-draw-btn";

// Hide the draw controls

export const hideDrawControls = () => {
	if (document.getElementsByClassName(controlsClassname).length) {
		document.getElementsByClassName(controlsClassname)[0].style =
			"display: none;";
		document.getElementsByClassName(controlsClassname)[1].style =
			"display: none;";
	}
};

// Display the draw controls

export const displayDrawControls = () => {
	if (document.getElementsByClassName(controlsClassname).length) {
		document.getElementsByClassName(controlsClassname)[0].style = "";
		document.getElementsByClassName(controlsClassname)[1].style = "";
	}
};

// ** Map Filter Type Actions

// Visibility of Filters

//Redux Action
export const setMapFiltersTypeVisibilityAction = (isHidden) => ({
	type: SET_MAP_FILTER_TYPE_VISIBILITY,
	isHidden,
});

// Setting Filters
// Redux Action
export const setMapFilterTypeAction = (filterType, checked) => ({
	type: SET_MAP_FILTER_TYPE,
	currentFilter: filterType,
	checked,
});

// Event Closure Action
export const setMapFilterTypeEventActionClosure = (mapInstance, dispatch) => (
	filtersType
) => async () => {
	let isVisible = mapfiltersTypesDict[filtersType];
	for (const filterType of mapFiltersTypeGroups[filtersType]) {
		let visibility = await mapInstance.getLayoutProperty(
			filterType,
			"visibility"
		);
		// toggle layer visibility by changing the layout object's visibility property
		if (visibility === "visible" || visibility === undefined) {
			await mapInstance.setLayoutProperty(
				filterType,
				"visibility",
				"none"
			);
			isVisible = false;
		} else {
			await mapInstance.setLayoutProperty(
				filterType,
				"visibility",
				"visible"
			);
			isVisible = true;
		}
	}
	dispatch(filtersType, isVisible);
};

// ** Map Filters Selection Actions

// Redux Action
export const getSelectionZoneFiltersAction = () => ({
	type: GET_SELECTION_ZONE_FILTERS,
});

// Redux Action
export const getSelectionHeatEnergyTypeFiltersAction = (zoneIds) => ({
	type: GET_SELECTION_HEAT_ENERGY_TYPE_FILTERS,
	zoneIds,
});

// Redux Action
export const getSelectionEnergyLabelFiltersAction = (
	zoneIds,
	heatenergies
) => ({
	type: GET_SELECTION_ENERGY_LABEL_FILTERS,
	zoneIds,
	heatenergies,
});

// Redux Action
export const getSelectionBuildingFiltersAction = (
	zoneIds,
	heatenergies,
	energylabel
) => ({
	type: GET_SELECTION_BUILDING_FILTERS,
	zoneIds,
	heatenergies,
	energylabel,
});

// Redux Action
export const removeHeatEnergyFromFiltersByZoneIdAction = (idzone) => ({
	type: REMOVE_HEAT_ENERGY_FROM_FILTERS_BY_ZONE_ID,
	idzone,
});

// Redux Action
export const removeEnergyLabelFromFiltersByHeatEnergyTypeAction = (
	heatenergytype
) => ({
	type: REMOVE_ENERGY_LABEL_FROM_FILTERS_BY_HEAT_ENERGY_TYPE,
	heatenergytype,
});

// Redux Action
export const filterPolygonSelectionBuildingFiltersAction = (
	buildingIds,
	polygons
) => ({
	type: SET_POLYGON_SELECTION_BUILDINGS,
	buildingIds,
	polygons,
});

// Redux Action
export const setSelectionZoneCurrentFiltersAction = (list) => ({
	type: SET_SELECTION_ZONE_CURRENT_FILTERS,
	list,
});

// Redux Action
export const setSelectionHeatEnergyCurrentFiltersAction = (list) => ({
	type: SET_SELECTION_HEAT_ENERGY_TYPE_CURRENT_FILTERS,
	list,
});

// Redux Action
export const setSelectionEnergyLabelCurrentFiltersAction = (list) => ({
	type: SET_SELECTION_ENERGY_LABEL_CURRENT_FILTERS,
	list,
});

// Redux Action
export const resetSelectionCurrentFiltersAction = () => ({
	type: RESET_SELECTION_CURRENT_FILTERS,
});

// Redux Action
export const loadMapInstanceDataAction = () => ({
	type: LOAD_MAP_INSTANCE_DATA,
});
