const useStyles = (theme) => ({
	cardMap: {
		height: "62vh",
		borderRadius: "5px",
		background: theme.palette.background.secondary,
		flexGrow: "1",
	},
	cardFilters: {
		height: "100%",
		maxHeight: "62vh",
		background: "#3a3a3a",
		padding: `${theme.spacing(1)}px`,
		display: "flex",
		flexDirection: "column",
		borderRadius: "5px",
	},
	digitalTwinPanel: {
		flexGrow: 1,
		padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
			3
		)}px`,
		display: "flex",
		flexDirection: "column",
	},
	mainLayout: {
		display: "flex",
		flexDirection: "column",
	},
	page: {
		background: theme.palette.background.default,
		display: "flex",
		flexDirection: "column",
		height: "100%",
	},
	grid: {
		marginTop: "0.5rem",
		flexGrow: 1,
		minHeight: "62vh",
	},
	navBarContainer: {
		padding: "1em 2em 0",
	},
	breadcrumbActions: {
		marginLeft: "auto",
		display: "flex",
		alignItems: "center",
		flexShrink: 0,
	},
	changesIndicatorLabel: {
		marginLeft: "4px",
		marginRight: "8px",
		color: theme.palette.text.grey,
	},
	closeBreadcrumbIcon: {
		color: theme.palette.primary.main,
		cursor: "pointer",
	},
	breadcrumbScenarioContainer: {
		display: "flex",
		alignItems: "center",
		height: "3.5rem",
	},
	scenarioButtonsContainer: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "flex-end",
	},
	carouselProvider: {
		height: "98%",
		maxHeight: "62vh",
	},
	carouselSlider: {
		height: "98%",
		maxHeight: "62vh",
	},
	carouselSlide: {
		maxHeight: "62vh",
	},
	mapPanel: {
		padding: "0.5rem",
		margin: "0.5rem",
		borderRadius: "30px",
		display: "flex",
		alignContent: "center",
		alignItems: "center",
		cursor: "pointer",
	},
	cardMapContainer: {
		display: "flex",
		flexDirection: "row",
		alignContent: "center",
		alignItems: "center",
	},
	mapFiltersTypeGrid: {
		paddingRight: "54px",
	},
	reportPageContainer: {
		display: "flex",
		alignItems: "center",
		alignContent: "center",
		justifyContent: "space-evenly",
		flexWrap: "wrap",
		margin: "1rem 0",
	},
	reportPage: {
		padding: ".6rem .3rem",
	},
});

export default useStyles;
