const useStyles = (theme) => ({
	containerModalScenarioCreation: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	modalScenarioTitleContainer: {
		width: "100%",
		textAlign: "center",
		color: theme.palette.white,
		fontSize: "24px",
		padding: "1rem 0 1.5rem",
	},
	modalScenarioCreation: {
		position: "relative",
		bottom: "10%",
		width: "500px",
		backgroundColor: theme.palette.background.secondary,
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: "15px",
	},
	formControl: {
		marginTop: `${theme.spacing(2)}px`,
		width: "100%",
	},
	addScenarioModalContainer: {
		marginTop: "20px",
		display: "flex",
		justifyContent: "flex-end",
	},
});

export default useStyles;
