export default {
	IRIS_BORDER: "Iris",
	CPS_BORDER: "CPS",
	CPS_DENSITE: "Densité de population",
	PARCELLES_FOND: "Parcelles",
	ZAC: "ZAC",
	BORNES_RECHARGE_ELECTRIQUE: "Bornes recharge électriques",

	RESEAUX_GAZ: "Réseaux de gaz",
	RESEAUX_CHALEUR: "Réseaux de chaleur",
	RESEAUX_ELECTRIQUE_CABLE: "Réseaux électriques: câbles",
	RESEAUX_ELECTRIQUE_POSTE: "Réseaux électriques: postes",
	GEOTHERMIE_EXPLOITATION: "Exploitations géothermiques",

	CONSO_CHALEUR_VOIRIE: "Consommation chaleur – voirie",
	CONSO_CHALEUR_BATI: "Consommation chaleur – bâti",

	DOGGER: "Géothermie - Dogger",
	LUSITANIEN: "Géothermie - Lusitanien",
	NEOCOMIEN: "Géothermie - Néocomien",
	ALBIEN: "Géothermie - Albien",
	WIND_POTENTIAL: "Zone favorable éolien",
	ROOFTOP_POTENTIAL: "Gisement Solaire des toitures (AREC)",
};
