export const useStyles = (theme) => ({
	root: {
		backgroundColor: theme.palette.brightGrey,
		height: "100%",
	},
	grid: {
		height: "100%",
	},
	quoteContainer: {
		[theme.breakpoints.down("md")]: {
			display: "none",
		},
	},
	quote: {
		backgroundColor: theme.palette.neutral,
		height: "100vh",
		backgroundImage: "url(/login-pic-cosmotech.PNG)",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center bottom",
	},
	quoteInner: {
		textAlign: "center",
		flexBasis: "600px",
		marginTop: "2%",
	},
	quoteText: {
		color: theme.palette.white,
		fontWeight: 300,
	},
	name: {
		marginTop: theme.spacing(3),
		color: theme.palette.white,
	},
	contentContainer: {},
	content: {
		height: "100%",
		display: "flex",
		flexDirection: "column",
	},
	contentHeader: {
		display: "flex",
		alignItems: "center",
		paddingTop: theme.spacing(5),
		paddingBototm: theme.spacing(2),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	logoImage: {
		marginLeft: theme.spacing(4),
	},
	contentBody: {
		flexGrow: 1,
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.down("md")]: {
			justifyContent: "center",
		},
	},
	form: {
		paddingLeft: 100,
		paddingRight: 100,
		paddingBottom: 125,
		flexBasis: 700,
		[theme.breakpoints.down("sm")]: {
			paddingLeft: theme.spacing(2),
			paddingRight: theme.spacing(2),
		},
	},
	title: {
		marginTop: theme.spacing(3),
	},
	suggestion: {
		margin: `${theme.spacing(3)}px 0`,
	},
	textField: {
		marginTop: theme.spacing(2),
	},
	signInButton: {
		width: "100%",
		margin: theme.spacing(2, 0),
	},
});
