import {
	LAUNCH_SIMULATION,
	LAUNCH_SIMULATION_DONE,
	LAUNCH_SIMULATION_RESPONSE,
} from "../constants";
import notificationInfos, {
	NotificationTitles,
} from "utils/constants/notificationInfos";
import {put, select, takeEvery} from "redux-saga/effects";
import {
	selectCurrentScenario,
	selectModifications,
	selectScenarioTree,
	selectSelectionZonesFilters,
} from "utils/helpers/sagaSelectors";
import {withPathParams, withQueries} from "utils/helpers/apiHelpers";

import ApiRoutePathsEnum from "utils/enums/ApiRoutePathsEnum";
import ScenarioUpdateDTOModel from "utils/models/ScenarioUpdateDTOModel";
import StatusEnum from "utils/enums/StatusEnum";
import axios from "axios";
import {getOuputKpisAction} from "domain/kpi/actions";
import getParentNode from "utils/helpers/getParentNode";
import {setNotificationInfoAction} from "domain/notification/actions";

function* scenarioData(action) {
	try {
		const currentScenario = yield select(selectCurrentScenario);
		const modifications = yield select(selectModifications);
		const zonesList = yield select(selectSelectionZonesFilters);
		const scenarioTree = yield select(selectScenarioTree);

		const cityCodeList = Object.assign(
			{},
			...zonesList
				.filter((elt) =>
					modifications?.CreateZac?.map((o) => o.City).includes(
						elt.name
					)
				)
				.map((item) => ({[item.name]: item.idzone}))
		);

		const {data} = yield axios.post(
			withPathParams({id: action.scenarioId})(
				ApiRoutePathsEnum.LAUNCH_SIMULATION
			),
			{
				scenario: {...currentScenario, modifications},
				cityCodeList: cityCodeList,
				buildingIds: [],
			}
		);

		yield put({
			type: LAUNCH_SIMULATION_RESPONSE,
			sagaId: data.sagaId,
			jobName: data.jobName,
		});

		const scenarioUpdateDTO = ScenarioUpdateDTOModel({
			id: currentScenario.id,
			modifications,
			sagaId: data.sagaId,
		});

		yield axios.patch(ApiRoutePathsEnum.UPDATE_ALTERNATIVE_SCENARIO, {
			scenarioUpdateDTO,
		});

		yield axios.get(
			withQueries({
				sagaid: data.sagaId,
				sagaidbau: getParentNode(scenarioTree, currentScenario.id)
					.sagaId,
			})(ApiRoutePathsEnum.SIMULATION_MAPPING)
		);

		yield put(
			setNotificationInfoAction(
				StatusEnum.SUCCESS,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.success.LAUNCH_SIMULATION
			)
		);

		yield new Promise((r) => setTimeout(r, 180000));

		yield put(
			getOuputKpisAction(
				data.sagaId,
				currentScenario.id,
				getParentNode(scenarioTree, currentScenario.id).sagaId
			)
		);

		yield put({type: LAUNCH_SIMULATION_DONE});
	} catch (error) {
		console.log(error);
		yield put(
			setNotificationInfoAction(
				StatusEnum.ERROR,
				NotificationTitles.SCENARIO,
				notificationInfos.SCENARIO.error.LAUNCH_SIMULATION
			)
		);
		yield put({type: LAUNCH_SIMULATION_DONE});
	}
}

function* launchSimulationData() {
	yield takeEvery(LAUNCH_SIMULATION, scenarioData);
}

export default launchSimulationData;
