import {combineReducers} from "redux";
import kpiReducer from "./kpi/kpiReducer";
import mapReducer from "./map/mapReducer";
import notificationReducer from "./notification/notificationReducer";
import parametersReducer from "./parameters/parametersReducer";
import powerbiReducer from "./powerbi/powerbiReducer";
import scenarioReducer from "./scenario/scenarioReducer";

const rootReducer = combineReducers({
	kpiReducer,
	mapReducer,
	scenarioReducer,
	notificationReducer,
	parametersReducer,
	powerbiReducer,
});

export default rootReducer;
