export const initialMapParams = {
	lng: 2.2417181400543313,
	lat: 48.688560391642284,
	zoom: 10.8,
	maxBounds: [
		[0.199556, 48.00611],
		[4.031696, 49.258329],
	],
};

export const mapStyle = "mapbox://styles/mapbox/streets-v11";
