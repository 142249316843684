import {
	AppBar,
	Box,
	MenuItem,
	Select,
	Tab,
	Tabs,
	withStyles,
} from "@material-ui/core";

import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import {i18nUtils} from "utils/i18n";
import tabParams from "utils/constants/tabParams";
import useStyles from "./muiStyles";
import {useTranslation} from "react-i18next";

const TabsLayout = ({pathname, classes}) => {
	const {t, i18n} = useTranslation();

	return (
		<AppBar className={classes.bar}>
			<Box className={classes.barDiv}>
				<Tabs value={pathname} className={classes.tabs}>
					{tabParams.map((tab) => (
						<Tab
							key={tab.key}
							value={tab.to}
							label={t(`layouts.tabs.${tab.key}.title`)}
							component={Link}
							to={tab.to}
							className={classes.tab}
						/>
					))}
				</Tabs>
				<Select
					value={i18n.language}
					onChange={(event) =>
						i18nUtils.changeLanguage(event.target.value, i18n)
					}
					disableUnderline
					IconComponent={() => null}>
					<MenuItem value={"fr"}>
						<ReactCountryFlag
							svg
							countryCode="FR"
							style={{
								fontSize: "2rem",
								lineHeight: "1rem",
								margin: "0 .5rem",
							}}
							title="France"
						/>
					</MenuItem>
					<MenuItem value={"en"}>
						<ReactCountryFlag
							svg
							countryCode="GB"
							style={{
								fontSize: "2rem",
								lineHeight: "1rem",
								margin: "0 .5rem",
							}}
							title="United Kingdom"
						/>
					</MenuItem>
				</Select>
			</Box>
		</AppBar>
	);
};

TabsLayout.propTypes = {
	pathname: PropTypes.string.isRequired,
};

export default withStyles(useStyles)(TabsLayout);
