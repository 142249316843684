import {Redirect, Route} from "react-router-dom";

import KeepAliveRoute from "keep-alive-route";
import React from "react";

const PrivateRoute = (props) => {
	const {
		render,
		authenticated,
		authorized,
		noAuthRedirect,
		noPermRedirect,
		...rest
	} = props;

	let route = <KeepAliveRoute {...rest} render={render} />;

	if (!authenticated) {
		route = (
			<Route
				{...rest}
				render={(routeProps) => (
					<Redirect
						to={{
							pathname: props.noAuthRedirect,
							state: {from: routeProps.location},
						}}
					/>
				)}
			/>
		);
	} else if (!authorized && noPermRedirect !== undefined) {
		route = (
			<Route
				{...rest}
				render={(routeProps) => (
					<Redirect
						to={{
							pathname: props.noPermRedirect,
							state: {from: routeProps.location},
						}}
					/>
				)}
			/>
		);
	}

	return route;
};

export default PrivateRoute;
