import {compose, withPropsOnChange} from "recompose";
import {
	launchBaseSimulationAction,
	launchSimulationAction,
	openCreationScenarioModalAction,
} from "domain/scenario/actions";

import DigitalTwinView from "./DigitalTwinView";
import {connect} from "react-redux";
import isSimulationReady from "utils/helpers/isSimulationReady";

const mapStateToProps = (state) => ({
	mapFiltersTypeVisibility: state.mapReducer.mapTypeFilters.isHidden,
	buildingList: state.parametersReducer.buildings.list.length
		? state.parametersReducer.buildings.list
		: state.mapReducer.mapSelectionFilters.polygonFilters.list.length
		? state.mapReducer.mapSelectionFilters.polygonFilters.list
		: state.mapReducer.mapSelectionFilters.buildingFilters.list,
	hasSelectedPolygons: Boolean(
		state.mapReducer.mapSelectionFilters.polygonFilters.list?.length
	),
	hasSagaId: Boolean(state.scenarioReducer.currentScenario.scenario?.sagaId),
	isKpisActive: state.kpiReducer.isActive,
	currentScenarioId: state.scenarioReducer.currentScenario.scenario?.id,
	simulationLaunch: state.scenarioReducer.simulationLaunch,
	scenarioFormValues: state.scenarioReducer.scenarioFormValues,
	isCurrentScenarioNull:
		state.scenarioReducer.currentScenario.scenario === null,
	isCurrentScenarioBase: !(
		state.scenarioReducer.currentScenario.scenario?.type === "derive"
	),
	mapStatus: state.mapReducer.mapData.status,
	accessToken: state.powerbiReducer.info.accessToken,
	embedUrl: state.powerbiReducer.info.embedUrl,
});

const mapDispatchToProps = {
	openCreationScenarioModalAction,
	launchSimulationAction,
	launchBaseSimulationAction,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withPropsOnChange(["scenarioFormValues"], (props) => ({
		isSimulationReady: isSimulationReady(props.scenarioFormValues),
	}))
)(DigitalTwinView);
