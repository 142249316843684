const recursiveParseTree = (data, dataList) => {
	(data.children || []).forEach((child) => {
		dataList.push(child.id);
		recursiveParseTree(child, dataList);
	});
	return;
};

const getScenarioAndChildrenIdList = (scenario) => {
	const dataList = [scenario.id];
	scenario.children.forEach((child) => {
		dataList.push(child.id);
		recursiveParseTree(child, dataList);
	});
	return dataList;
};

function search(node, id) {
	if (node.id === id) return node;
	else if (node.children) {
		let result;
		for (const child of node.children) {
			if (!result) result = search(child, id);
		}
		return result ? result : null;
	}
	return null;
}

export default (tree, id) => {
	for (const node of tree) {
		const scenario = search(node, id);
		if (scenario) return getScenarioAndChildrenIdList(scenario);
	}
	return null;
};
